import React from 'react';
import { Link } from '@reach/router';
import { Card, Row, Col, Icon } from 'antd';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import './Welcome.css';

const Welcome = () => {
  const { user: authData, isAuthenticated, loginWithRedirect } = useAuth0();
  return !isAuthenticated ? (
    loginWithRedirect()
  ) : (
    <Row type="flex" align="middle" justify="center" className="py-1">
      <Col
        xs={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: 12 }}
        xl={{ span: 12 }}
        xxl={{ span: 12 }}>
        <h4 className="welcome_title text-deep-blue">
          Welcome... {authData && authData.name}
        </h4>

        <h2 className="welcome_subtitle">Select your activity</h2>
        <Row gutter={[24, 24]} className="mt-2">
          <Col xs={{ span: 12 }} md={{ span: 12 }} lg={{ span: 8 }}>
            <Link to="/service-report">
              <Card className="welcome_card" bordered={false}>
                <div className="welcome_card__icon_wrapper ">
                  <Icon
                    className="welcome_card__icon"
                    type="snippets"
                    theme="twoTone"
                    twoToneColor="#0552B5"
                  />
                </div>
                <p className="welcome_card__text">
                  <strong>Comprehensive</strong> <br />
                  Report
                </p>
              </Card>
            </Link>
          </Col>

          <Col xs={{ span: 12 }} md={{ span: 12 }} lg={{ span: 8 }}>
            <Link to="/dashboard">
              <Card className="welcome_card" bordered={false}>
                <div className="welcome_card__icon_wrapper ">
                  <Icon
                    className="welcome_card__icon"
                    type="file-add"
                    theme="twoTone"
                    twoToneColor="#0F609B"
                  />
                </div>
                <p className="welcome_card__text">
                  <strong>Single</strong> <br />
                  Report
                </p>
              </Card>
            </Link>
          </Col>

          <Col xs={{ span: 12 }} md={{ span: 12 }} lg={{ span: 8 }}>
            <Link to="/service-report/lists">
              <Card className="welcome_card" bordered={false}>
                <div className="welcome_card__icon_wrapper ">
                  <Icon
                    className="welcome_card__icon"
                    type="folder-open"
                    theme="twoTone"
                    twoToneColor="#2680C2"
                  />
                </div>
                <p className="welcome_card__text">
                  <strong> Existing</strong> Reports
                </p>
              </Card>
            </Link>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default withAuthenticationRequired(Welcome);
