import React from 'react';
import { Spin, Icon } from 'antd';
import PropTypes from 'prop-types';

const antIcon = (
  <Icon type="loading" style={{ fontSize: 54, marginTop: '-1.3em' }} spin />
);

const Loader = ({ children, title, isLoading, size }) => {
  return (
    <Spin indicator={antIcon} spinning={isLoading} tip={title} size={size}>
      {children}
    </Spin>
  );
};

Loader.propType = {
  children: PropTypes.node,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
};

Loader.defaultProps = {
  title: 'Fetching data...',
  size: 'small',
};

export default Loader;
