import gql from 'graphql-tag';

// update users
export const UPDATE_USER_MUTATION = gql`
  mutation updateUser($id: String!, $data: UserUpdateInput!) {
    updateUser(id: $id, input: $data) {
      _id
      auth0UserId
      lastName
      firstName
      email
      member {
        _id
        branch {
          _id
          name
        }
      }
      roles {
        _id
        name
      }
    }
  }
`;
