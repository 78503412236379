import React from 'react';
import {
  Col,
  Row,
  Button,
  Card,
  Input,
  Form,
  Select,
  Alert,
  Icon,
  DatePicker,
} from 'antd';
import { navigate, Link } from '@reach/router';
import { useFormik } from 'formik';
import isEmpty from 'lodash.isempty';
import { useMutation, useQuery } from '@apollo/client';

// internals
import { PageTitle, appNotification } from '_components';
import { formItemLayout } from '_constants';
import validationSchema from './formValidationSchema';
import { CREATE_SERVICE, GET_SERVICE_TYPES } from './graphql';
import { GQL_ORDER } from '_helpers/util';

const { Option } = Select;
const { TextArea } = Input;

const ServiceForm = props => {
  const [createService, { loading: mutateLoading }] = useMutation(
    CREATE_SERVICE,
  );
  const { data: queryData } = useQuery(GET_SERVICE_TYPES, {
    variables: { order: GQL_ORDER },
  });

  // const disabledDate = current => {
  //   return current && current > moment().endOf('day');
  // };

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    dirty,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {},
    validationSchema,
    onSubmit(values) {
      const formData = {
        ...values,
        name: `${values.name} on ${values.serviceDate}`,
      };

      createService({ variables: { data: formData } }).then(({ data }) => {
        data.createService._id &&
          appNotification({
            desc: `${data.createService.name} Service was successfuly added. Kudos`,
            type: 'success',
          });
        navigate('/services');
      });

      resetForm();
    },
  });

  return (
    <>
      <PageTitle
        title="Service & Order"
        extra={
          <Row type="flex" justify="end">
            <Link to="../">Back</Link>
          </Row>
        }
      />
      <div className="trans-details">
        <Row type="flex" className="" gutter={[24, 24]}>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}>
            <Row gutter={[24, 24]}>
              <Col span={24} className="">
                <Card
                  bordered={false}
                  style={{ borderRadius: 4 }}
                  title="Add a new service">
                  <Form layout="horizontal">
                    <Form.Item>
                      <Alert
                        message="Services are dependent on service catergory"
                        description="You need to choose a service catergory before you create order of service "
                        type="info"
                        icon={<Icon type="info-circle" theme="filled" />}
                        showIcon
                        banner
                      />
                    </Form.Item>

                    <Form.Item
                      required={true}
                      label="Service Name"
                      htmlFor="name"
                      colon={false}
                      {...formItemLayout}
                      validateStatus={
                        errors.name && touched.name ? 'error' : null
                      }
                      help={errors.name && touched.name ? errors.name : null}>
                      <Input
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                      />
                    </Form.Item>

                    <Form.Item
                      required={true}
                      label="Service Date"
                      htmlFor="serviceDate"
                      colon={false}
                      {...formItemLayout}
                      validateStatus={
                        errors.serviceDate && touched.serviceDate
                          ? 'error'
                          : null
                      }
                      help={
                        errors.serviceDate && touched.serviceDate
                          ? errors.serviceDate
                          : null
                      }>
                      <DatePicker
                        style={{ width: '100%' }}
                        name="serviceDate"
                        format={'MMMM DD YYYY'}
                        // disabledDate={disabledDate}
                        onBlur={handleBlur}
                        onChange={(dateObject, dateString) =>
                          setFieldValue('serviceDate', dateString)
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      required={true}
                      label="Service Category"
                      htmlFor="serviceType"
                      colon={false}
                      {...formItemLayout}
                      validateStatus={
                        errors.serviceType && touched.serviceType
                          ? 'error'
                          : null
                      }
                      help={
                        errors.serviceType && touched.serviceType
                          ? errors.serviceType
                          : null
                      }>
                      <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Choose category"
                        optionFilterProp="children"
                        onChange={text => setFieldValue('serviceType', text)}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                        {!isEmpty(queryData) ? (
                          queryData.serviceTypes.map(type => (
                            <Option key={type._id} value={type._id}>
                              {type.name}
                            </Option>
                          ))
                        ) : (
                          <Option value={null}>No service type defined</Option>
                        )}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      required={true}
                      label="Order Of Service"
                      htmlFor="orderOfService"
                      colon={false}
                      {...formItemLayout}
                      validateStatus={
                        errors.orderOfService && touched.orderOfService
                          ? 'error'
                          : null
                      }
                      help={
                        errors.orderOfService && touched.orderOfService
                          ? errors.orderOfService
                          : null
                      }>
                      <TextArea
                        name="orderOfService"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.orderOfService}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Additional Info"
                      htmlFor="additionalInfo"
                      colon={false}
                      {...formItemLayout}
                      validateStatus={
                        errors.additionalInfo && touched.additionalInfo
                          ? 'error'
                          : null
                      }
                      help={
                        errors.additionalInfo && touched.additionalInfo
                          ? errors.additionalInfo
                          : null
                      }>
                      <TextArea
                        name="additionalInfo"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.additionalInfo}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Remarks"
                      htmlFor="remarks"
                      colon={false}
                      {...formItemLayout}
                      validateStatus={
                        errors.remarks && touched.remarks ? 'error' : null
                      }
                      help={
                        errors.remarks && touched.remarks
                          ? errors.remarks
                          : null
                      }>
                      <TextArea
                        name="remarks"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.remarks}
                      />
                    </Form.Item>

                    <Row type="flex" justify="end">
                      <Button
                        key="submit"
                        type="primary"
                        size="large"
                        loading={mutateLoading}
                        disabled={!isEmpty(errors) || !dirty}
                        onClick={handleSubmit}>
                        Create Service
                      </Button>
                    </Row>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ServiceForm;
