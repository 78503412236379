import gql from 'graphql-tag';

export const GET_SEED_REPORTS = gql`
  query getSeedReports($order: SeedReportSortInput) {
    seedReports(order: $order) {
      _id
      seedName
      seedAmount
      seedRemarks
      serviceType {
        _id
        name
      }
      service {
        name
      }
      recordedBy {
        _id
        lastName
        firstName
      }
      member {
        _id
        firstName
        lastName
      }

      branch {
        _id
        name
      }
    }
  }
`;
