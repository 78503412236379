import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const options = {
  tooltips: {
    backgroundColor: '#fff',
    titleFontColor: '#4A494A',
    bodyFontColor: '#373A3C',
    footerFontColor: '#373A3C',
    borderColor: '#48AF1B',
  },
  legend: {
    display: false,
    position: 'left',
    align: 'center',
    labels: {
      boxWidth: 20,
    },
  },
};

const data = {
  labels: ['Successful', 'Failed'],
  datasets: [
    {
      data: [3000, 1500],
      backgroundColor: ['#4BC0C0', '#FF6384'],
      hoverBackgroundColor: ['#4BC0C0', '#FF6384'],
    },
  ],
};

const ISWBankStatusChart = () => {
  return <Doughnut data={data} options={options} height={200} width={300} />;
};

export default ISWBankStatusChart;
