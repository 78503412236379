import gql from 'graphql-tag';

export const CREATE_BRANCH_MUTATION = gql`
  mutation createBranch($data: BranchInput!) {
    createBranch(input: $data) {
      _id
      name
      url
      address
      city
      state
      country
      region {
        _id
      }
    }
  }
`;

export const CREATE_BRANCH_MUTATION_FULL = gql`
  mutation createBranch($data: BranchInput!) {
    createBranch(input: $data) {
      _id
      name
      url
      address
      city
      state
      country
      region {
        _id
      }
      leader {
        _id
      }
    }
  }
`;
