import { yupToFormErrors } from 'formik';
import * as Yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object({
  firstName: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, {
      message: 'Please enter valid characters',
      excludeEmptyString: true,
    })
    .min(2)
    .required('First name is required'),
  middleName: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, {
      message: 'Please enter valid characters',
      excludeEmptyString: true,
    })
    .min(2),
  lastName: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, {
      message: 'Please enter valid characters',
      excludeEmptyString: true,
    })
    .min(2)
    .required('Last name is required'),

  gender: Yup.string()
    .matches(/^[a-zA-Z]+$/, {
      message: 'Please enter valid characters',
      excludeEmptyString: true,
    })
    .min(2),
  email: Yup.string().email(),
  phoneNumber: Yup.string(),
  occupation: Yup.string(),
  saved: Yup.boolean().required('Enter salvation status'),
  joiningChurch: Yup.boolean().required('Field required'),
  dateOfSalvation: Yup.date(),
  ageRange: Yup.string().required('Field required'),

  prayerPoints: Yup.string(),
  invitedBy: Yup.string()
    .matches(/^[a-zA-Z0-9\s]+$/, {
      message: 'Please enter valid characters',
      excludeEmptyString: true,
    })
    .min(2)
    .required('Field is required'),

  maritalStatus: Yup.string(),

  address: Yup.string().min(2),
  city: Yup.string().min(2),
  state: Yup.string().min(2),
  country: Yup.string().min(2),

  branch: Yup.string()
    .matches(/^[a-zA-Z0-9\s]+$/, {
      message: 'Please enter valid characters',
      excludeEmptyString: true,
    })
    .min(2)
    .required('Field is required'),
});

export default validationSchema;
