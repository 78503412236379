import React, { useState } from 'react';
import {
  Form,
  Input,
  Col,
  Row,
  Button,
  Modal,
  Spin,
  DatePicker,
  Select,
} from 'antd';
import { useFormik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import isEmpty from 'lodash.isempty';

import { PageTitle, appNotification } from '_components';
import { DataTable } from './components';
import { ADD_CONVERT_REPORT, GET_MEMBERS, GET_BRANCH } from './query';
import validationSchema from './formValidationSchema';

export const ConvertsReports = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: queryData } = useQuery(GET_MEMBERS);
  const { data: branchData } = useQuery(GET_BRANCH);
  const [addConvertReport, { loading: mutateLoading }] = useMutation(
    ADD_CONVERT_REPORT,
  );

  const showModal = () => setIsOpen(true);
  const handleOk = e => {
    setIsOpen(!isOpen);
  };

  const handleCancel = e => {
    setIsOpen(!isOpen);
  };

  const handleReset = () => {
    resetForm();
    setIsOpen(false);
  };

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    dirty,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema,
    onSubmit(values, { setSubmitting }) {
      if (!values.name) {
        delete values.name;
        setSubmitting(false);
      }
      const formData = {
        ...values,
      };
      addConvertReport({ variables: { data: formData } }).then(({ data }) => {
        data.createConvert._id &&
          appNotification({
            desc: 'Your Converts Report was successfuly added. Kudos',
            type: 'success',
          });
        handleReset();
      });
    },
  });

  /* eslint-disable */
  return (
    <div>
      <PageTitle title="Convert Reports" />

      <div className="mb-2 mt-2">
        <Row gutter={8} type="flex" justify="space-between">
          <Col span={15}>
            <Row gutter={8} type="flex" justify="start">
              <Col>
                <Form.Item
                  validateStatus={errors.name ? 'error' : null}
                  help={
                    errors.name && touched.name
                      ? 'Convert name is required'
                      : null
                  }>
                  <label htmlFor="">Search Convert Name</label>
                  <Input
                    name="seach"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // value={values.name}
                    placeholder=""
                  />
                </Form.Item>
              </Col>

              <Col className="filter__panel_btn_wrapper">
                <Button
                  type="primary"
                  icon="search"
                  className="medium__btn"
                  // onClick={handleSubmit}
                >
                  Search
                </Button>
              </Col>
              <Col className="filter__panel_btn_wrapper ">
                <Button
                  icon="undo"
                  className="medium__btn"
                  // onClick={handleReset}
                >
                  Clear
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={9}>
            <Row type="flex" justify="end" gutter={8}>
              <Col className="filter__panel_btn_wrapper">
                <Button
                  type="primary"
                  icon="plus"
                  className="medium__btn"
                  onClick={showModal}>
                  Add New Record
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <DataTable searchQuery={values.name} />

      {/* modal for upload */}

      <Modal
        title="Add New Converts"
        visible={isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button
            key="submit"
            type="primary"
            style={{ width: 170 }}
            disabled={!isEmpty(errors) || !dirty}
            onClick={handleSubmit}>
            Submit
          </Button>,
        ]}>
        <Form>
          <Spin
            size="large"
            tip="Submitting your report, Kindly hold on"
            spinning={mutateLoading}>
            <Form.Item
              label="Convert Name"
              htmlFor="name"
              validateStatus={errors.name ? 'error' : null}
              help={errors.name ? errors.name : null}>
              <label htmlFor="name"></label>
              <Input
                size="large"
                type="text"
                name="name"
                placeholder=""
                onChange={handleChange}
                value={values.name}
              />
            </Form.Item>

            <Form.Item
              label="New Life Teacher"
              htmlFor="newLifeTeacher"
              validateStatus={errors.newLifeTeacher ? 'error' : null}
              help={errors.newLifeTeacher ? errors.newLifeTeacher : null}>
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Search New Life Teacher"
                optionFilterProp="children"
                onChange={text => setFieldValue('newLifeTeacher', text)}
                // onBlur={handleBlur}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }>
                {queryData &&
                  queryData.members.map(member => {
                    return (
                      <Select.Option value={member._id}>
                        {member?.firstName} {member?.lastName}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item
              label="Date Saved"
              htmlFor="dateSaved"
              validateStatus={errors.dateSaved ? 'error' : null}
              help={errors.dateSaved ? errors.dateSaved : null}>
              <DatePicker
                style={{ width: '100%' }}
                name="dateSaved"
                format={'MMMM DD YYYY'}
                onBlur={handleBlur}
                onChange={(dateObject, dateString) =>
                  setFieldValue('dateSaved', dateString)
                }
              />
            </Form.Item>
            <Form.Item
              label="Branch"
              htmlFor="branch"
              validateStatus={errors.branch ? 'error' : null}
              help={errors.branch ? errors.branch : null}>
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Search Branch"
                optionFilterProp="children"
                onChange={text => setFieldValue('branch', text)}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }>
                {branchData &&
                  branchData.branches.map(branch => {
                    return (
                      <Select.Option value={branch._id}>
                        {branch?.name}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item
              label="Pastoral Remark"
              htmlFor="pastoralRemark"
              validateStatus={errors.pastoralRemark ? 'error' : null}
              help={errors.pastoralRemark ? errors.pastoralRemark : null}>
              <Input.TextArea
                name="pastoralRemark"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.pastoralRemark}
              />
            </Form.Item>
          </Spin>
        </Form>
      </Modal>
    </div>
  );
};

export default ConvertsReports;
