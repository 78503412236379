import React from 'react';
import {
  Col,
  Row,
  Divider,
  Card,
  List,
  Tooltip,
  Badge,
  Typography,
  Descriptions,
} from 'antd';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';

/**
 * THIS CARD IS DIFFERENT FROM THE ONE IN SERVICE REPORTS
 * @param {*} param0
 */
const OtherInfoCard = ({ address, city, state, country }) => {
  return (
    <Col
      xs={{ span: 24 }}
      sm={{ span: 18 }}
      lg={{ span: 18 }}
      xl={{ span: 18 }}
      xxl={{ span: 12 }}>
      <Card style={{ borderRadius: '4px' }} bordered={false}>
        {/* if we want date we add date too */}

        <Typography.Title level={4} type="secondary" className="text-gray">
          Other details
        </Typography.Title>

        <Descriptions>
          <Descriptions.Item label="Address" span={2}>
            {address}
          </Descriptions.Item>
          <Descriptions.Item label="City">{city}</Descriptions.Item>
          <Descriptions.Item label="State">{state}</Descriptions.Item>
          <Descriptions.Item label="Country" span={2}>
            {country}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </Col>
  );
};

export default OtherInfoCard;
