import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Card, Avatar, Descriptions, Tag } from 'antd';
import { navigate, Link } from '@reach/router';
import { uuid } from 'uuidv4';

// internals
import { PageTitle } from '_components';
import { SS } from '_helpers';

import { OtherInfoCard } from './components';

const ServiceReportDetails = props => {
  // value coming from session storage
  const [details, setDetails] = useState(SS.get('members-details'));

  useEffect(() => {
    return () => {
      // cancel subscriptions
    };
  }, []);

  return (
    <>
      <PageTitle
        title="Member's Details"
        extra={
          <Row type="flex" justify="end">
            <Link to="/members">Back</Link>
          </Row>
        }
      />
      <div className="trans-details">
        <Row type="flex" className="" gutter={[24, 24]}>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}>
            <Row gutter={[24, 24]}>
              {/* First Timers Details column */}
              <Col span={24} className="">
                <Card bordered={false} style={{ borderRadius: 4 }}>
                  <Row
                    className="markdown__row"
                    type="flex"
                    justify="space-between">
                    <Col span={18}>
                      <h3 className="">{`${details.firstName} ${details.lastName}`}</h3>
                    </Col>
                    <Col>
                      <Avatar
                        shape="square"
                        size={64}
                        // src={details.photo}
                        icon="user"
                      />
                    </Col>
                  </Row>

                  <div className="details_divider"></div>
                  <div className="markdown ">
                    <table className="">
                      <tbody>
                        <tr>
                          <td>Full Name</td>
                          <td>{`${details.firstName} ${details.middleName} ${details.lastName}`}</td>
                        </tr>
                        <tr>
                          <td>Date Of Birth</td>
                          <td>
                            {details.dateOfBirth ? details.dateOfBirth : ''}
                          </td>
                        </tr>
                        <tr>
                          <td>Gender</td>
                          <td>{details.gender}</td>
                        </tr>
                        <tr>
                          <td>Email</td>
                          <td>{details.email}</td>
                        </tr>{' '}
                        <tr>
                          <td>Phone Number</td>
                          <td>{details.phoneNumber}</td>
                        </tr>{' '}
                        <tr>
                          <td>Occupation</td>
                          <td>{details.occupation}</td>
                        </tr>
                        <tr>
                          <td>Membership Verification</td>
                          <td>
                            {details.verified ? (
                              <Tag color="green">Verified</Tag>
                            ) : (
                              <Tag color="red">Not Verified</Tag>
                            )}
                          </td>
                        </tr>
                        {/* <tr>
                          <td>Address</td>
                          <td>{`${details.address}, ${details.city}, ${details.state}, ${details.country}`}</td>
                        </tr> */}
                        <tr>
                          <td>Branch</td>
                          <td>{details.branch}</td>
                        </tr>
                        <tr>
                          <td>Membership Level</td>
                          <td>{details.membershipLevel}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}>
            <Row type="flex" gutter={[24, 24]}>
              {/* Payment Method column */}

              <OtherInfoCard
                address={details.address}
                city={details.city}
                state={details.state}
                country={details.country}
              />
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ServiceReportDetails;
