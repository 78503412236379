import gql from 'graphql-tag';

export const GET_SERVICES = gql`
  query getServices($order: ServiceSortInput) {
    services(order: $order) {
      _id
      name
      url
      serviceDate
      orderOfService
      additionalInfo
      remarks
      serviceType {
        _id
        name
      }
    }
  }
`;

export const GET_SINGLE_SERVICE = gql`
  query getSingleService($id: String!) {
    singleService(id: $id) {
      _id
      name
      serviceDate
      orderOfService
      additionalInfo
      remarks
    }
  }
`;
