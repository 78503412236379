import gql from 'graphql-tag';

export const ABSORB_FIRST_TIMER = gql`
  mutation absorbFirstTimer($data: FirstTimerAbsorptionInput!, $id: String!) {
    absorbFirstTimer(input: $data, id: $id) {
      _id
    }
  }
`;

export const GET_FT = gql`
  query getFirstTimer($order: FirstTimerSortInput) {
    firstTimers(order: $order) {
      _id
      firstName
      middleName
      lastName
      address
      city
      state
      country
      firstDayInChurch
      saved
      belClassStage
      newLifeStage
      pastoralRemarks
      phoneNumber
      email
      belClassTeacher {
        _id
        firstName
        middleName
        lastName
      }
      newLifeTeacher {
        _id
        firstName
        middleName
        lastName
      }
      unitLeader {
        _id
        firstName
        middleName
        lastName
      }
      branch {
        _id
      }
    }
  }
`;

export const GET_MEMBERS = gql`
  query getMembers {
    members {
      _id
      firstName
      middleName
      lastName
    }
  }
`;

// _id: ID!
// language: String
// firstName: String!
// middleName: String
// lastName: String
// gender: String
// ageRange: String
// phoneNumber: String
// email: String
// saved: Boolean
// dateOfSalvation: DateTime
// joiningChurch: Boolean
// invitedBy: String
// prayerPoints: String
// occupation: String
// address: String
// city: String
// state: String
// country: String
// branch: BranchType
// firstDayInChurch: DateTime
// unitLeader: MemberType
// newLifeStage: String
// newLifeTeacher: MemberType
// belClassStage: String
// belClassTeacher: MemberType
// pastoralRemarks: String
// memberDetail: Mem
// firstTimers(
//     order: FirstTimerSortInput
//     whereOperator: FirstTimerWhereOperator
//     whereCondition: FirstTimerWhereInput
//     pagination: FirstTimerPageInput
//     ): [FirstTimerType!]!
// type ServiceTypeInput {
//     language: String = "en"
//     name: String!
//     url: String
//     }
