const statsOptions = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          // max: 100,
          min: 0,
          stepSize: 50,
        },

        scaleLabel: {
          display: true,
          labelString: 'Amount (Millions)',
          fontSize: 16,
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
  },
  tooltips: {
    backgroundColor: '#fff',
    titleFontColor: '#4A494A',
    bodyFontColor: '#373A3C',
    footerFontColor: '#373A3C',
  },
  legend: {
    position: 'bottom',
    labels: {
      usePointStyle: true,
    },
  },
};

const performanceTrendOptions = {
  scales: {
    yAxes: [
      {
        ticks: {
          min: 0,
          callback: function(value, index, values) {
            return '₦ ' + value.toLocaleString('en');
          },
        },
        scaleLabel: {
          display: false,
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
  },
  tooltips: {
    backgroundColor: '#19C165',
    titleFontColor: '#FFF',
    bodyFontColor: '#FFF',
    footerFontColor: '#373A3C',
  },
  legend: {
    display: false,
  },
};

const LineChartOptions = {
  statsOptions,
  performanceTrendOptions,
};
export default LineChartOptions;
