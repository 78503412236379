import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Card, Input, Form, Select } from 'antd';
import { navigate, Link } from '@reach/router';
import { useFormik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import isEmpty from 'lodash.isempty';
import { CREATE_ROLE_MUTATION, GET_PERMISSIONS } from './query';

// internals
import { PageTitle, appNotification } from '_components';
import { formItemLayout } from '_constants';
import validationSchema from './formValidationSchema';

const { Option } = Select;

const RoleForm = props => {
  const order = { field: 'name', direction: 'ASC' };
  const {
    loading: permissionLoading,
    error: permissionError,
    data: permissionData,
  } = useQuery(GET_PERMISSIONS, { variables: { order } });

  const [addRole] = useMutation(CREATE_ROLE_MUTATION);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {},
    validationSchema,
    async onSubmit(values) {
      const roleData = {
        ...values,
      };
      // console.log(roleData);
      addRole({ variables: { data: roleData } }).then(({ data }) => {
        if (data.createRole._id) {
          appNotification({
            desc: `${data.createRole.name} was successfuly added. Kudos`,
            type: 'success',
          });
          navigate('/roles');
        }
      });
      resetForm();
    },
  });

  useEffect(() => {
    return () => {
      // cancel subscriptions
    };
  }, []);
  return (
    <>
      <PageTitle
        title=" Create Role"
        extra={
          <Row type="flex" justify="end">
            <Link to="../">Back</Link>
          </Row>
        }
      />
      <div className="trans-details">
        <Row type="flex" className="" gutter={[24, 24]}>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}>
            <Row gutter={[24, 24]}>
              <Col span={24} className="">
                <Card
                  bordered={false}
                  style={{ borderRadius: 4 }}
                  title="Add a new Role">
                  <Form layout="horizontal">
                    <Form.Item
                      label="Name"
                      htmlFor="name"
                      colon={false}
                      {...formItemLayout}
                      validateStatus={
                        errors.name && touched.name ? 'error' : null
                      }
                      help={errors.name && touched.name ? errors.name : null}>
                      <Input
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Permissions"
                      htmlFor="permissions"
                      colon={false}
                      {...formItemLayout}
                      validateStatus={
                        errors.permissions && touched.permissions
                          ? 'error'
                          : null
                      }
                      help={
                        errors.permisions && touched.permissions
                          ? errors.permissions
                          : null
                      }>
                      {/* THE SELECT OPTIONS SHOULD FETCHED ASYNC */}
                      <Select
                        showSearch
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Select Permissions for role"
                        optionFilterProp="children"
                        onChange={text => setFieldValue('permissions', text)}
                        // onBlur={handleBlur}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                        {!isEmpty(permissionData) ? (
                          permissionData.permissions.map(permission => (
                            <Option key={permission._id} value={permission._id}>
                              {permission.name}
                            </Option>
                          ))
                        ) : (
                          <Option value={null}>No permisison found</Option>
                        )}
                      </Select>
                    </Form.Item>

                    <Row type="flex" justify="end">
                      <Button
                        key="submit"
                        type="primary"
                        style={{ width: 170 }}
                        disabled={!isEmpty(errors)}
                        onClick={handleSubmit}>
                        Create Role
                      </Button>
                    </Row>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default RoleForm;
