import * as Yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object({
  firstName: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, {
      message: 'Please enter valid characters',
      excludeEmptyString: true,
    })
    .min(2)
    .required('First name is required'),
  middleName: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, {
      message: 'Please enter valid characters',
      excludeEmptyString: true,
    })
    .min(2),
  lastName: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, {
      message: 'Please enter valid characters',
      excludeEmptyString: true,
    })
    .min(2)
    .required('Last name is required'),

  gender: Yup.string()
    .matches(/^[a-zA-Z]+$/, {
      message: 'Please enter valid characters',
      excludeEmptyString: true,
    })
    .min(2),

  membershipLevel: Yup.string()
    .matches(/^[a-zA-Z]+$/, {
      message: 'Please enter valid characters',
      excludeEmptyString: true,
    })
    .min(2),

  dateOfBirth: Yup.date(),

  email: Yup.string().email(),

  // phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  phoneNumber: Yup.string(),

  maritalStatus: Yup.string(),

  photo: Yup.string(),
  occupation: Yup.string(),
  address: Yup.string().min(2),
  city: Yup.string().min(2),
  state: Yup.string().min(2),
  country: Yup.string().min(2),

  branch: Yup.string()
    .matches(/^[a-zA-Z0-9\s]+$/, {
      message: 'Please enter valid characters',
      excludeEmptyString: true,
    })
    .min(2)
    .required('Field is required'),
});

export default validationSchema;
