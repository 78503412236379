import gql from 'graphql-tag';

export const GET_FINANCE_REPORTS = gql`
  query getFinanceReports($order: FinanceReportSortInput) {
    financeReports(order: $order) {
      _id
      offering
      serviceType {
        _id
        name
      }
      service {
        name
      }
      recordedBy {
        _id
        lastName
        firstName
      }

      branch {
        _id
        name
        leader {
          lastName
          firstName
          middleName
          phoneNumber
        }
      }
    }
  }
`;
