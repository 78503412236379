import gql from 'graphql-tag';

export const GET_BRANCH = gql`
  {
    branches {
      _id
      name
    }
  }
`;

export const GET_SERVICE_REPORTS = gql`
  query getServiceReports($order: ServiceReportSortInput) {
    serviceReports(order: $order) {
      _id
      name
      noOfFirstTimers
      totalAttendance
      totalSeed
      totalEgf
      totalTithe
      totalExpense
      totalFinance
      sumTotalIncome
      actualServiceDate
      pastoralRemarks
      branch {
        _id
        name
      }
      recordedBy {
        _id
        firstName
        lastName
      }
      serviceType {
        _id
        name
      }
      service {
        _id
        name
      }
    }
  }
`;

export const ADD_FINANCE_REPORT = gql`
  mutation addFinnceReport($data: FinanceReportInput!) {
    createFinanceReport(input: $data) {
      _id
    }
  }
`;

export const UPDATE_FINANCE_REPORT = gql`
  mutation updateFinanceReport($data: FinanceReportInput!, $id: String!) {
    updateFinanceReport(input: $data, id: $id) {
      _id
    }
  }
`;
