import { uuid } from 'uuidv4';

export const MenuList = [
  {
    to: '/',
    icon: 'dashboard',
    title: 'Dashboard',
  },
  // {
  //   to: '/attendance',
  //   icon: 'team',
  //   title: 'Attendance Report',
  // },
  // {
  //   to: '/reports-analytics',
  //   icon: 'bar-chart',
  //   title: 'Reports analytics',
  // },
  // {
  //   to: '/service-report',
  //   icon: 'profile',
  //   title: 'Service Reports',
  // },
  {
    to: '/converts',
    icon: 'smile',
    title: 'Converts',
  },
  {
    to: '/ft-absorption',
    icon: 'user',
    title: 'FirstTimer Absorption',
  },
];

export const AdminMenuList = [
  {
    key: uuid(),
    icon: 'user-add',
    title: 'Branch',
    subMenu: [
      {
        key: uuid(),
        to: '/branches/new',
        icon: 'user-add',
        title: 'Add Branch',
      },
      {
        key: uuid(),
        to: '/branches',
        icon: 'user-add',
        title: 'View Branches',
      },
    ],
  },
  // 2
  {
    key: uuid(),
    icon: 'user-add',
    title: 'Region',
    subMenu: [
      {
        key: uuid(),
        to: '/regions/new',
        icon: 'user-add',
        title: 'Add Region',
      },
      {
        key: uuid(),
        to: '/regions',
        icon: 'user-add',
        title: 'View Regions',
      },
    ],
  },
  // 3
  {
    key: uuid(),
    icon: 'user-add',
    title: 'Services',
    subMenu: [
      {
        key: uuid(),
        to: '/services/new',
        icon: 'user-add',
        title: 'Add Service',
      },
      {
        key: uuid(),
        to: '/services',
        icon: 'user-add',
        title: 'View Services',
      },
      {
        key: uuid(),
        to: '/services-types/new',
        icon: 'user-add',
        title: 'Add Service Category',
      },
      {
        key: uuid(),
        to: '/services-types',
        icon: 'user-add',
        title: 'View Service Category',
      },
    ],
  },
  // 4
  {
    key: uuid(),
    icon: 'user-add',
    title: 'Roles',
    subMenu: [
      {
        key: uuid(),
        to: '/roles/new',
        icon: 'user-add',
        title: 'Add Role',
      },
      {
        key: uuid(),
        to: '/roles',
        icon: 'user-add',
        title: 'View Roles',
      },
    ],
  }, // 5
  {
    key: uuid(),
    icon: 'user-add',
    title: 'Users',
    subMenu: [
      {
        key: uuid(),
        to: '/users',
        icon: 'user-add',
        title: 'Manage Users',
      },
    ],
  },
];

export const MenuWithSubMenuList = [
  {
    key: uuid(),
    icon: 'user-add',
    title: 'First Timers',
    subMenu: [
      {
        key: uuid(),
        to: '/first-timers/new',
        icon: 'user-add',
        title: 'Add New First Timer',
      },
      {
        key: uuid(),
        to: '/first-timers',
        icon: 'user-add',
        title: 'View First Timers',
      },
    ],
  },
  // 2
  {
    key: uuid(),
    icon: 'team',
    title: 'Members',
    subMenu: [
      {
        key: uuid(),
        to: '/members/new',
        icon: 'team',
        title: 'Add New Member',
      },
      {
        key: uuid(),
        to: '/members/bulk-upload',
        icon: 'teams',
        title: 'Bulk Members',
      },
      {
        key: uuid(),
        to: '/members',
        icon: 'team',
        title: 'View Members',
      },
    ],
  }, // 3
  {
    key: uuid(),
    icon: 'profile',
    title: 'Service Reports',
    subMenu: [
      {
        key: uuid(),
        to: '/service-report',
        icon: 'profile',
        title: 'Add New Reports',
      },
      {
        key: uuid(),
        to: '/services',
        icon: 'profile',
        title: 'View Service Detail',
      },
      {
        key: uuid(),
        to: '/service-report/lists',
        icon: 'profile',
        title: 'View Service Reports',
      },
    ],
  },
  {
    key: uuid(),
    icon: 'profile',
    title: 'Reports List',
    subMenu: [
      {
        key: uuid(),
        to: '/attendance',
        icon: 'profile',
        title: 'Attendance',
      },
      {
        key: uuid(),
        to: '/offering',
        icon: 'profile',
        title: 'Offering',
      },
      {
        key: uuid(),
        to: '/seeds',
        icon: 'profile',
        title: 'Seed',
      },
      {
        key: uuid(),
        to: '/egf',
        icon: 'profile',
        title: 'EGF',
      },
      {
        key: uuid(),
        to: '/tithe',
        icon: 'profile',
        title: 'Tithe',
      },
    ],
  },
];
