import { notification } from 'antd';

//types are success, info, warning, error
const appNotification = ({ desc, title = 'Notification', type = 'info'}) => {
  notification[type]({
    message: title,
    description: desc,
  });
};

export default appNotification;
