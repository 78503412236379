import React, { useState, useEffect } from 'react';
import {
  Col,
  Row,
  Button,
  Card,
  DatePicker,
  Input,
  Form,
  Select,
  Divider,
  Radio,
  Tooltip,
} from 'antd';
import { navigate, Link } from '@reach/router';
import { Field, useFormik } from 'formik';
import isEmpty from 'lodash.isempty';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';

// internals
import { PageTitle, appNotification } from '_components';
import validationSchema from './formValidationSchema';
import { GET_BRANCH, GET_MEMBERS, CREATE_FIRST_TIMERS } from './graphql';

const { Option } = Select;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;
const { TextArea } = Input;

const FirstTimersForm = props => {
  // "location" is a props from reach router
  // i am using it to get the title & id so you can attach form details to it
  const { location } = props;
  const [
    createFirstTimer,
    { loading: mutateLoading, error: mutateError, data: mutateData },
  ] = useMutation(CREATE_FIRST_TIMERS);
  const order = { field: 'name', direction: 'ASC' };
  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(GET_BRANCH, {
    variables: { order },
  });

  const {
    loading: membersLoading,
    error: membersError,
    data: membersData,
  } = useQuery(GET_MEMBERS, { variables: { order } });

  const disabledDate = current => {
    return current && current > moment().endOf('day');
  };

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {},
    validationSchema,
    onSubmit(values) {
      //   if (!values.name) {
      //     delete values.name;
      //   }
      const formData = {
        ...values,
      };
      // console.log(values);
      createFirstTimer({ variables: { data: formData } }).then(({ data }) => {
        data.createFirstTimer._id &&
          appNotification({
            desc: 'First timer details was successfuly added. Kudos',
            type: 'success',
          });
        navigate('/first-timers');
      });

      // resetForm();
    },
  });

  useEffect(() => {
    return () => {
      // cancel subscriptions
    };
  }, []);

  return (
    <>
      <PageTitle
        title="First Timers's Form"
        extra={
          <Row type="flex" justify="end">
            <Link to="/first-timers">Back</Link>
          </Row>
        }
      />
      <div className="trans-details">
        <Row type="flex" className="" gutter={[24, 24]}>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 15 }}
            xxl={{ span: 15 }}>
            <Row gutter={[24, 24]}>
              {/* First Timers Details column */}
              <Col span={24} className="">
                <Card bordered={false} style={{ borderRadius: 4 }}>
                  <h3 className="service text-deep-blue">
                    Add details for a New First Timer
                  </h3>

                  <Form>
                    <Divider orientation="left" className="text-gray">
                      Biodata
                    </Divider>
                    <Row gutter={[8, 8]}>
                      <Col xs={{ span: 24 }} md={{ span: 8 }}>
                        <Form.Item
                          label="First Name"
                          htmlFor="firstName"
                          colon={false}
                          required
                          validateStatus={
                            errors.firstName && touched.firstName
                              ? 'error'
                              : null
                          }
                          help={
                            errors.firstName && touched.firstName
                              ? errors.firstName
                              : null
                          }>
                          <Input
                            name="firstName"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.firstName}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 8 }}>
                        <Form.Item
                          label="Middle Name"
                          htmlFor="middleName"
                          colon={false}
                          validateStatus={
                            errors.middleName && touched.middleName
                              ? 'error'
                              : null
                          }
                          help={
                            errors.middleName && touched.middleName
                              ? errors.middleName
                              : null
                          }>
                          <Input
                            name="middleName"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.middleName}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 8 }}>
                        <Form.Item
                          label="Last Name"
                          htmlFor="lastName"
                          required
                          colon={false}
                          validateStatus={
                            errors.lastName && touched.lastName ? 'error' : null
                          }
                          help={
                            errors.lastName && touched.lastName
                              ? errors.lastName
                              : null
                          }>
                          <Input
                            name="lastName"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.lastName}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} md={{ span: 8 }}>
                        <Form.Item
                          label="Gender"
                          htmlFor="gender"
                          required
                          colon={false}
                          validateStatus={
                            errors.gender && touched.gender ? 'error' : null
                          }
                          help={
                            errors.gender && touched.gender
                              ? errors.gender
                              : null
                          }>
                          <Select
                            // showSearch
                            style={{ width: '100%' }}
                            placeholder="Select gender"
                            optionFilterProp="children"
                            onChange={text => setFieldValue('gender', text)}>
                            <Option value="female">Female</Option>
                            <Option value="male">Male</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      {/* <Col xs={{ span: 24 }} md={{ span: 8 }}>
                        <Form.Item
                          label="Marital Status"
                          htmlFor="maritalStatus"
                          colon={false}
                          validateStatus={
                            errors.maritalStatus && touched.maritalStatus
                              ? 'error'
                              : null
                          }
                          help={
                            errors.maritalStatus && touched.maritalStatus
                              ? errors.maritalStatus
                              : null
                          }>
                          <Select
                            // showSearch
                            style={{ width: '100%' }}
                            placeholder="Select status"
                            optionFilterProp="children"
                            onChange={text =>
                              setFieldValue('maritalStatus', text)
                            }>
                            <Option value="single">Single</Option>
                            <Option value="married">Married</Option>
                            <Option value="divorced">Divorced</Option>
                            <Option value="widow/widower">
                              Widow / Widower
                            </Option>
                          </Select>
                        </Form.Item>
                      </Col> */}
                      <Col xs={{ span: 24 }} md={{ span: 8 }}>
                        <Form.Item
                          label="Age Range"
                          htmlFor="ageRange"
                          colon={false}
                          required
                          validateStatus={
                            errors.ageRange && touched.ageRange ? 'error' : null
                          }
                          help={
                            errors.ageRange && touched.ageRange
                              ? errors.ageRange
                              : null
                          }>
                          <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select status"
                            optionFilterProp="children"
                            onChange={text => setFieldValue('ageRange', text)}>
                            <Option value="11-19">11-19</Option>
                            <Option value="20-29">20-29</Option>
                            <Option value="30-39">30-39</Option>
                            <Option value="40-49">40-49</Option>
                            <Option value="50 and above">50 and above</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Divider orientation="left" className="text-gray">
                      Personal Information
                    </Divider>

                    <Row gutter={[8, 8]}>
                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        xxl={{ span: 8 }}>
                        <Form.Item
                          label="Email"
                          htmlFor="email"
                          colon={false}
                          validateStatus={
                            errors.email && touched.email ? 'error' : null
                          }
                          help={
                            errors.email && touched.email ? errors.email : null
                          }>
                          <Input
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        xxl={{ span: 8 }}>
                        <Form.Item
                          label="Phone Number"
                          htmlFor="phoneNumber"
                          colon={false}
                          required
                          validateStatus={
                            errors.phoneNumber && touched.phoneNumber
                              ? 'error'
                              : null
                          }
                          help={
                            errors.phoneNumber && touched.phoneNumber
                              ? errors.phoneNumber
                              : null
                          }>
                          <IntlTelInput
                            style={{ width: '100%' }}
                            fieldName="phoneNumber"
                            inputClassName="ant-input"
                            nationalMode={false}
                            defaultCountry="ng"
                            preferredCountries={['ng', 'us', 'gb', 'ca']}
                            onPhoneNumberChange={(text, phoneText) =>
                              setFieldValue('phoneNumber', phoneText)
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        xxl={{ span: 8 }}>
                        <Form.Item
                          label="Address"
                          htmlFor="address"
                          colon={false}
                          required
                          validateStatus={
                            errors.address && touched.address ? 'error' : null
                          }
                          help={
                            errors.address && touched.address
                              ? errors.address
                              : null
                          }>
                          <Input
                            name="address"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.address}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        xxl={{ span: 8 }}>
                        <Form.Item
                          label="City"
                          htmlFor="city"
                          colon={false}
                          required
                          validateStatus={
                            errors.city && touched.city ? 'error' : null
                          }
                          help={
                            errors.city && touched.city ? errors.city : null
                          }>
                          <Input
                            name="city"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.city}
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        xxl={{ span: 8 }}>
                        <Form.Item
                          label="Country"
                          htmlFor="country"
                          colon={false}
                          validateStatus={
                            errors.country && touched.country ? 'error' : null
                          }
                          help={
                            errors.country && touched.country
                              ? errors.country
                              : null
                          }>
                          <CountryDropdown
                            className="ant-input"
                            value={values.country}
                            onChange={e => setFieldValue('country', e)}
                            priorityOptions={['NG', 'US', 'GB', 'CA']}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        xxl={{ span: 8 }}>
                        <Form.Item
                          label="State"
                          htmlFor="state"
                          colon={false}
                          validateStatus={
                            errors.state && touched.state ? 'error' : null
                          }
                          help={
                            errors.state && touched.state ? errors.state : null
                          }>
                          <RegionDropdown
                            className="ant-input"
                            country={values.country}
                            value={values.state}
                            onChange={e => setFieldValue('state', e)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Divider orientation="left" className="text-gray">
                      Other Information
                    </Divider>
                    <Row gutter={8}>
                      <Col xs={{ span: 12 }} md={{ span: 8 }} xxl={{ span: 8 }}>
                        <Form.Item
                          label="Occupation"
                          htmlFor="occupation"
                          required
                          colon={false}
                          validateStatus={
                            errors.occupation && touched.occupation
                              ? 'error'
                              : null
                          }
                          help={
                            errors.occupation && touched.occupation
                              ? errors.occupation
                              : null
                          }>
                          <Input
                            name="occupation"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.occupation}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 12 }} md={{ span: 8 }} xxl={{ span: 8 }}>
                        <Form.Item
                          label="Are you born again?"
                          htmlFor="saved"
                          colon={false}
                          required
                          validateStatus={
                            errors.saved && touched.saved ? 'error' : null
                          }
                          help={
                            errors.saved && touched.saved ? errors.saved : null
                          }>
                          <RadioGroup
                            onChange={e =>
                              setFieldValue('saved', e.target.value)
                            }
                            value={values.saved}
                            defaultValue={true}>
                            <RadioButton value={true}>Saved</RadioButton>
                            <RadioButton value={false}>Unsaved</RadioButton>
                          </RadioGroup>
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 12 }} md={{ span: 8 }} xxl={{ span: 8 }}>
                        <Form.Item
                          label="Date Of Salvation"
                          htmlFor="dateOfSalvation"
                          colon={false}
                          // required
                          validateStatus={
                            errors.dateOfSalvation && touched.dateOfSalvation
                              ? 'error'
                              : null
                          }
                          help={
                            errors.dateOfSalvation && touched.dateOfSalvation
                              ? errors.dateOfSalvation
                              : null
                          }>
                          <DatePicker
                            style={{ width: '100%' }}
                            name="dateOfSalvation"
                            format={'MMMM DD YYYY'}
                            disabledDate={disabledDate}
                            onChange={(dateObject, dateString) =>
                              setFieldValue('dateOfSalvation', dateObject)
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={8}>
                      <Col xs={{ span: 12 }} md={{ span: 8 }} xxl={{ span: 8 }}>
                        <Form.Item
                          label="Will you like to join The Dream Centre?"
                          htmlFor="joiningChurch"
                          colon={false}
                          required
                          validateStatus={
                            errors.joiningChurch && touched.joiningChurch
                              ? 'error'
                              : null
                          }
                          help={
                            errors.joiningChurch && touched.joiningChurch
                              ? errors.joiningChurch
                              : null
                          }>
                          <RadioGroup
                            onChange={e =>
                              setFieldValue('joiningChurch', e.target.value)
                            }
                            value={values.joiningChurch}
                            defaultValue={true}>
                            <RadioButton value={true}>Yes</RadioButton>
                            <RadioButton value={false}>Not yet</RadioButton>
                          </RadioGroup>
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 12 }} md={{ span: 8 }} xxl={{ span: 8 }}>
                        <Form.Item
                          label="Invited By"
                          htmlFor="invitedBy"
                          colon={false}
                          required
                          validateStatus={
                            errors.invitedBy && touched.invitedBy
                              ? 'error'
                              : null
                          }
                          help={
                            errors.invitedBy && touched.invitedBy
                              ? errors.invitedBy
                              : null
                          }>
                          <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Invited by"
                            optionFilterProp="children"
                            onChange={text => setFieldValue('invitedBy', text)}
                            // onBlur={handleBlur}
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }>
                            {!isEmpty(membersData) ? (
                              membersData.members.map(member => (
                                <Option
                                  key={member._id}
                                  value={
                                    member.firstName + ' ' + member.lastName
                                  }>
                                  {member.firstName + ' ' + member.lastName}
                                </Option>
                              ))
                            ) : (
                              <Option value={null}>No members defined</Option>
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 12 }} md={{ span: 8 }} xxl={{ span: 8 }}>
                        <Form.Item
                          label="Branch"
                          htmlFor="branch"
                          colon={false}
                          required
                          validateStatus={
                            errors.branch && touched.branch ? 'error' : null
                          }
                          help={
                            errors.branch && touched.branch
                              ? errors.branch
                              : null
                          }>
                          <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Choose branch"
                            optionFilterProp="children"
                            onChange={text => setFieldValue('branch', text)}
                            // onBlur={handleBlur}
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }>
                            {!isEmpty(queryData) ? (
                              queryData.branches.map(type => (
                                <Option key={type._id} value={type._id}>
                                  {type.name}
                                </Option>
                              ))
                            ) : (
                              <Option value={null}>No branch</Option>
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Form.Item
                      validateStatus={errors.prayerPoints ? 'error' : null}
                      help={errors.prayerPoints ? errors.prayerPoints : null}>
                      <label htmlFor="prayerPoints">Prayer Points</label>
                      <TextArea
                        name="prayerPoints"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.prayerPoints}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                      />
                    </Form.Item>

                    <Row type="flex" justify="end" className="mt-2">
                      <Button
                        key="submit"
                        type="primary"
                        // style={{ width: }}
                        disabled={!isEmpty(errors)}
                        onClick={handleSubmit}>
                        Submit Details
                      </Button>
                    </Row>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FirstTimersForm;
