import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Select } from 'antd';
import { Line } from 'react-chartjs-2';

// internal
import { DateRange, CanAccess } from '_components';
import { lineChartConstants } from '_constants';
import { LineChartOptions } from '_helpers';

const data = {
  labels: [...lineChartConstants.WEEK_LABELS],
  datasets: [
    {
      // label: 'Successful',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'transparent',
      borderColor: '#19C165', // The main line color
      borderCapStyle: 'square',
      borderDash: [], // try [5, 15] for instance
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: '#19C165',
      pointBackgroundColor: 'white',
      pointBorderWidth: 1,
      pointHoverRadius: 4,
      pointHoverBackgroundColor: '#19C165',
      pointHoverBorderColor: '#19C165',
      pointHoverBorderWidth: 1,
      pointRadius: 4,
      pointHitRadius: 10,
      data: [1200000, 509000, 680000, 510000, 156000, 450005, 160000],
    },
  ],
};

const { performanceTrendOptions } = LineChartOptions;

const PerformanceTrendChart = () => {
  useEffect(() => {
    //console.log(`status ${status}`);
    return () => {
      // cancel subscriptions
    };
  }, []);

  return (
    <Row className="dashboard__graph-wrapper">
      <Line data={data} options={performanceTrendOptions} height={100} />
    </Row>
  );
};

export default PerformanceTrendChart;
