import React, { useEffect, useState } from 'react';
import { Table, Button, Divider } from 'antd';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { uuid } from 'uuidv4';
import { Link } from '@reach/router';
import filter from 'lodash/filter';
import isEmpty from 'lodash.isempty';
import { SS, Currency } from '_helpers';
import { appNotification as notification } from '_components';
import { GET_SEED_REPORTS } from '../graphql';

const columns = [
  {
    title: 'Seed Name',
    dataIndex: 'seedName',
    // key: 'name',
    // align: 'center',
    // width: '12%',
  },
  {
    title: 'Amount',
    dataIndex: 'seedAmount',
    render: (text, record) => {
      return <>{Currency.format(record.seedAmount)}</>;
    },
  },

  {
    title: 'Recorded For',
    dataIndex: 'recordedFor',
  },

  {
    title: 'Branch',
    dataIndex: 'branch',
    render: (text, record) => {
      return <>{record.branch}</>;
    },
  },
  {
    title: 'Service Type',
    dataIndex: 'serviceType',
  },
  {
    title: 'Recorded by',
    dataIndex: 'recordedBy',
    // render: (text, record) => {
    //   return <>{record.recordedBy}</>;
    // },
  },
  {
    title: 'Remarks',
    dataIndex: 'remarks',
  },

  {
    title: 'Action',
    key: '',
    dataIndex: '',
    align: 'center',
    // width: 160,
    render: (text, record) => {
      return (
        <>
          <Link to={`details/${record.id}`}>
            <Button
              key={uuid()}
              size="small"
              // href={`/ft-absorption/${uuid()}`}
              onClick={() => SS.save('seed-details', record)}
              className="table-view__button">
              View
            </Button>
          </Link>
        </>
      );
    },
  },
];

const update = data =>
  data &&
  data.map(seed => ({
    key: uuid(),
    id: seed._id,
    seedName: seed.seedName,
    seedAmount: seed.seedAmount,
    // recordedBy: seed.recordedBy,
    recordedBy:
      seed.recordedBy &&
      Object.values(seed.recordedBy)
        .slice(1, -1)
        .join(' '),
    recordedFor:
      seed.member &&
      Object.values(seed.member)
        .slice(1, -1)
        .join(' '),
    service: seed.service.name,
    serviceType: seed.serviceType.name,
    branch: seed.branch.name,
    remarks: seed.seedRemarks,
  }));

const DataTable = ({ searchQuery }) => {
  const isLoading = false;
  const [seedsData, updateList] = useState([]);
  const [page, setPage] = useState(1);
  const order = { field: 'seedName', direction: 'Desc' };

  const { loading, error, data } = useQuery(GET_SEED_REPORTS, {
    variables: { order },
  });
  // console.log(data);

  // CONTROL PAGINATION DATA
  const pagination = {
    total: seedsData.length,
    pageSize: 10,
    current: page,
    onChange: page => {
      setPage(page);
      // dispatch(fetchAgents(page));
    },
  };

  useEffect(() => {
    let results = [];
    if (searchQuery.length > 3) {
      results = filter(seedsData, seedReports => {
        const name = Object.values(seedReports)
          .slice(1, 4)
          .join(' ')
          .toLowerCase();

        return (
          name.includes(searchQuery.toLowerCase()) ||
          name.indexOf(searchQuery.toLowerCase()) !== -1
        );
      });
      updateList(results);
    }
    return () => {
      // cancel subscriptions
      if (data && data.seedReports) {
        updateList(update(data.seedReports));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    if (data && data.seedReports) {
      updateList(update(data.seedReports));
    }
  }, [data, loading]);

  useEffect(() => {
    return () => {
      // cancel subscriptions
    };
  }, [page]);

  return (
    <div style={{ marginTop: 8 }}>
      <Table
        className="data__table"
        columns={columns}
        dataSource={seedsData}
        pagination={pagination}
        loading={isLoading}
      />
    </div>
  );
};

export default DataTable;
