import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Card, Input, Form, Select } from 'antd';
import { navigate, Link } from '@reach/router';
import { useFormik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import isEmpty from 'lodash.isempty';
import { CREATE_REGION_MUTATION } from './query';
import { GET_MEMBERS } from '../../pages/Members/query';
import { GET_BRANCH } from '../../pages/Branches/query';

// internals
import { PageTitle, appNotification } from '_components';
import { formItemLayout } from '_constants';
import validationSchema from './formValidationSchema';

const { Option } = Select;

const RegionForm = props => {
  const order = { field: 'name', direction: 'ASC' };
  const {
    loading: branchLoading,
    error: branchError,
    data: branchData,
  } = useQuery(GET_BRANCH, { variables: { order } });

  const {
    loading: membersLoading,
    error: membersError,
    data: membersData,
  } = useQuery(GET_MEMBERS, { variables: { order } });

  const [addRegion] = useMutation(CREATE_REGION_MUTATION);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {},
    validationSchema,
    async onSubmit(values) {
      const regionData = {
        ...values,
      };
      // console.log(regionData);
      addRegion({ variables: { data: regionData } }).then(({ data }) => {
        if (data.createRegion._id) {
          appNotification({
            desc: `${data.createRegion.name} was successfuly added. Kudos`,
            type: 'success',
          });
          navigate('/regions');
        }
      });
      resetForm();
    },
  });

  useEffect(() => {
    return () => {
      // cancel subscriptions
    };
  }, []);
  return (
    <>
      <PageTitle
        title=" Create Region"
        extra={
          <Row type="flex" justify="end">
            <Link to="../">Back</Link>
          </Row>
        }
      />
      <div className="trans-details">
        <Row type="flex" className="" gutter={[24, 24]}>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}>
            <Row gutter={[24, 24]}>
              <Col span={24} className="">
                <Card
                  bordered={false}
                  style={{ borderRadius: 4 }}
                  title="Add a new Region">
                  <Form layout="horizontal">
                    <Form.Item
                      label="Name"
                      htmlFor="name"
                      colon={false}
                      {...formItemLayout}
                      validateStatus={
                        errors.name && touched.name ? 'error' : null
                      }
                      help={errors.name && touched.name ? errors.name : null}>
                      <Input
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Region Leader"
                      htmlFor="leader"
                      colon={false}
                      {...formItemLayout}
                      validateStatus={
                        errors.leader && touched.leader ? 'error' : null
                      }
                      help={
                        errors.leader && touched.leader ? errors.leader : null
                      }>
                      {/* <Input
                        name="leader"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.leader}
                      /> */}
                      <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select Leader"
                        optionFilterProp="children"
                        onChange={text => setFieldValue('leader', text)}
                        // onBlur={handleBlur}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                        {!isEmpty(membersData) ? (
                          membersData.members.map(member => (
                            <Option key={member._id} value={member._id}>
                              {member.firstName + ' ' + member.lastName}
                            </Option>
                          ))
                        ) : (
                          <Option value={null}>No leaders defined</Option>
                        )}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      label="Branches"
                      htmlFor="branches"
                      colon={false}
                      {...formItemLayout}
                      validateStatus={
                        errors.branches && touched.branches ? 'error' : null
                      }
                      help={
                        errors.branches && touched.branches
                          ? errors.branches
                          : null
                      }>
                      {/* THE SELECT OPTIONS SHOULD FETCHED ASYNC */}
                      <Select
                        showSearch
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Select Branches"
                        optionFilterProp="children"
                        onChange={text => setFieldValue('branches', text)}
                        // onBlur={handleBlur}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                        {!isEmpty(branchData) ? (
                          branchData.branches.map(branch => (
                            <Option key={branch._id} value={branch._id}>
                              {branch.name}
                            </Option>
                          ))
                        ) : (
                          <Option value={null}>No branch defined</Option>
                        )}
                      </Select>
                    </Form.Item>

                    <Row type="flex" justify="end">
                      <Button
                        key="submit"
                        type="primary"
                        style={{ width: 170 }}
                        disabled={!isEmpty(errors)}
                        onClick={handleSubmit}>
                        Create Region
                      </Button>
                    </Row>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default RegionForm;
