import gql from 'graphql-tag';

export const GET_SERVICE_CATEGORY = gql`
  {
    serviceTypes {
      _id
      name
      url
    }
  }
`;
