import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Select } from 'antd';
import { Line } from 'react-chartjs-2';

// internal
import { DateRange, CanAccess, Loader } from '_components';
import { lineChartConstants } from '_constants';
import { LineChartOptions } from '_helpers';

const { statsOptions } = LineChartOptions;

const { Option } = Select;

const LineChart = () => {
  const [isLoading, setIsLoading] = useState(true);
  const successfulData = [65, 129, 80, 81, 56, 55, 40, 32, 60, 55, 30, 78];
  const failedData = [45, 70, 60, 75, 64, 78, 90, 43, 50, 60, 73, 79];
  const failedData3 = [70, 80, 60, 95, 99, 78, 90, 43, 77, 90, 76, 89];

  const data = {
    labels: [...lineChartConstants.MONTH_LABELS],
    datasets: [
      {
        label: 'Offering',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'transparent',
        borderColor: '#19C165', // The main line color
        borderCapStyle: 'square',
        borderDash: [], // try [5, 15] for instance
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#19C165',
        pointBackgroundColor: 'white',
        pointBorderWidth: 1,
        pointHoverRadius: 4,
        pointHoverBackgroundColor: '#19C165',
        pointHoverBorderColor: '#19C165',
        pointHoverBorderWidth: 1,
        pointRadius: 4,
        pointHitRadius: 10,
        data: successfulData,
      },
      {
        label: 'Tithes',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(167,105,0,0.4)',
        borderColor: '#FF5660',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#FF5660',
        pointBackgroundColor: 'white',
        pointBorderWidth: 1,
        pointHoverRadius: 4,
        pointHoverBackgroundColor: '#FF5660',
        pointHoverBorderColor: '#FF5660',
        pointHoverBorderWidth: 2,
        pointRadius: 4,
        pointHitRadius: 10,
        data: failedData,
      },
      {
        label: 'EGF',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(167,105,0,0.4)',
        borderColor: '#ffc106',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#ffc106',
        pointBackgroundColor: 'white',
        pointBorderWidth: 1,
        pointHoverRadius: 4,
        pointHoverBackgroundColor: '#ffc106',
        pointHoverBorderColor: '#ffc106',
        pointHoverBorderWidth: 2,
        pointRadius: 4,
        pointHitRadius: 10,
        data: failedData3,
      },
    ],
  };

  const [currentBank, setCurrentBank] = useState(null);

  function handleDaysAgo(e) {
    // console.log(`radio checked:${e.target.value}`);
  }

  function handleChange(value) {
    setCurrentBank(value);
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    return () => {
      // cancel subscriptions
    };
  }, [currentBank]);

  return (
    <Row className="dashboard__graph-wrapper">
      <Card className="dashboard__graph-card">
        <Row type="flex" justify="space-between" className="mb-3 mt-1">
          <Col>
            <h1>Financial Statistics</h1>
          </Col>
        </Row>
        <Loader isLoading={isLoading}>
          <Line data={data} options={statsOptions} height={85} />
        </Loader>
      </Card>
    </Row>
  );
};

export default LineChart;
