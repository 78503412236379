import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Col,
  Row,
  Button,
  message,
  Icon,
  Modal,
  Upload,
} from 'antd';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import isEmpty from 'lodash.isempty';
import { Link } from '@reach/router';

//internals
import { PageTitle } from '_components';
import { DataTable } from './components';

const validationSchema = Yup.object({
  firstName: Yup.string()
    .matches(/^[a-zA-Z0-9]+$/, {
      message: 'Please enter valid characters',
      excludeEmptyString: true,
    })
    .min(2, 'Too Short! Please enter more characters')
    .max(50, 'Too Long! Please enter no more than 50 characters'),
});

const Rolemanagement = () => {
  const [page, setPage] = useState(0);

  const handleReset = () => {
    resetForm();
    // passed 1 because i want to "page 1" from API
  };

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    resetForm,
  } = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema,
    onSubmit(values) {
      if (!values.name) {
        delete values.name;
      }
      const data = {
        ...values,
      };

      // resetForm();
    },
  });

  useEffect(() => {
    // api call here

    return () => {
      // cancel subscriptions
    };
  }, []);

  return (
    <div>
      <PageTitle title="Roles"></PageTitle>

      {/* <div className="mb-2 mt-2">
        <Row type="flex" justify="end" gutter={8}>
          <Col className="filter__panel_btn_wrapper">
            <Link to="/roles">
              <Button type="primary" icon="plus" className="medium__btn">
                Add New Role
              </Button>
            </Link>
          </Col>
        </Row>
      </div> */}
      <div className="mb-2 mt-2">
        <Row gutter={8} type="flex" justify="space-between">
          <Col span={15}>
            <Row gutter={8} type="flex" justify="start">
              <Col>
                <Form.Item
                  validateStatus={errors.name ? 'error' : null}
                  help={
                    errors.name && touched.name ? 'Role name is required' : null
                  }>
                  <label htmlFor="">Search for Roles</label>
                  <Input
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    placeholder="eg: Pastor"
                  />
                </Form.Item>
              </Col>

              <Col className="filter__panel_btn_wrapper">
                <Button
                  type="primary"
                  icon="search"
                  className="medium__btn"
                  onClick={handleSubmit}>
                  Search for Roles
                </Button>
              </Col>
              <Col className="filter__panel_btn_wrapper ">
                <Button
                  icon="undo"
                  className="medium__btn"
                  onClick={handleReset}>
                  Clear
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={9}>
            <Row type="flex" justify="end" gutter={8}>
              <Col className="filter__panel_btn_wrapper">
                <Link to="new">
                  <Button type="primary" icon="plus" className="medium__btn">
                    Create New Roles
                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <DataTable searchQuery={values.name} />
    </div>
  );
};

export default Rolemanagement;
