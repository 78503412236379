import React, { useState, useEffect } from 'react';
import {
  Col,
  Row,
  Button,
  Card,
  Input,
  Form,
  Select,
  Alert,
  Icon,
  Spin,
} from 'antd';
import { Link, navigate } from '@reach/router';
import { useFormik } from 'formik';
import isEmpty from 'lodash.isempty';
import { useMutation } from '@apollo/client';

// internals
import { PageTitle, appNotification } from '_components';
import { formItemLayout } from '_constants';
import validationSchema from './formValidationSchema';
import { ADD_SERVICE_TYPE } from './graphql';

const { Option } = Select;

const ServiceTypeForm = props => {
  const [
    addServiceType,
    { loading: mutateLoading, error: mutateError, data: mutateData },
  ] = useMutation(ADD_SERVICE_TYPE);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    dirty,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {},
    validationSchema,
    onSubmit(values) {
      //   if (!values.name) {
      //     delete values.name;
      //   }
      const formData = {
        ...values,
      };

      addServiceType({ variables: { data: formData } }).then(({ data }) => {
        data.createServiceType._id &&
          appNotification({
            desc: 'Your Service Category was successfuly added. Kudos',
            type: 'success',
          });
        navigate('/services-types');
      });

      resetForm();
    },
  });

  useEffect(() => {
    return () => {
      // cancel subscriptions
    };
  }, []);
  return (
    <>
      <PageTitle
        title="Service Catergory"
        extra={
          <Row type="flex" justify="end">
            <Link to="../">Back</Link>
          </Row>
        }
      />
      <div className="trans-details">
        <Row type="flex" className="" gutter={[24, 24]}>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}>
            <Row gutter={[24, 24]}>
              <Col span={24} className="">
                <Card
                  bordered={false}
                  style={{ borderRadius: 4 }}
                  title="Add a new service category">
                  <Form layout="horizontal">
                    <Spin
                      size="large"
                      tip="Submitting your report, Kindly hold on"
                      spinning={mutateLoading}>
                      <Form.Item>
                        <Alert
                          message="Informational Notes"
                          description="Service Catergories varies from Living By the Answer, Apostolic Service, Weekend of Glory etc. You need to create one before you can create a service"
                          type="info"
                          icon={<Icon type="info-circle" theme="filled" />}
                          showIcon
                          banner
                        />
                      </Form.Item>

                      <Form.Item
                        label="Name"
                        htmlFor="name"
                        colon={false}
                        {...formItemLayout}
                        validateStatus={
                          errors.name && touched.name ? 'error' : null
                        }
                        help={errors.name && touched.name ? errors.name : null}>
                        <Input
                          name="name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                        />
                      </Form.Item>

                      <Row type="flex" justify="end">
                        <Button
                          key="submit"
                          type="primary"
                          disabled={!isEmpty(errors) || !dirty}
                          onClick={handleSubmit}>
                          Create Service Category
                        </Button>
                      </Row>
                    </Spin>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ServiceTypeForm;
