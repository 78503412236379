import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const options = {
  tooltips: {
    backgroundColor: '#fff',
    titleFontColor: '#4A494A',
    bodyFontColor: '#373A3C',
    footerFontColor: '#373A3C',
    borderColor: '#373',
  },
  legend: {
    display: false,
    position: 'bottom',
    align: 'center',
    boxWidth: 20,
  },
  cutoutPercentage: 75,
};

const RateBreakdownChart = ({ type, data, rate }) => {
  return (
    <>
      <Doughnut data={data} options={options} height={110} width={300} />
      <div className="inside-donut-chart-label">
        <h3 className="donut-chart__number">{rate}%</h3>
        <h4 className="donut-chart__detail">{type} Rate</h4>
      </div>
    </>
  );
};

export default RateBreakdownChart;
