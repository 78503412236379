import React from 'react';
import { Col, Row, Icon, Card, List, Tooltip, Badge, Button } from 'antd';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';

// THIS IS CONSTANT except we want to add new reports for each service.
const reportsList = [
  {
    typeOfReport: 'Attendance',
    addReportUrl: '/attendance/new',
    viewReportUrl: '/attendance',
    status: 'unsubmitted', // you will have to check individually for the status of the report
  },
  {
    typeOfReport: 'Offering',
    addReportUrl: '/offering/new',
    viewReportUrl: '/offering',
    status: 'submitted',
  },
  {
    typeOfReport: 'Seeds',
    addReportUrl: '/seeds/new',
    viewReportUrl: '/seeds',
    status: 'unsubmitted',
  },
  {
    typeOfReport: 'Expenses',
    addReportUrl: '/expenses/new',
    viewReportUrl: '/expenses',
    status: 'submitted',
  },
];

const ServiceCards = ({ title, serviceType, _id }) => {
  return (
    <Col
      xs={{ span: 24 }}
      sm={{ span: 12 }}
      lg={{ span: 12 }}
      xl={{ span: 8 }}
      xxl={{ span: 6 }}>
      <Card className="service-card" bordered={false}>
        {/* <Link to={`details/${_id}`}> */}
        <Link to="lists">
          <h3 className="text-deep-blue service-card-title">
            {title} <Icon type="link" style={{ color: '#0fabbc' }} />
          </h3>
        </Link>
        <p className="title-card-desc">{serviceType}</p>

        <List
          className="demo-loadmore-list"
          itemLayout="horizontal"
          split={false}
          size="small"
          dataSource={reportsList}
          renderItem={item => (
            <List.Item
              actions={[
                <span>
                  <Link to={item.addReportUrl}>add</Link>
                </span>,
                <Link to={item.viewReportUrl}>view</Link>,
              ]}>
              <List.Item.Meta
                description={
                  <>
                    <span>{item.typeOfReport}</span> &nbsp;
                  </>
                }
              />
            </List.Item>
          )}
        />
      </Card>
    </Col>
  );
};

ServiceCards.propTypes = {
  title: PropTypes.string.isRequired,
  serviceType: PropTypes.string.isRequired,
};

ServiceCards.defaultProps = {
  title: 'Unknown Service',
  serviceType: 'Unknown Service Category',
};

export default ServiceCards;
