import * as Yup from 'yup';

const validationSchema = Yup.object({
  // name: Yup.string()
  //   .matches(/^[a-zA-Z0-9\s]+$/, {
  //     message: 'Please enter valid characters',
  //     excludeEmptyString: true,
  //   })
  //   .min(2)
  //   .max(50)
  //   .required('Field is required'),

  firstDateInChurch: Yup.date(),

  // followUpContact: Yup.string()
  //   .matches(/^[a-zA-Z0-9\s]+$/, {
  //     message: 'Please enter valid characters',
  //     excludeEmptyString: true,
  //   })
  //   .min(2)
  //   .max(50),

  // newLifeTeacher: Yup.string()
  //   .matches(/^[a-zA-Z0-9\s]+$/, {
  //     message: 'Please enter valid characters',
  //     excludeEmptyString: true,
  //   })
  //   .min(2)
  //   .max(50),

  // familyUnitLeader: Yup.string()
  //   .matches(/^[a-zA-Z0-9\s]+$/, {
  //     message: 'Please enter valid characters',
  //     excludeEmptyString: true,
  //   })
  //   .min(2)
  //   .max(50)
  //   .required('Family unit leader is required'),

  // believerClassTeacher: Yup.string()
  //   .matches(/^[a-zA-Z0-9\s]+$/, {
  //     message: 'Please enter valid characters',
  //     excludeEmptyString: true,
  //   })
  //   .min(2)
  //   .max(50),

  // newLifeStage: Yup.number()
  //   .typeError('Must be in digits')
  //   .positive()
  //   .min(1, 'Class begins at 1')
  //   .max(7, 'Last class is 7')
  //   .integer(),

  // believerClassStage: Yup.number()
  //   .typeError('Must be in digits')
  //   .positive()
  //   .min(1, 'Class begins at 1')
  //   .max(52, 'Last class is 52')
  //   .integer(),

  remark: Yup.string(),
});

export default validationSchema;
