import gql from 'graphql-tag';

export const GET_FIRSTTIMERS = gql`
  {
    firstTimers {
      _id
      lastName
      middleName
      firstName
      gender
      ageRange
      phoneNumber
      email
      saved
      dateOfSalvation
      joiningChurch
      invitedBy
      prayerPoints
      occupation
      address
      city
      state
      country
      branch {
        _id
        name
      }
    }
  }
`;
