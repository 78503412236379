import gql from 'graphql-tag';

export const GET_MEMBERS = gql`
  {
    members {
      _id
      firstName
      middleName
      lastName
      dateOfBirth
      email
      gender
      phoneNumber
      membershipLevel
      branch {
        name
      }
      maritalStatus
      photo {
        fileUrl
      }
      occupation
      address
      city
      state
      country
      verified
      branch {
        name
      }
    }
  }
`;

// photo: MediaLibraryType
// occupation: String
// address: String
// city: String
// state: String
// country: String
// branch: BranchType
// user: UserType
