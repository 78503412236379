import gql from 'graphql-tag';

export const GET_BRANCH = gql`
  {
    branches {
      _id
      name
    }
  }
`;
//fix correct mutation
export const CREATE_MEMBERS = gql`
  mutation createMember($data: MemberInput!) {
    createMember(input: $data) {
      _id
    }
  }
`;
