import gql from 'graphql-tag';

// mutate regions
export const CREATE_REGION_MUTATION = gql`
  mutation createRegion($data: RegionInput!) {
    createRegion(input: $data) {
      _id
      name
    }
  }
`;
