import React from 'react';
import { Col, Row, Button, Divider } from 'antd';
import { Steps, useSteps } from 'react-step-builder';
import {
  AttendanceForm,
  SeedsForm,
  FinanceForm,
  EGFForm,
  TitheForm,
} from 'formPages';
import './MultistepFormsPage.css';

const MultistepForms = props => {
  const { prev, next, total, current, progress } = useSteps();

  return (
    <div className="page-container">
      <Steps>
        <AttendanceForm
          next={next}
          isLastStep={current === total}
          createServiceReport={props?.location?.state?.createServiceReport}
        />
        <FinanceForm
          next={next}
          isLastStep={current === total}
          createServiceReport={props?.location?.state?.createServiceReport}
        />

        <TitheForm
          next={next}
          isLastStep={current === total}
          createServiceReport={props?.location?.state?.createServiceReport}
        />

        <SeedsForm
          next={next}
          isLastStep={current === total}
          createServiceReport={props?.location?.state?.createServiceReport}
        />

        <EGFForm
          next={next}
          isLastStep={current === total}
          createServiceReport={props?.location?.state?.createServiceReport}
        />
      </Steps>

      <Divider />

      <Row type="flex" align="middle" justify="center" gutter={[64, 64]}>
        <Col>
          Form Page: {current} / {total}
        </Col>
        <Col>Progress: {progress * 100}%</Col>
        <Col>
          <Button className="" onClick={prev}>
            Previous
          </Button>
          <Button className="ml-1" onClick={next}>
            Next
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default MultistepForms;
