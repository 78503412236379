import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row, Select } from 'antd';

import './PageTitle.css';

const PageTitle = ({ title, extra }) => {
  return (
    <Row type="flex" gutter={8} justify="space-between" className="mb-3">
      <Col span={12}>
        <h1 className="page__h1">{title}</h1>
      </Col>
      <Col span={12}>{extra}</Col>
    </Row>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  extra: PropTypes.element,
};

export default PageTitle;
