import React from 'react';
import { Form, Input, Col, Row, Button } from 'antd';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Link } from '@reach/router';

import { PageTitle } from '_components';
import { DataTable } from './components';

const validationSchema = Yup.object({
  firstName: Yup.string()
    .matches(/^[a-zA-Z0-9]+$/, {
      message: 'Please enter valid characters',
      excludeEmptyString: true,
    })
    .min(2, 'Too Short! Please enter more characters')
    .max(50, 'Too Long! Please enter no more than 50 characters'),
});

const ServiceReportsList = () => {
  const handleReset = () => {
    resetForm();
  };

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    resetForm,
  } = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema,
    onSubmit(values) {
      if (!values.name) {
        delete values.name;
      }
    },
  });

  return (
    <div>
      <PageTitle title="Service Report Lists" />

      <div className="mb-2 mt-2">
        <Row gutter={8} type="flex" justify="space-between">
          <Col span={15}>
            <Row gutter={8} type="flex" justify="start" align="middle">
              <Col xs={{ span: 12 }} md={{ span: 8 }}>
                <Form.Item
                  label="Search"
                  htmlFor="name"
                  colon={false}
                  validateStatus={errors.name ? 'error' : null}
                  help={
                    errors.name && touched.name ? 'Name is required' : null
                  }>
                  <Input
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    placeholder="eg: living by answer"
                  />
                </Form.Item>
              </Col>

              <Col>
                <Button
                  type="primary"
                  icon="search"
                  size="large"
                  className="btn-h-100 mr-1"
                  onClick={handleSubmit}>
                  Search
                </Button>
                <Button
                  icon="undo"
                  size="large"
                  className="btn-h-100"
                  onClick={handleReset}>
                  Clear
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={9}>
            <Row type="flex" justify="end" gutter={8}>
              <Col className="filter__panel_btn_wrapper">
                <Link to="/service-report">
                  <Button type="primary" icon="plus" size="large">
                    New Report
                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <DataTable searchQuery={values.name} />
    </div>
  );
};

export default ServiceReportsList;
