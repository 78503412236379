import * as Yup from 'yup';

/*
programme
date
pastors
men
women
brothers
sisters
teenagers
children
tithe
offering
egf
otherSeedsName
otherSeedsAmount
expenses
expensesAmount
*/

const validationSchema = Yup.object({
  serviceReport: Yup.string()
    .matches(/^[a-zA-Z0-9\s]+$/, {
      message: 'Please enter valid characters',
      excludeEmptyString: true,
    })
    .min(2),

  date: Yup.date(),

  pastor: Yup.number()
    .required('Please enter a value for Pastor/Leader')
    .typeError('Must be in digits')
    .min(0)
    .integer(),

  men: Yup.number()
    .typeError('Must be in digits')
    .min(0)
    .integer(),
  women: Yup.number()
    .typeError('Must be in digits')
    .min(0)
    .integer(),
  brothers: Yup.number()
    .typeError('Must be in digits')
    .min(0)
    .integer(),
  sisters: Yup.number()
    .typeError('Must be in digits')
    .min(0)
    .integer(),
  teenagers: Yup.number()
    .typeError('Must be in digits')
    .min(0)
    .integer(),
  children: Yup.number()
    .typeError('Must be in digits')
    .min(0)
    .integer(),
});

export default validationSchema;
