import React from 'react';
import { Col, Row, Card, Icon } from 'antd';
import moment from 'moment';
import { navigate } from '@reach/router';

// internals
import { SS } from '_helpers';
import { PageTitle } from '_components';

const AttendanceDetails = props => {
  const details = SS.get('attendance-details');
  return (
    <>
      <PageTitle
        title="Attendance Details"
        extra={
          <Row type="flex" justify="end">
            <a
              onClick={() => {
                navigate(-1);
              }}>
              <Icon className="mx-1" type="left-circle" /> Back{' '}
            </a>
          </Row>
        }
      />
      <Row gutter={[24, 24]}>
        {/* Payment Details column */}
        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
          className="">
          <Card bordered={false} style={{ borderRadius: 4 }}>
            <Row className="markdown__row" type="flex" justify="space-between">
              <Col span={18}>
                <span style={{ marginRight: '1em', marginLeft: '1em' }}>
                  {details.name}
                </span>
              </Col>
            </Row>
            <div className="details_divider"></div>
            <div className="markdown ">
              <table className="">
                <tbody>
                  <tr>
                    <td>Date & Time</td>
                    <td>
                      {moment('2020-06-20').format('MMM DD YYYY, h:mm:ss a')}
                    </td>
                  </tr>

                  <tr>
                    <td>Pastors</td>
                    <td>{details.pastors || details.pastor}</td>
                  </tr>
                  <tr>
                    <td>Men</td>
                    <td>{details.men}</td>
                  </tr>
                  <tr>
                    <td>Women</td>
                    <td>{details.women}</td>
                  </tr>
                  <tr>
                    <td>Brothers</td>
                    <td>{details.brothers}</td>
                  </tr>
                  <tr>
                    <td>Sisters</td>
                    <td>{details.sisters}</td>
                  </tr>
                  <tr>
                    <td>Teenagers</td>
                    <td>{details.teenagers}</td>
                  </tr>
                  <tr>
                    <td>Samuel Club</td>
                    <td>{details.samuelClub}</td>
                  </tr>
                  <tr>
                    <td>Children</td>
                    <td>{details._children || details.children}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AttendanceDetails;
