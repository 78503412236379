import * as Yup from 'yup';

export const newSchema = Yup.object().shape({
  recordedBy: Yup.string(),
  serviceType: Yup.string(),
  serviceReport: Yup.string().required('Service Report is required'),
  service: Yup.string(),
  actualServiceDate: Yup.string(),
  branch: Yup.string(),
  titheDetails: Yup.array().of(
    Yup.object().shape({
      member: Yup.string().required('Member is required'),
      titheAmount: Yup.number()
        .typeError('Amount must be in digits')
        .positive('Amount must be greater than zero')
        .integer('Amount must be integer')
        .required('Amount is required'),
      titheRemarks: Yup.string().min(2, 'Remark too short'),
    }),
  ),
});
