import React from 'react';
import { Menu, Dropdown, Icon } from 'antd';

const menu = (
  <Menu style={{ width: 100 }}>
    <Menu.Item>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="http://www.alipay.com/">
        Today
      </a>
    </Menu.Item>
    <Menu.Item>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="http://www.alipay.com/">
        Week
      </a>
    </Menu.Item>
    <Menu.Item>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="http://www.taobao.com/">
        Month
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="http://www.tmall.com/">
        Year
      </a>
    </Menu.Item>
  </Menu>
);

const FilterDropdown = () => {
  return (
    <Dropdown overlay={menu} placement="bottomRight">
      <Icon type="more" />
    </Dropdown>
  );
};

export default FilterDropdown;
