import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { useQuery } from '@apollo/client';
import { GET_MY_PROFILE } from '../Profile/graphql';
import { Loader } from '_components';
import Dashboard from 'pages/Dashboard';

import { envConfig } from '_helpers';
import { get } from '_helpers/localStorage';

const Root = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const {
    data: profileData,
    error,
    loading,
    refetch,
  } = useQuery(GET_MY_PROFILE, { skip: !get('token') });
  const [defaultRole, setDefault] = useState(false);
  useEffect(() => {
    const isOnlyDefaultRole = !!profileData?.myProfile?.roles?.every(
      ({ _id }) => _id === envConfig.defaultRoleId,
    );
    if (!(profileData != null) && !profileData && !loading) {
      navigate('/profile');
    } else if (profileData && isOnlyDefaultRole) {
      setDefault(true);
    } else if (profileData) {
      navigate(window.location.pathname || '/welcome');
    }
    if (!profileData) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData, loading]);

  if (!isAuthenticated) return loginWithRedirect();
  // eslint-disable-next-line
  if (error) console.log(error);
  if (loading) return <Loader isLoading={loading} />;
  if (defaultRole) {
    return (
      <div>
        <h2>Please Contact the administrator to assign roles</h2>
      </div>
    );
  } else {
    return <Dashboard />;
  }
};

export default withAuthenticationRequired(Root);
