import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Card, Tag, Timeline, Radio, Icon } from 'antd';
import { navigate, Link } from '@reach/router';
import { uuid } from 'uuidv4';

// internals
import { PageTitle } from '_components';
import { SS, Currency } from '_helpers';

const SeedsReportDetails = props => {
  const [details, setDetails] = useState(SS.get('seed-details'));
  // console.log(details);
  // change tag color: success || failed || pending

  useEffect(() => {
    return () => {
      // cancel subscriptions
    };
  }, []);

  return (
    <>
      <PageTitle
        title="Seed Details"
        extra={
          <Row type="flex" justify="end">
            <a
              onClick={() => {
                navigate(-1);
              }}>
              <Icon className="mx-1" type="left-circle" /> Back{' '}
            </a>
          </Row>
        }
      />
      <div className="trans-details">
        <Row type="flex" className="" gutter={[24, 24]}>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}>
            <Row gutter={[24, 24]}>
              {/* First Timers Details column */}
              <Col span={24} className="">
                <Card bordered={false} style={{ borderRadius: 4 }}>
                  <Row
                    className="markdown__row"
                    type="flex"
                    justify="space-between">
                    <Col span={18}>
                      <h3 className="mx-1">{`${details?.seedName}'s Details`}</h3>
                    </Col>
                  </Row>
                  <div className="details_divider"></div>
                  <div className="markdown ">
                    <table className="">
                      <tbody>
                        <tr>
                          <td>Seed Name</td>
                          <td>{details?.seedName}</td>
                        </tr>
                        <tr>
                          <td>Seed Amount</td>
                          <td>{Currency.format(details?.seedAmount)}</td>
                        </tr>

                        <tr>
                          <td>Recorded For</td>
                          <td>{details?.recordedFor}</td>
                        </tr>

                        <tr>
                          <td>Service</td>
                          <td>{details?.service}</td>
                        </tr>
                        <tr>
                          <td>Service Type</td>
                          <td>{details?.serviceType}</td>
                        </tr>
                        <tr>
                          <td>Branch</td>
                          <td>{details?.branch}</td>
                        </tr>

                        <tr>
                          <td>Recorded By</td>
                          <td>{details?.recordedBy}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}>
            <Row gutter={[24, 24]}>
              {/* Payment Method column */}
              <Col span={24} className="">
                <Card bordered={false} style={{ borderRadius: 4 }}>
                  <Row
                    className="markdown__row"
                    type="flex"
                    justify="space-between">
                    <Col span={18}>
                      <span style={{ marginRight: '1em', marginLeft: '1em' }}>
                        Pastors' Remark
                      </span>
                    </Col>
                  </Row>
                  <div className="details_divider"></div>
                  <div className="markdown ">
                    <p className="px-1 py-1">{details?.seedRemarks}</p>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SeedsReportDetails;
