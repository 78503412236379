import React, { useState } from 'react';
import { Card } from 'antd';
import { uuid } from 'uuidv4';
import moment from 'moment';

// internals
import { Loader } from '_components';

const data = [
  {
    title: 'Test nofity',
    createdAt: moment.now,
    description: 'Carer just a created a report',
  },
];

const NotificationLists = () => {
  const [loading, setLoading] = useState(false);

  const list = data.map(info => (
    <Card
      key={uuid()}
      className="notify__card"
      title={info.title}
      hoverable
      extra={
        <span className="notify__card-head-extra">
          {moment(info.createdAt).format('DD MMM YYYY')}
        </span>
      }>
      <p>{info.description}</p>
    </Card>
  ));

  return (
    <>
      <Loader isLoading={loading}>{list}</Loader>
    </>
  );
};

export default NotificationLists;
