import React from 'react';
import { Drawer } from 'antd';

const NotificationDrawer = ({ onClose, visible, children }) => {
  return (
    <Drawer
      placement={'left'}
      closable={true}
      onClose={onClose}
      visible={visible}
      width={420}
      bodyStyle={{ padding: 20 }}
      // getContainer={'main'}
    >
      {children}
    </Drawer>
  );
};

export default NotificationDrawer;
