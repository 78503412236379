import React from 'react';
import { Card, Col, Row, Avatar, Radio, Table } from 'antd';
import NumberFormat from 'react-number-format';
import {
  ISWBankStatusChart,
  ISWTransactionStatusChart,
  FilterDropdown,
} from './components';

const columns = [
  {
    title: 'Bank Name',
    dataIndex: 'name',
    render: text => (
      <>
        {' '}
        <Avatar size={48} src={'https://placeimg.com/160/160/any'} />{' '}
        <span className="mx-1">{text}</span>{' '}
      </>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
  {
    title: 'Performance Trend',
    dataIndex: 'address',
  },
];
const data = [
  {
    key: '1',
    name: 'Guarantee Trust Bank',
    status: 'active',
    address: '71%',
  },
  {
    key: '2',
    name: 'Zenith Bank',
    status: 'active',
    address: '19%',
  },
  {
    key: '3',
    name: 'Access Bank',
    status: 'active',
    address: '20%',
  },
  {
    key: '4',
    name: 'First Bank of Nigeria',
    status: 'active',
    address: '99%',
  },
  {
    key: '5',
    name: 'Eco Bank',
    status: 'active',
    address: '60%',
  },
];

const InterswitchCards = () => {
  function onChange(e) {
    // console.log(`radio checked:${e.target.value}`);
  }

  function handleChange(value) {
    // console.log(`selected ${value}`);
  }
  return (
    <Row type="flex" justify="space-between" gutter={[16, 48, 32]} className="">
      <Col lg={{ span: 12 }} xl={{ span: 8 }} xxl={{ span: 8 }}>
        <Row gutter={[48, 32]}>
          <Col>
            <Card className="charts__card" bordered={false}>
              <div className="stats__card-top">
                <div className="charts__title-wrapper">
                  <div className="title-wrapper-1">
                    <h4 className="charts__card-h4">Transactions STATUS</h4>
                    <h3 className="charts__card-h3">25%</h3>
                  </div>
                  {/* <div className="title-wrapper-2">
                    <FilterDropdown></FilterDropdown>
                  </div> */}
                </div>
              </div>

              <Row type="flex" justify="space-around">
                <Col span={6} className="mt-2">
                  <div className="inst__card-extra successful">
                    <h4 className="inst__extra-h4">Successful</h4>
                    <h2 className="inst__extra-h2">
                      <NumberFormat
                        value={54689}
                        displayType={'text'}
                        thousandSeparator={true}
                      />
                    </h2>
                  </div>
                  <div className="inst__card-extra pending">
                    <h4 className="inst__extra-h4">Pending</h4>
                    <h2 className="inst__extra-h2">
                      <NumberFormat
                        value={7430}
                        displayType={'text'}
                        thousandSeparator={true}
                      />
                    </h2>
                  </div>
                  <div className="inst__card-extra failed">
                    <h4 className="inst__extra-h4">Failed</h4>
                    <h2 className="inst__extra-h2">
                      <NumberFormat
                        value={70500}
                        displayType={'text'}
                        thousandSeparator={true}
                      />
                    </h2>
                  </div>
                </Col>
                <Col span={18} className="mb-2">
                  <ISWTransactionStatusChart />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col>
            <Card className="charts__card" bordered={false}>
              <div className="stats__card-top">
                <div className="charts__title-wrapper">
                  <div className="title-wrapper-1">
                    <h4 className="charts__card-h4">Total Banks</h4>
                    <h3 className="charts__card-h3">450</h3>
                  </div>
                  <div className="title-wrapper-2">
                    <FilterDropdown></FilterDropdown>
                  </div>
                </div>
              </div>

              <Row type="flex" justify="space-around">
                <Col span={6} className="mt-2">
                  <div className="inst__card-extra m-pending">
                    <h4 className="inst__extra-h4">Successful</h4>
                    <h2 className="inst__extra-h2">
                      <NumberFormat
                        value={5489}
                        displayType={'text'}
                        thousandSeparator={true}
                      />
                    </h2>
                  </div>

                  <div className="inst__card-extra failed">
                    <h4 className="inst__extra-h4">Failed</h4>
                    <h2 className="inst__extra-h2">
                      <NumberFormat
                        value={700}
                        displayType={'text'}
                        thousandSeparator={true}
                      />
                    </h2>
                  </div>
                </Col>
                <Col span={18} className="mb-2">
                  <ISWBankStatusChart />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>
      <Col lg={{ span: 12 }} xl={{ span: 16 }} xxl={{ span: 16 }}>
        <Card>
          <Row type="flex" justify="space-between" className="mb-3 mt-1">
            <Col span={8}>
              <h1>Top Performers</h1>
            </Col>
            <Col span={16} className="line-chart__">
              <Row type="flex" justify="end">
                <Col className="line-chart__radio">
                  <Radio.Group onChange={onChange} defaultValue="today">
                    <Radio.Button value="today">Today</Radio.Button>
                    <Radio.Button value="week">Week</Radio.Button>
                    <Radio.Button value="month">Month</Radio.Button>
                    <Radio.Button value="year">Year</Radio.Button>
                  </Radio.Group>
                </Col>
              </Row>
            </Col>
          </Row>

          <Table columns={columns} dataSource={data} className="data__table" />
        </Card>
      </Col>
    </Row>
  );
};

export default InterswitchCards;
