import React from 'react';
import { Col, Row, Button, Card, Input, Form, Select, Alert } from 'antd';
import { navigate, Link } from '@reach/router';
import { useFormik } from 'formik';
import isEmpty from 'lodash.isempty';
import { useQuery, useMutation } from '@apollo/client';

import { appNotification, PageTitle } from '_components';
import { SS } from '_helpers';
import { GQL_ORDER } from '_helpers/util';
import { formItemLayout } from '_constants';
import { GET_MEMBERS } from 'pages/Members/query';
import { UPDATE_REGION } from './editRegion.query';
import validationSchema from './formValidationSchema';

const EditRegionForm = () => {
  const details = SS.get('region-details');
  const [updateRegion, { loading, error }] = useMutation(UPDATE_REGION, {
    onCompleted() {
      appNotification({
        desc: 'Region updated successfully',
        title: 'Success',
        type: 'success',
      });
    },
    onError() {
      appNotification({
        desc: 'Unable to update region. Try again later.',
        type: 'error',
      });
    },
  });
  const { error: membersError, data: membersData } = useQuery(GET_MEMBERS, {
    variables: { order: GQL_ORDER },
  });

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      ...details,
    },
    validationSchema,
    async onSubmit(values) {
      const data = {
        leader: values.leader,
        name: values.name,
      };

      try {
        const { data: response } = await updateRegion({
          variables: {
            id: details.id,
            data,
          },
        });
        if (response.updateRegion) {
          navigate('/regions');
          SS.remove('region-details');
        }
      } catch (error) {
        appNotification({
          desc: error.message || 'Unable to edit region. Contact admin',
          type: 'error',
        });
      }
    },
  });

  const handleTryAgain = () => {
    window.location.reload();
  };

  if (error || membersError) {
    return (
      <div>
        <h3>Unable to edit region. Try again.</h3>
        <Button size="large" onClick={handleTryAgain}>
          Try again
        </Button>
      </div>
    );
  }

  return (
    <>
      <PageTitle
        title=" Edit Region"
        extra={
          <Row type="flex" justify="end">
            <Link to="/regions">Back</Link>
          </Row>
        }
      />
      <div className="trans-details">
        <Row type="flex" className="" gutter={[24, 24]}>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}>
            <Row gutter={[24, 24]}>
              <Col span={24} className="">
                <Card
                  bordered={false}
                  style={{ borderRadius: 4 }}
                  title="Change region details">
                  <Form layout="horizontal">
                    <Form.Item
                      label="Name"
                      htmlFor="name"
                      colon={false}
                      {...formItemLayout}
                      validateStatus={
                        errors.name && touched.name ? 'error' : null
                      }
                      help={errors.name && touched.name ? errors.name : null}>
                      <Input
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Region Leader"
                      htmlFor="leader"
                      colon={false}
                      {...formItemLayout}
                      validateStatus={
                        errors.leader && touched.leader ? 'error' : null
                      }
                      help={
                        errors.leader && touched.leader ? errors.leader : null
                      }>
                      <Select
                        showSearch
                        placeholder={values.leader}
                        optionFilterProp="children"
                        onChange={text => setFieldValue('leader', text)}
                        defaultValue={values.leaderId}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                        {!isEmpty(membersData) ? (
                          membersData.members.map(member => (
                            <Select.Option key={member._id} value={member._id}>
                              {member?.firstName + ' ' + member.lastName}
                            </Select.Option>
                          ))
                        ) : (
                          <Select.Option value={null}>
                            No data found
                          </Select.Option>
                        )}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Branches"
                      colon={false}
                      {...formItemLayout}>
                      <Alert
                        message="Note"
                        description="Region branches cannot be edited at the moment. You need to edit individually by going to the branches page."
                        type="info"
                      />
                    </Form.Item>

                    <Row type="flex" justify="end">
                      <Button
                        key="submit"
                        type="primary"
                        size="large"
                        loading={loading}
                        disabled={!isEmpty(errors)}
                        onClick={handleSubmit}>
                        Update Region
                      </Button>
                    </Row>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EditRegionForm;
