import React, { useEffect, useState } from 'react';
import {
  Col,
  Divider,
  Card,
  List,
  Tooltip,
  Badge,
  Typography,
  Button,
} from 'antd';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { SS } from '../../../_helpers';

const updateReportsList = details => {
  return [
    {
      typeOfReport: 'Attendance',
      addReportUrl: '/attendance/new',
      viewReportUrl: '/attendance',
      data: details?.attendance,
    },
    {
      typeOfReport: 'Offering',
      addReportUrl: '/offering/new',
      viewReportUrl: '/offering',
      data: details?.finance,
    },
    {
      typeOfReport: 'Seeds',
      addReportUrl: '/seeds/new',
      viewReportUrl: '/seeds',
      data: details?.seed,
    },
    {
      typeOfReport: 'Tithe',
      addReportUrl: '/tithe/new',
      viewReportUrl: '/tithe',
      data: details?.tithe,
    },
    {
      typeOfReport: 'EGF',
      addReportUrl: '/egf/new',
      viewReportUrl: '/egf',
      data: details?.egf,
    },
    // {
    //     typeOfReport: 'Expenses',
    //     addReportUrl: '/expenses/new',
    //     viewReportUrl: '/expenses',
    //     data: details?.expense,
    // },
    // {
    //   typeOfReport: 'First Timer',
    //   addReportUrl: '/first-timer/new',
    //   viewReportUrl: '/first-timers',
    //   status: 'Unsubmitted',
    // },
  ];
};

const ServiceCards = ({ data }) => {
  const [details, setDetails] = useState(data);

  const [reportsList, updateList] = useState([]);

  useEffect(() => {
    const d = updateReportsList(details);
    updateList(d);
  }, [details]);

  useEffect(() => {
    setDetails(data);
    return () => {};
  }, [data]);
  return (
    <Col
      xs={{ span: 24 }}
      sm={{ span: 18 }}
      lg={{ span: 18 }}
      xl={{ span: 18 }}
      xxl={{ span: 12 }}>
      <Card style={{ borderRadius: '4px' }} bordered={false}>
        {/* if we want date we add date too */}

        <Typography.Title level={4} type="secondary" className="text-gray">
          Other reports
        </Typography.Title>

        <Divider />

        <List
          className="demo-loadmore-list"
          itemLayout="horizontal"
          split={false}
          size="small"
          dataSource={reportsList}
          renderItem={item => (
            <List.Item
              actions={[
                <span>
                  <Link
                    to={item?.addReportUrl}
                    // state={
                    //   item && details
                    //     ? {
                    //         ...item?.data[0],
                    //         ...{ name: details?.name, id: details?.id },
                    //       }
                    //     : {}
                    // }
                  >
                    <Tooltip
                      title={
                        !isEmpty(item?.data) &&
                        ['Offering', 'Seeds', 'Tithe', 'EGF'].includes(
                          item?.typeOfReport,
                        ) ? (
                          <span>
                            You cannot modify existing financial record
                          </span>
                        ) : (
                          <span>Modify record</span>
                        )
                      }>
                      <Button
                        className="card-view__button"
                        disabled={
                          !isEmpty(item?.data) &&
                          ['Offering', 'Seeds', 'Tithe', 'EGF'].includes(
                            item?.typeOfReport,
                          )
                        }>
                        {!isEmpty(item?.data) ? 'modify' : 'add'}
                      </Button>
                    </Tooltip>
                  </Link>
                </span>,
                <span>
                  <Link
                    to={
                      isEmpty(item?.data)
                        ? '#'
                        : `${item?.viewReportUrl}/details/${item?.data[0]?._id}`
                    }>
                    <Button
                      className="card-view__button"
                      onClick={() =>
                        SS.save(`${item.viewReportUrl.substring(1)}-details`, {
                          ...item?.data[0],
                          ...{
                            name: details?.name,
                            branch: details?.branch,
                            recordedBy: details?.recordedBy,
                            serviceType: details?.serviceType,
                            service: details?.service,
                          },
                        })
                      }
                      //seeds, tithe,egf now comes as an array
                      disabled={
                        isEmpty(item?.data) ||
                        ['Seeds', 'Tithe', 'EGF'].includes(item?.typeOfReport)
                      }>
                      view
                    </Button>
                  </Link>
                </span>,
                // <Link to={`${item.viewReportUrl}`}>view</Link>,
              ]}>
              <List.Item.Meta
                description={
                  <>
                    <span>{item?.typeOfReport}</span> &nbsp;
                    {/*  // you will have to check individually for the status of the report */}
                    {!isEmpty(item?.data) ? (
                      <Tooltip title="Submitted" placement="right">
                        <Badge status="success"></Badge>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Unsubmitted" placement="right">
                        <Badge status="error"></Badge>
                      </Tooltip>
                    )}
                  </>
                }
              />
            </List.Item>
          )}
        />
      </Card>
    </Col>
  );
};

ServiceCards.propTypes = {
  data: PropTypes.object.isRequired,
};
//
// ServiceCards.defaultProps = {
//   data: {
//     _id: 0,
//     service: 'Unkown Service',
//     serviceType: 'Unknown Service Category',
//     totalAttendance: 0,
//     totalSeed: 0,
//     totalExpense: 0,
//     totalFinance: 0
//   }
// };

export default ServiceCards;
