import * as Yup from 'yup';

const validationSchema = Yup.object({
  /* COMMENTED OUT BECAUSE WE DON'T NEED THE SERVICE CATEGORY */
  // serviceType: Yup.string()
  //   .matches(/^[a-zA-Z0-9\s]+$/, {
  //     message: 'Please enter valid characters',
  //     excludeEmptyString: true,
  //   })
  //   .min(2),

  serviceReport: Yup.string()
    .min(2)
    .required('Service name is required'),

  // tithe: Yup.number()
  //   .typeError('Must be in digits')
  //   .min(0),

  offering: Yup.number()
    .typeError('Must be in digits')
    .min(0),

  // egf: Yup.number()
  //   .typeError('Must be in digits')
  //   .min(0),

  branch: Yup.string().min(2),
  recordedBy: Yup.string().min(2),
});

export default validationSchema;
