import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Tag } from 'antd';
import NumberFormat from 'react-number-format';
import {
  TransactionsRateChart,
  TransactionStatusChart,
  PerformanceTrendChart,
} from './components';
import { RateBreakdownChart, Loader } from '_components';

const BankTransactionsCards = () => {
  const [isLoading, setIsLoading] = useState(true);

  const rateBreakdownData = {
    successData: [55, 20, 25],
    failureData: [10, 55, 35],
  };
  const transactionPerformance = {
    week: {
      value: 23434,
      percent: 34,
    },
    month: {
      value: 234242,
      percent: 34.34,
    },
    year: {
      value: 9899797,
      percent: 54.04,
    },
  };

  const successData = {
    labels: ['Mastercard', 'Visa', 'Verve'],
    datasets: [
      {
        data: [...rateBreakdownData.successData],
        backgroundColor: ['#FF5660', '#19C165', '#FFC400'],
        hoverBackgroundColor: ['#FF5660', '#19C165', '#FFC400'],
      },
    ],
  };
  const failureData = {
    labels: ['Mastercard', 'Visa', 'Verve'],
    datasets: [
      {
        data: [...rateBreakdownData.failureData],
        backgroundColor: ['#FF5660', '#19C165', '#FFC400'],
        hoverBackgroundColor: ['#FF5660', '#19C165', '#FFC400'],
      },
    ],
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    return () => {
      // cancel subscriptions
    };
  }, []);
  return (
    <>
      <Row
        type="flex"
        justify="space-between"
        gutter={[16, 48, 32]}
        className="">
        <Col lg={{ span: 12 }} xl={{ span: 8 }} xxl={{ span: 8 }}>
          <Card className="charts__card" bordered={false}>
            <div className="stats__card-top mb-2">
              <h4 className="charts__card-h4">Transaction Rate</h4>
            </div>
            <div className="mb-2">
              <TransactionsRateChart />
            </div>
            <Row type="flex" justify="space-around">
              <Col>
                <div className="trans-rate successful">
                  <h2 className="trans-rate">
                    <span className="trans-em">{75}%</span> &nbsp; Successful
                  </h2>
                </div>
              </Col>
              <Col>
                <div className="trans-rate failed">
                  <h2 className="trans-rate">
                    <span className="trans-em">{25}%</span> &nbsp; Failed
                  </h2>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col lg={{ span: 12 }} xl={{ span: 8 }} xxl={{ span: 8 }}>
          <Loader isLoading={isLoading}>
            <Card className="charts__card" bordered={false}>
              <div className="stats__card-top mb-2">
                <h4 className="charts__card-h4">Success Rate Breakdown</h4>
              </div>
              <div className="mb-2">
                <RateBreakdownChart
                  type="Success"
                  data={successData}
                  rate={75}
                />
              </div>
              <Row type="flex" justify="space-around" className="mt-3">
                <Col>
                  <div className="inst__card-extra failed">
                    <h4 className="inst__extra-h4">Mastercard</h4>
                    <h2 className="inst__extra-h2">55%</h2>
                  </div>
                </Col>

                <Col>
                  <div className="inst__card-extra  successful">
                    <h4 className="inst__extra-h4">Visa</h4>
                    <h2 className="inst__extra-h2">20%</h2>
                  </div>
                </Col>
                <Col>
                  <div className="inst__card-extra pending">
                    <h4 className="inst__extra-h4">Verve</h4>
                    <h2 className="inst__extra-h2">25%</h2>
                  </div>
                </Col>
              </Row>
            </Card>
          </Loader>
        </Col>
        <Col lg={{ span: 12 }} xl={{ span: 8 }} xxl={{ span: 8 }}>
          <Loader isLoading={isLoading}>
            <Card className="charts__card" bordered={false}>
              <div className="stats__card-top mb-2">
                <h4 className="charts__card-h4">Failure Rate Breakdown</h4>
              </div>
              <div className="mb-2">
                <RateBreakdownChart
                  type="Failure"
                  data={failureData}
                  rate={25}
                />
              </div>
              <Row type="flex" justify="space-around" className="mt-3">
                <Col>
                  <div className="inst__card-extra failed">
                    <h4 className="inst__extra-h4">Mastercard</h4>
                    <h2 className="inst__extra-h2">10%</h2>
                  </div>
                </Col>
                <Col>
                  <div className="inst__card-extra successful">
                    <h4 className="inst__extra-h4">Visa</h4>
                    <h2 className="inst__extra-h2">55%</h2>
                  </div>
                </Col>
                <Col>
                  <div className="inst__card-extra pending">
                    <h4 className="inst__extra-h4">Verve</h4>
                    <h2 className="inst__extra-h2">35%</h2>
                  </div>
                </Col>
              </Row>
            </Card>
          </Loader>
        </Col>
      </Row>
      <Row type="flex" gutter={[16, 48, 32]} className="mt-2">
        <Col lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
          <Card className="charts__card" bordered={false}>
            <div className="stats__card-top">
              <h4 className="charts__card-h4">Transaction Performance</h4>
            </div>
            {/* <div className="charts-transaction-stats">
              <h3>₦183,927,000</h3>
            </div> */}
            <Loader isLoading={isLoading}>
              <div className="charts__row">
                <div className="">
                  <h4 className="last-month">This Week</h4>
                  <h3 className="">
                    <NumberFormat
                      value={transactionPerformance.week.value}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'₦'}
                      className="last-month__value"
                    />
                  </h3>
                  <Tag color="#19C165" className="index-tag">
                    {transactionPerformance.week.percent}%
                  </Tag>
                </div>
                <span className="last-month__divider"></span>
                <div className="">
                  <h4 className="last-month">This Month</h4>
                  <h3 className="">
                    <NumberFormat
                      value={transactionPerformance.month.value}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'₦'}
                      className="last-month__value"
                    />
                  </h3>
                  <Tag color="#19C165" className="index-tag">
                    {transactionPerformance.month.percent}%
                  </Tag>
                </div>
                <span className="last-month__divider"></span>
                <div className="">
                  <h4 className="last-month">This Year</h4>
                  <h3 className="">
                    <NumberFormat
                      value={transactionPerformance.year.value}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'₦'}
                      className="last-month__value"
                    />
                  </h3>
                  <Tag color="#19C165" className="index-tag">
                    {transactionPerformance.year.percent}%
                  </Tag>
                </div>
              </div>
            </Loader>
          </Card>
        </Col>

        <Col lg={{ span: 12 }} xl={{ span: 12 }} xxl={{ span: 12 }}>
          <Card className="charts__card" bordered={false}>
            <h1>Performance Trend</h1>
            <Loader isLoading={isLoading}>
              <PerformanceTrendChart />
            </Loader>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default BankTransactionsCards;
