import { Rules } from '_helpers';

/**
 * This function is used to check permissions
 * The logic here was heavily lifted from the blog
 * Reference: https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/
 * @param {object} rules
 * @param {string} role
 * @param {array} action
 * @param {*} data
 */

const check = (rules, role, action, data) => {
  /**
   * IMPORTANT
   * Please note "role.userType" is because the userType is
   * mocked in the local storage
   */
  const permissions = rules[role.userType];

  if (!permissions) return false;

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) return true;

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) return false;

    return permissionCondition(data);
  }

  return false;
};

const CanAccess = props => {
  return check(Rules, props.role, props.perform, props.data)
    ? props.yes()
    : props.no();
};

CanAccess.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default CanAccess;
