import React from 'react';
import 'antd/dist/antd.css';
import { Location } from '@reach/router';
import routes from '_routes';

// internals
import { AppLayout } from 'pages';
import './App.css';
import './MediaQuery.css';

function App() {
  return (
    <Location>
      {({ location }) => <AppLayout location={location}>{routes}</AppLayout>}
    </Location>
  );
}

export default App;
