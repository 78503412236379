import gql from 'graphql-tag';

export const ADD_SERVICE_REPORT = gql`
  mutation addServiceReport($data: ServiceReportInput!) {
    createServiceReport(input: $data) {
      _id
      name
      pastoralRemarks
    }
  }
`;
export const GET_SERVICE_REPORT = gql`
  query getServiceReport($order: ServiceReportSortInput) {
    serviceReports(order: $order) {
      _id
      name
      noOfFirstTimers
      totalAttendance
      totalSeed
      totalExpense
      totalFinance
      sumTotalIncome
      actualServiceDate
      pastoralRemarks
      branch {
        _id
        name
      }
      recordedBy {
        _id
        firstName
        lastName
      }
      serviceType {
        _id
        name
      }
      service {
        _id
        name
      }
    }
  }
`;

export const GET_SINGLE_SERVICE_REPORT = gql`
  query singleService($id: String!) {
    singleService(id: $id) {
      _id
      name
      serviceType {
        _id
        name
      }
    }
  }
`;

export const GET_MY_PROFILE_DETAILS = gql`
  query {
    myProfile {
      _id
      firstName
      lastName
      member {
        firstName
        lastName
        branch {
          name
        }
      }
    }
  }
`;

export const TEST = gql`
  mutation updateUser($id: String!, $data: UserUpdateInput!) {
    updateUser(id: $id, input: $data) {
      _id
      auth0UserId
      lastName
      firstName
      email
      member {
        _id
        branch {
          _id
          name
        }
      }
      roles {
        _id
        name
      }
    }
  }
`;
