import React, { useEffect, useState } from 'react';
import { Table, Button } from 'antd';
import { useQuery } from '@apollo/client';
import { uuid } from 'uuidv4';
import { Link } from '@reach/router';
import moment from 'moment';
import filter from 'lodash/filter';
import isEmpty from 'lodash.isempty';
import { SS } from '_helpers';
import { appNotification as notification } from '_components';
import { GET_FIRSTTIMERS } from '../query';

const columns = [
  {
    title: 'First Name',
    dataIndex: 'firstName',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
  },
  {
    title: 'Email Address',
    dataIndex: 'email',
  },
  {
    title: 'Phone Number',
    dataIndex: 'phoneNumber',
  },
  {
    title: 'Salvation Status',
    dataIndex: 'saved',
  },
  {
    title: 'Gender',
    dataIndex: 'gender',
  },
  {
    title: 'Age Range',
    dataIndex: 'ageRange',
  },
  {
    title: 'Decision to join church',
    dataIndex: 'joiningChurch',
    render: (text, record) => {
      return <>{record.joiningChurch ? 'Yes' : 'Not yet'}</>;
    },
  },
  {
    title: ' Branch',
    dataIndex: 'branch',
    render: (text, record) => {
      return <>{record.branch}</>;
    },
  },
  {
    title: 'Action',
    key: '',
    dataIndex: '',
    render: (text, record) => {
      return (
        <Link to={`details/${record.id}`}>
          <Button
            key={record.id}
            onClick={() => SS.save('firsttimers-details', record)}
            className="table-view__button">
            View Details
          </Button>
        </Link>
      );
    },
  },
];

const update = data =>
  data &&
  data.map(firsttimer => ({
    key: uuid(),
    id: firsttimer._id,
    firstName: firsttimer.firstName,
    middleName: firsttimer.middleName,
    lastName: firsttimer.lastName,
    gender: firsttimer.gender,
    email: firsttimer.email,
    phoneNumber: firsttimer.phoneNumber,
    occupation: firsttimer.occupation,
    saved: firsttimer.saved ? 'Saved' : 'Unsaved',
    joiningChurch: firsttimer.joiningChurch,
    dateOfSalvation: isEmpty(firsttimer.dateOfSalvation)
      ? '-'
      : moment(firsttimer.dateOfSalvation).format('MMM DD YYYY, h:mm:ss a'),
    ageRange: firsttimer.ageRange,
    prayerPoints: firsttimer.prayerPoints,
    invitedBy: firsttimer.invitedBy,
    address: firsttimer.address,
    city: firsttimer.city,
    state: firsttimer.state,
    country: firsttimer.country,
    branch: isEmpty(firsttimer.branch) ? '-' : firsttimer.branch.name,
  }));

const DataTable = ({ searchQuery }) => {
  const [firstTimersData, updateList] = useState([]);

  const [page, setPage] = useState(1);
  const order = { field: 'lastName', direction: 'Asc' };

  const { loading, error, data } = useQuery(GET_FIRSTTIMERS, {
    variables: { order },
  });
  // console.log(data);
  // CONTROL PAGINATION DATA
  const pagination = {
    total: firstTimersData.length,
    pageSize: 10,
    current: page,
    onChange: page => {
      setPage(page);
    },
  };
  useEffect(() => {
    let results = [];
    if (searchQuery.length > 3) {
      results = filter(firstTimersData, firstTimer => {
        const name = Object.values(firstTimer)
          .slice(1, 4)
          .join(' ')
          .toLowerCase();

        return (
          name.includes(searchQuery.toLowerCase()) ||
          name.indexOf(searchQuery.toLowerCase()) !== -1
        );
      });
      updateList(results);
    }
    return () => {
      // cancel subscriptions
      if (data && data.firstTimers) {
        updateList(update(data.firstTimers));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    if (data && data.firstTimers) {
      updateList(update(data.firstTimers));
    }
  }, [data, loading]);

  useEffect(() => {
    return () => {
      // cancel subscriptions
    };
  }, [page]);
  return (
    <div style={{ marginTop: 8 }}>
      <Table
        className="data__table"
        columns={columns}
        dataSource={firstTimersData}
        pagination={pagination}
        loading={loading}
      />
    </div>
  );
};

export default DataTable;
