import gql from 'graphql-tag';

export const GET_SERVICE_TYPES = gql`
  query getServiceTypes($order: ServiceTypeSortInput) {
    serviceTypes(order: $order) {
      _id
      name
      url
    }
  }
`;

export const GET_SERVICE_REPORTS = gql`
  query getServiceReports($order: ServiceReportSortInput) {
    serviceReports(order: $order) {
      _id
      name
      noOfFirstTimers
      totalAttendance
      totalSeed
      totalEgf
      totalTithe
      totalExpense
      totalFinance
      sumTotalIncome
      actualServiceDate
      pastoralRemarks
      branch {
        _id
        name
      }
      recordedBy {
        _id
        firstName
        lastName
      }
      serviceType {
        _id
        name
      }
      service {
        _id
        name
      }
      attendance {
        _id
        pastor
        men
        women
        brothers
        sisters
        teenagers
        children
      }
      finance {
        _id
      }
      seeds {
        _id
      }
      expenses {
        _id
      }
      seeds {
        seedName
        seedAmount
        seedRemarks
        member {
          firstName
          middleName
          lastName
        }
      }
      egfs {
        _id
        egfName
        egfAmount
        egfRemarks
        member {
          firstName
          middleName
          lastName
        }
      }
      tithes {
        _id
        titheAmount
        titheRemarks
        member {
          _id
          firstName
          middleName
          lastName
        }
      }
    }
  }
`;

export const GET_SINGLE_SERVICE_REPORT = gql`
  query getSingleServiceReport($id: String!) {
    singleServiceReport(id: $id) {
      _id
      name
      noOfFirstTimers
      totalAttendance
      totalSeed
      totalEgf
      totalTithe
      totalExpense
      totalFinance
      sumTotalIncome
      actualServiceDate
      pastoralRemarks
      branch {
        _id
        name
      }
      recordedBy {
        _id
        firstName
        lastName
      }
      serviceType {
        _id
        name
      }
      service {
        _id
        name
      }
      attendance {
        _id
        pastor
        men
        women
        brothers
        sisters
        teenagers
        children
      }
      finance {
        _id
        tithe
        offering
        egf
      }
      seeds {
        _id
        seedName
        seedAmount
        seedRemarks
        member {
          firstName
          middleName
          lastName
        }
      }
      egfs {
        _id
        egfName
        egfAmount
        egfRemarks
        member {
          firstName
          middleName
          lastName
        }
      }
      tithes {
        _id
        titheAmount
        titheRemarks
        member {
          _id
          firstName
          middleName
          lastName
        }
      }
      expenses {
        _id
      }
    }
  }
`;

// _id: ID!
// language: String
// name: String!
// : Float
// : Float
// : Float
// : Float
// : Float
// : Float
// : String
// : DateTime!
// : ServiceTypeType
// service: ServiceType
// : BranchType
// : UserType
// seeds: [SeedReportType!]
// expenses: [ExpenseReportType!]
// attendance: AttendanceReportType
// finance: FinanceReportType
