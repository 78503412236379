import * as Yup from 'yup';

const validationSchema = Yup.object({
  name: Yup.string()
    .matches(/^[a-zA-Z0-9\s]+$/, {
      message: 'Please enter valid characters',
      excludeEmptyString: true,
    })
    .min(2)
    .required('Permission name is required'),

  permissions: Yup.array().of(Yup.string()),
});

export default validationSchema;
