import React from 'react';
import { Col, Row, Button, Card, Input, Form, Select } from 'antd';
import { navigate, Link } from '@reach/router';
import { useFormik } from 'formik';
import isEmpty from 'lodash.isempty';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { useQuery, useMutation } from '@apollo/client';

import { appNotification, PageTitle } from '_components';
import { SS } from '_helpers';
import { formItemLayout } from '_constants';
import validationSchema from './formValidationSchema';
import { GET_MEMBERS } from 'pages/Members/query';
import { GET_REGION } from 'pages/Regions/query';
import { UPDATE_BRANCH } from './editBranch.query';
import { GQL_ORDER } from '_helpers/util';

const EditBranchForm = () => {
  const details = SS.get('branch-details');
  const [updateBranch, { loading, error }] = useMutation(UPDATE_BRANCH);
  const { error: membersError, data: membersData } = useQuery(GET_MEMBERS, {
    variables: { order: GQL_ORDER },
  });
  const { error: regionError, data: regionData } = useQuery(GET_REGION, {
    variables: { order: GQL_ORDER },
  });

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: { ...details },
    validationSchema,
    async onSubmit(values) {
      delete values.id;
      delete values.key;
      try {
        const { data: response } = await updateBranch({
          variables: {
            id: details.id,
            input: {
              ...values,
            },
          },
        });
        if (response?.updateBranch) {
          appNotification({
            desc: 'Branch updated successfully',
            title: 'Success',
            type: 'success',
          });
          SS.remove('branch-details');
          navigate('/branches');
        }
        resetForm();
      } catch (error) {
        appNotification({
          desc: error.message || 'Unable to edit branch. Contact admin',
          type: 'error',
        });
      }
    },
  });

  const handleTryAgain = () => {
    window.location.reload();
  };

  if (error || regionError || membersError) {
    return (
      <div>
        <h3>Unable to edit branch. Try again.</h3>
        <Button size="large" onClick={handleTryAgain}>
          Try again
        </Button>
      </div>
    );
  }

  return (
    <>
      <PageTitle
        title=" Edit Branch"
        extra={
          <Row type="flex" justify="end">
            <Link to="/branches">Back</Link>
          </Row>
        }
      />
      <div className="trans-details">
        <Row type="flex" className="" gutter={[24, 24]}>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}>
            <Row gutter={[24, 24]}>
              <Col span={24} className="">
                <Card
                  bordered={false}
                  style={{ borderRadius: 4 }}
                  title="Edit Branch Details">
                  <Form layout="horizontal">
                    <Form.Item
                      label="Name"
                      htmlFor="name"
                      colon={false}
                      required
                      {...formItemLayout}
                      validateStatus={
                        errors.name && touched.name ? 'error' : null
                      }
                      help={errors.name && touched.name ? errors.name : null}>
                      <Input
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Branch Leader"
                      htmlFor="leader"
                      colon={false}
                      required
                      {...formItemLayout}
                      validateStatus={
                        errors.leader && touched.leader ? 'error' : null
                      }
                      help={
                        errors.leader && touched.leader
                          ? errors.leader
                          : 'Note: Leader must be selected'
                      }>
                      <Select
                        showSearch
                        placeholder={values.leader}
                        optionFilterProp="children"
                        onChange={text => setFieldValue('leader', text)}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                        {!isEmpty(membersData) ? (
                          membersData.members.map(member => (
                            <Select.Option key={member._id} value={member._id}>
                              {member?.firstName + ' ' + member.lastName}
                            </Select.Option>
                          ))
                        ) : (
                          <Select.Option value={null}>
                            No data found
                          </Select.Option>
                        )}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Region"
                      htmlFor="region"
                      colon={false}
                      {...formItemLayout}
                      required
                      validateStatus={
                        errors.region && touched.region ? 'error' : null
                      }
                      help={
                        errors.region && touched.region
                          ? errors.region
                          : 'Note: Region must be selected'
                      }>
                      <Select
                        showSearch
                        placeholder={values.region}
                        optionFilterProp="children"
                        onChange={text => setFieldValue('region', text)}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                        {!isEmpty(regionData) ? (
                          regionData.regions.map(region => (
                            <Select.Option key={region._id} value={region._id}>
                              {region.name}
                            </Select.Option>
                          ))
                        ) : (
                          <Select.Option value={null}>
                            No data found
                          </Select.Option>
                        )}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      label="Address"
                      htmlFor="address"
                      colon={false}
                      {...formItemLayout}
                      validateStatus={
                        errors.address && touched.address ? 'error' : null
                      }
                      help={
                        errors.address && touched.address
                          ? errors.address
                          : null
                      }>
                      <Input
                        name="address"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.address}
                      />
                    </Form.Item>
                    <Form.Item
                      label="City"
                      htmlFor="city"
                      colon={false}
                      {...formItemLayout}
                      validateStatus={
                        errors.city && touched.city ? 'error' : null
                      }
                      help={errors.city && touched.city ? errors.city : null}>
                      <Input
                        name="city"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.city}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Country"
                      htmlFor="country"
                      colon={false}
                      {...formItemLayout}
                      validateStatus={
                        errors.country && touched.country ? 'error' : null
                      }
                      help={
                        errors.country && touched.country
                          ? errors.country
                          : null
                      }>
                      <CountryDropdown
                        className="ant-input"
                        value={values.country}
                        onChange={e => setFieldValue('country', e)}
                        priorityOptions={['NG', 'US', 'GB', 'CA']}
                      />
                    </Form.Item>
                    <Form.Item
                      label="State"
                      htmlFor="state"
                      colon={false}
                      {...formItemLayout}
                      validateStatus={
                        errors.state && touched.state ? 'error' : null
                      }
                      help={
                        errors.state && touched.state ? errors.state : null
                      }>
                      <RegionDropdown
                        className="ant-input"
                        country={values.country}
                        value={values.state}
                        onChange={e => setFieldValue('state', e)}
                      />
                    </Form.Item>

                    <Row type="flex" justify="end">
                      <Button
                        key="submit"
                        type="primary"
                        size="large"
                        loading={loading}
                        disabled={!isEmpty(errors)}
                        onClick={handleSubmit}>
                        Update Branch
                      </Button>
                    </Row>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EditBranchForm;
