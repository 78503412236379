import React, { useState, useEffect } from 'react';
import { Col, Row, Card, Icon } from 'antd';
import { navigate } from '@reach/router';
import isEmpty from 'lodash.isempty';
// internals
import { PageTitle } from '_components';
import { SS } from '_helpers';

import { ServiceCards } from './components';
import { useLazyQuery } from '@apollo/client';
import { GET_SINGLE_SERVICE_REPORT } from '../ServiceReports/graphql';
import { update } from '../ServiceReportsList/components/DataTable';

const ServiceReportDetails = props => {
  // values should come from API
  const [details, setDetails] = useState(SS.get('service-report-details'));

  const [
    getReport,
    { loading: queryLoading, error: queryError, data: queryData, refetch },
  ] = useLazyQuery(GET_SINGLE_SERVICE_REPORT);

  useEffect(() => {
    if (details?.id) {
      getReport({ variables: { id: details.id } });
    }
    // setDetails({...updateForm, ...details})
    return () => {
      // cancel subscriptions
    };
  }, [details.id, getReport]);
  //
  useEffect(() => {
    if (!queryLoading && queryData?.singleServiceReport) {
      setDetails(update([queryData.singleServiceReport])[0]);
    }

    return () => {};
  }, [queryData, queryLoading]);
  //
  return (
    <>
      <PageTitle
        title="Service Report Details"
        extra={
          <Row type="flex" justify="end">
            <a
              onClick={() => {
                navigate(-1);
              }}>
              <Icon className="mx-1" type="left-circle" /> Back{' '}
            </a>
          </Row>
        }
      />
      <div className="trans-details">
        <Row type="flex" className="" gutter={[24, 24]}>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}>
            <Row gutter={[24, 24]}>
              {/* First Timers Details column */}
              <Col span={24} className="">
                <Card bordered={false} style={{ borderRadius: 4 }}>
                  <Row
                    className="markdown__row"
                    type="flex"
                    justify="space-between">
                    <Col span={18}>
                      <h3 className="mx-1">{`${details.name}'s Details`}</h3>
                    </Col>
                  </Row>
                  <div className="details_divider"></div>
                  <div className="markdown ">
                    <table className="">
                      <tbody>
                        <tr>
                          <td>Report Name</td>
                          <td>{details.name}</td>
                        </tr>
                        <tr>
                          <td>Attendance</td>
                          <td>{details.totalAttendance}</td>
                        </tr>

                        <tr>
                          <td>No. Of FirstTimers</td>
                          <td>{details.noOfFirstTimers}</td>
                        </tr>
                        <tr>
                          <td>Service Type</td>
                          <td>{details.serviceType}</td>
                        </tr>
                        <tr>
                          <td>Branch</td>
                          <td>{details.branch}</td>
                        </tr>

                        <tr>
                          <td>Recorded By</td>
                          <td>{details.recordedBy}</td>
                        </tr>
                      </tbody>
                    </table>
                    <Row
                      className="markdown__row"
                      type="flex"
                      justify="space-between">
                      <Col span={18}>
                        <span
                          style={{
                            marginRight: '1em',
                            marginLeft: '1em',
                          }}>
                          Collections
                        </span>
                      </Col>
                    </Row>
                    <div className="details_divider"></div>
                    <div className="markdown ">
                      <table className="">
                        <tr>
                          <td>Total Offering</td>
                          <td>{details.totalFinance}</td>
                        </tr>
                        <tr>
                          <td>Total Seed</td>
                          <td>{details.totalSeed}</td>
                        </tr>
                        <tr>
                          <td>Total Tithe</td>
                          <td>{details.totalTithe}</td>
                        </tr>
                        <tr>
                          <td>Total EGF</td>
                          <td>{details.totalEgf}</td>
                        </tr>
                        <tr>
                          <td>Total Income</td>
                          <td>{details.sumTotalIncome}</td>
                        </tr>
                        <tr>
                          <td>Total Expense</td>
                          <td>{details.totalExpense}</td>
                        </tr>
                      </table>
                    </div>
                    <Row
                      className="markdown__row"
                      type="flex"
                      justify="space-between">
                      <Col span={18}>
                        <span
                          style={{
                            marginRight: '1em',
                            marginLeft: '1em',
                          }}>
                          Pastor's Remark
                        </span>
                      </Col>
                    </Row>
                    <div className="details_divider"></div>
                    <div className="markdown ">
                      <p className="px-1 py-1">{details.pastoralRemarks}</p>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}>
            <Row type="flex" gutter={[24, 24]}>
              {/* Payment Method column */}
              <ServiceCards data={details} />
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ServiceReportDetails;
