/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Table, Button } from 'antd';
import { uuid } from 'uuidv4';
import { Link } from '@reach/router';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import filter from 'lodash/filter';

import { SS } from '_helpers';

import { GET_SERVICE_REPORTS } from '../../ServiceReports/graphql';

const columns = [
  {
    title: 'Service Date',
    dataIndex: 'actualServiceDate',
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Attendance',
    dataIndex: 'totalAttendance',
  },
  {
    title: 'Seeds',
    dataIndex: 'totalSeed',
  },
  {
    title: 'EGF',
    dataIndex: 'totalEgf',
  },
  {
    title: 'Tithe',
    dataIndex: 'totalTithe',
  },
  {
    title: 'Offering',
    dataIndex: 'totalFinance',
  },
  {
    title: 'Expenses',
    dataIndex: 'totalExpense',
  },
  {
    title: 'RecordedBy',
    dataIndex: 'recordedBy',
  },
  {
    title: 'Action',
    key: '',
    dataIndex: '',
    render: (text, record) => {
      return (
        <Link to={`/service-report/details/${record.id}`}>
          <Button
            key={uuid()}
            onClick={() => SS.save('service-report-details', record)}
            className="table-view__button">
            View Details
          </Button>
        </Link>
      );
    },
  },
];

export const update = data =>
  data &&
  data.map(report => ({
    key: uuid(),
    id: report._id,
    name: report.name,
    noOfFirstTimers: report.noOfFirstTimers,
    totalAttendance: report.totalAttendance,
    totalSeed: report.totalSeed,
    totalEgf: report.totalEgf,
    totalTithe: report.totalTithe,
    totalExpense: report.totalExpense,
    totalFinance: report.totalFinance,
    sumTotalIncome: report.sumTotalIncome,
    actualServiceDate:
      report.actualServiceDate &&
      moment(report.actualServiceDate).format('YYYY-MM-DD HH:MM:SS'),
    pastoralRemarks: report.pastoralRemarks,
    branch: report.branch.name,
    recordedBy:
      report.recordedBy &&
      Object.values(report.recordedBy)
        .slice(1, -1)
        .join(' '),
    serviceType: report.serviceType.name,
    service: report.service.name,
    attendance: report.attendance,
    finance: report.finance,
    seed: report.seeds,
    egf: report.egfs,
    tithe: report.tithes,
    expense: report.expenses,
  }));

const DataTable = ({ searchQuery }) => {
  const [page, setPage] = useState(1);
  const order = { field: 'actualServiceDate', direction: 'DESC' };

  const [serviceReports, updateList] = useState([]);

  const { loading, error: queryError, data } = useQuery(GET_SERVICE_REPORTS, {
    variables: { order },
  });
  // console.log('report data', data);

  // CONTROL PAGINATION DATA
  const pagination = {
    total: serviceReports.length,
    pageSize: 10,
    current: page,
    onChange: page => {
      setPage(page);
    },
  };

  useEffect(() => {
    let results = [];
    if (searchQuery.length > 3) {
      results = filter(
        serviceReports,
        report =>
          report.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          report.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1,
      );
      updateList(results);
    }
    return () => {
      // cancel subscriptions
      if (data && data.serviceReports) {
        updateList(update(data.serviceReports));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    if (data && data.serviceReports) {
      updateList(update(data.serviceReports));
    }
  }, [data, loading]);

  useEffect(() => {
    return () => {
      // cancel subscriptions
    };
  }, [page]);

  return (
    <div style={{ marginTop: 8 }}>
      <Table
        className="data__table"
        columns={columns}
        dataSource={serviceReports.sort(
          (a, b) =>
            new Date(b.actualServiceDate).getTime() -
            new Date(a.actualServiceDate).getTime(),
        )}
        pagination={pagination}
        loading={loading}
      />
    </div>
  );
};

export default DataTable;
