import React, { Component } from 'react';
import { DatePicker } from 'antd';
import PropTypes from 'prop-types';
// import moment from 'moment';
import './DateRange.css';

class DateRange extends Component {
  state = {
    startValue: null,
    endValue: null,
    endOpen: false,
    // startValue: moment().startOf('month'),
    // endValue: moment()
  };

  disabledStartDate = startValue => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() >= endValue.valueOf();
  };

  disabledEndDate = endValue => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  onStartChange = value => {
    this.onChange('startValue', value);
    if (value === null) {
      return this.props.getStartDate('');
    }
    this.props.getStartDate(value.startOf('day').format());
  };

  onEndChange = value => {
    this.onChange('endValue', value);
    if (value === null) {
      return this.props.getEndDate('');
    }
    this.props.getEndDate(value.endOf('day').format());
  };

  handleStartOpenChange = open => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = open => {
    this.setState({ endOpen: open });
  };

  render() {
    const { startValue, endValue, endOpen } = this.state;
    const { displayLabel } = this.props;
    return (
      <div className="date-range__wrapper">
        <div>
          {displayLabel && (
            <label htmlFor="" className="date-range__label">
              From Date:
            </label>
          )}
          <DatePicker
            disabledDate={this.disabledStartDate}
            showTime
            format="MMM DD YYYY"
            value={startValue}
            placeholder="From"
            onChange={this.onStartChange}
            onOpenChange={this.handleStartOpenChange}
            className="filter__panel_picker"
          />
        </div>

        <div className="ml-1 datepicker__divider">
          {displayLabel && (
            <label htmlFor="" className="date-range__label">
              To Date:
            </label>
          )}
          <DatePicker
            disabledDate={this.disabledEndDate}
            showTime
            format="MMM DD YYYY"
            value={endValue}
            placeholder="To"
            onChange={this.onEndChange}
            open={endOpen}
            onOpenChange={this.handleEndOpenChange}
            className="filter__panel_picker"
          />
        </div>
      </div>
    );
  }
}

DateRange.propTypes = {
  displayLabel: PropTypes.bool,
  getStartDate: PropTypes.func.isRequired,
  getEndDate: PropTypes.func.isRequired,
};

export default DateRange;
