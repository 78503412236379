import React from 'react';
import { Form, Input, Col, Row, Button } from 'antd';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Link } from '@reach/router';
import { PageTitle } from '_components';
import { DataTable } from './components';

const validationSchema = Yup.object({
  name: Yup.string()
    .matches(/^[a-zA-Z0-9\s]+$/, {
      message: 'Please enter valid characters',
      excludeEmptyString: true,
    })
    .min(2, 'Too Short! Please enter more characters')
    .max(50, 'Too Long! Please enter no more than 50 characters'),
});

const FirstTimerAbsorption = () => {
  const handleReset = () => {
    resetForm();
  };

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    resetForm,
  } = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema,
    onSubmit(values) {
      if (!values.name) {
        delete values.name;
      }
      const data = {
        ...values,
      };
    },
  });

  return (
    <div>
      <PageTitle title="First Timers Absorption" />

      <div className="mb-2 mt-2">
        <Row gutter={8} type="flex" justify="space-between">
          <Col span={15}>
            <Row gutter={8} type="flex" justify="start">
              <Col>
                <Form.Item
                  validateStatus={errors.name ? 'error' : null}
                  help={
                    errors.name && touched.name
                      ? 'Convert name is required'
                      : null
                  }>
                  <label htmlFor="">Search Name</label>
                  <Input
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    placeholder=""
                  />
                </Form.Item>
              </Col>

              <Col className="filter__panel_btn_wrapper">
                <Button
                  type="primary"
                  icon="search"
                  className="medium__btn"
                  onClick={handleSubmit}>
                  Search
                </Button>
              </Col>
              <Col className="filter__panel_btn_wrapper ">
                <Button
                  icon="undo"
                  className="medium__btn"
                  onClick={handleReset}>
                  Clear
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={9}>
            <Row type="flex" justify="end" gutter={8}>
              <Col className="filter__panel_btn_wrapper">
                <Link to="new">
                  <Button type="primary" icon="plus" className="medium__btn">
                    Add New Record
                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <DataTable searchQuery={values.name} />
    </div>
  );
};

export default FirstTimerAbsorption;
