import React, { useState, useEffect } from 'react';
import {
  Col,
  Row,
  Button,
  Card,
  DatePicker,
  Input,
  Form,
  Select,
  Radio,
} from 'antd';
import { navigate, Link } from '@reach/router';
import { uuid } from 'uuidv4';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import isEmpty from 'lodash.isempty';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';

// internals
import { PageTitle, appNotification } from '_components';
import validationSchema from './formValidationSchema';
import { ABSORB_FIRST_TIMER, GET_FT, GET_MEMBERS } from './graphql';

const { TextArea } = Input;
const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;
const { Option } = Select;

const FirstTimerAbsorptionForm = props => {
  const order = { field: 'firstName', direction: 'ASC' };

  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(GET_FT, {
    variables: { order },
  });
  const [
    absorbFT,
    { loading: mutateLoading, error: mutateError, data: mutateData },
  ] = useMutation(ABSORB_FIRST_TIMER);

  const {
    loading: membersLoading,
    error: membersError,
    data: membersData,
  } = useQuery(GET_MEMBERS, { variables: { order } });

  const [formDetails, updateForm] = useState([]);

  const disabledDate = current => {
    return current && current > moment().endOf('day');
  };

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    dirty,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {},
    validationSchema,
    onSubmit(values) {
      //   if (!values.name) {
      //     delete values.name;
      //   }
      const formData = {
        ...values,
        branch: formDetails.branch._id,
      };

      const ID = formDetails._id;
      // formData.branch = formDetails.branch._id;
      // console.log('branch', values.branch);
      delete formData._id;
      delete formData.__typename;
      delete formData.firstTimer;

      absorbFT({ variables: { data: formData, id: ID } }).then(({ data }) => {
        data.absorbFirstTimer._id &&
          appNotification({
            desc: 'Your form was successfuly sent. Kudos',
            type: 'success',
          });
        navigate('/ft-absorption');
      });
      // resetForm();
    },
  });

  useEffect(() => {
    return () => {
      // cancel subscriptions
    };
  }, []);

  return (
    <>
      <PageTitle
        title="First Timer Absorption Form"
        extra={
          <Row type="flex" justify="end">
            <Link to="../">Back</Link>
          </Row>
        }
      />
      <div className="trans-details">
        <Row type="flex" className="" gutter={[24, 24]}>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}>
            <Row gutter={[24, 24]}>
              {/* First Timers Details column */}
              <Col span={24} className="">
                <Card
                  bordered={false}
                  style={{ borderRadius: 4 }}
                  title="Add New First Timers">
                  <Form>
                    <Form.Item
                      label="First Timer's Name"
                      htmlFor="name"
                      required
                      colon={false}
                      validateStatus={
                        errors.name && touched.name ? 'error' : null
                      }
                      help={errors.name && touched.name ? errors.name : null}>
                      <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Enter to search"
                        optionFilterProp="children"
                        onChange={text =>
                          setFieldValue('firstTimer', text).then(() =>
                            updateForm(queryData.firstTimers[text]),
                          )
                        }
                        // onBlur={handleBlur}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                        {queryData &&
                          queryData.firstTimers.map((ft, idx) => {
                            return (
                              <Option key={uuid()} value={idx}>
                                {' '}
                                {Object.values(ft)
                                  .slice(1, 4)
                                  .join(' ')}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                    <Row gutter={8}>
                      <Col span={16}>
                        <Form.Item
                          label=" First Time in Church"
                          htmlFor="firstDayInChurch"
                          colon={false}
                          validateStatus={
                            errors.firstDayInChurch && touched.firstDayInChurch
                              ? 'error'
                              : null
                          }
                          help={
                            errors.firstDayInChurch && touched.firstDayInChurch
                              ? errors.firstDayInChurch
                              : null
                          }>
                          <DatePicker
                            style={{ width: '100%' }}
                            name="firstDayInChurch"
                            format={'MMMM DD YYYY'}
                            disabledDate={disabledDate}
                            defaultValue={moment()}
                            value={values.firstDayInChurch}
                            onChange={(dateObject, dateString) =>
                              setFieldValue('firstDayInChurch', dateObject)
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label="Salvation Status"
                          htmlFor="salvationStatus"
                          colon={false}
                          validateStatus={
                            errors.saved && touched.saved ? 'error' : null
                          }
                          help={
                            errors.saved && touched.saved ? errors.saved : null
                          }>
                          <label></label>
                          <RadioGroup
                            onChange={e =>
                              setFieldValue('saved', e.target.value)
                            }
                            value={values.saved}
                            checked={formDetails.saved}>
                            <RadioButton value={true}>Saved</RadioButton>
                            <RadioButton value={false}>Unsaved</RadioButton>
                          </RadioGroup>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Form.Item
                      label="Family Unit Leader"
                      htmlFor="familyUnitLeader"
                      required
                      colon={false}
                      validateStatus={
                        errors.familyUnitLeader && touched.familyUnitLeader
                          ? 'error'
                          : null
                      }
                      help={
                        errors.familyUnitLeader && touched.familyUnitLeader
                          ? errors.familyUnitLeader
                          : null
                      }>
                      <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Family Unit Leader"
                        optionFilterProp="children"
                        onChange={text => setFieldValue('unitLeader', text)}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                        {!isEmpty(membersData) ? (
                          membersData.members.map(member => (
                            <Option key={uuid()} value={member._id}>
                              {member?.firstName + ' ' + member.lastName}
                            </Option>
                          ))
                        ) : (
                          <Option value={null}>No Unit Leaders defined</Option>
                        )}
                      </Select>
                    </Form.Item>
                    <Row gutter={8}>
                      <Col span={16}>
                        <Form.Item
                          label="Your New Life Teacher"
                          colon={false}
                          htmlFor="newLifeTeacher"
                          validateStatus={
                            errors.newLifeTeacher && touched.newLifeTeacher
                              ? 'error'
                              : null
                          }
                          help={
                            errors.newLifeTeacher && touched.newLifeTeacher
                              ? errors.newLifeTeacher
                              : null
                          }>
                          <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="New Life Teacher"
                            optionFilterProp="children"
                            onChange={text =>
                              setFieldValue('newLifeTeacher', text)
                            }
                            // onBlur={handleBlur}
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }>
                            {!isEmpty(membersData) ? (
                              membersData.members.map(member => (
                                <Option key={uuid()} value={member._id}>
                                  {member.firstName + ' ' + member.lastName}
                                </Option>
                              ))
                            ) : (
                              <Option value={null}>
                                No new life teacher defined
                              </Option>
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label="Your New Life Stage"
                          htmlFor="newListStage"
                          colon={false}
                          validateStatus={
                            errors.newLifeStage && touched.newLifeStage
                              ? 'error'
                              : null
                          }
                          help={
                            errors.newLifeStage && touched.newLifeStage
                              ? errors.newLifeStage
                              : null
                          }>
                          <Input
                            name="newLifeStage"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={
                              formDetails.newLifeStage || values.newLifeStage
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={8}>
                      <Col span={16}>
                        <Form.Item
                          label="Believer's Class Teacher"
                          htmlFor="believerClassTeacher"
                          colon={false}
                          validateStatus={
                            errors.believerClassTeacher &&
                            touched.believerClassTeacher
                              ? 'error'
                              : null
                          }
                          help={
                            errors.believerClassTeacher &&
                            touched.believerClassTeacher
                              ? errors.believerClassTeacher
                              : null
                          }>
                          <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Believers Class Teacher"
                            optionFilterProp="children"
                            onChange={text =>
                              setFieldValue('belClassTeacher', text)
                            }
                            // onBlur={handleBlur}
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }>
                            {!isEmpty(membersData) ? (
                              membersData.members.map(member => (
                                <Option key={uuid()} value={member._id}>
                                  {member.firstName + ' ' + member.lastName}
                                </Option>
                              ))
                            ) : (
                              <Option value={null}>No believers defined</Option>
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label="Believer's Class Stage"
                          colon={false}
                          htmlFor="believerClassStage"
                          validateStatus={
                            errors.believerClassStage &&
                            touched.believerClassStage
                              ? 'error'
                              : null
                          }
                          help={
                            errors.believerClassStage &&
                            touched.believerClassStage
                              ? errors.believerClassStage
                              : null
                          }>
                          <Input
                            name="belClassStage"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={
                              formDetails.belClassStage || values.belClassStage
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Form.Item
                      label="Pastors Remark"
                      colon={false}
                      htmlFor="remark"
                      validateStatus={
                        errors.remark && touched.remark ? 'error' : null
                      }
                      help={
                        errors.remark && touched.remark ? errors.remark : null
                      }>
                      <TextArea
                        name="pastoralRemarks"
                        value={
                          formDetails.pastoralRemarks || values.pastoralRemarks
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                      />
                    </Form.Item>
                    <Row type="flex" justify="end">
                      <Button
                        key="submit"
                        type="primary"
                        style={{ width: 170 }}
                        // disabled={!isEmpty(errors)}
                        onClick={handleSubmit}>
                        Submit Details
                      </Button>
                    </Row>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FirstTimerAbsorptionForm;
