import * as Yup from 'yup';

const validationSchema = Yup.object({
  firstName: Yup.string()
    .matches(/^[a-zA-Z0-9\s]+$/, {
      message: 'Please enter valid characters',
      excludeEmptyString: true,
    })
    .min(2)
    .required('First name is required'),
  lastName: Yup.string()
    .matches(/^[a-zA-Z0-9\s]+$/, {
      message: 'Please enter valid characters',
      excludeEmptyString: true,
    })
    .min(2)
    .required('Last name is required'),
  email: Yup.string()
    .email()
    .required('email name is required'),

  // member: Yup.string()
  //   .min(8)
  //   .required('Users branch required'),

  roles: Yup.array().of(Yup.string()),
});

export default validationSchema;
