import * as Yup from 'yup';

const validationSchema = Yup.object({
  name: Yup.string()
    .matches(/^[a-zA-Z0-9\s]+$/, {
      message: 'Please enter valid characters',
      excludeEmptyString: true,
    })
    .min(2)
    .required('Service name is required'),
  serviceDate: Yup.date().required('Service date is required'),
  orderOfService: Yup.string().required('Order of service is required'),
  additionalInfo: Yup.string(),
  remarks: Yup.string(),
  serviceType: Yup.string().required('Service category is required'),
});

export default validationSchema;
