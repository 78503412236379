import gql from 'graphql-tag';

export const ADD_SERVICE_TYPE = gql`
  mutation addServiceType($data: ServiceTypeInput!) {
    createServiceType(input: $data) {
      _id
    }
  }
`;

// type ServiceTypeInput {
//     language: String = "en"
//     name: String!
//     url: String
//     }
