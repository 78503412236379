import React from 'react';
import { Row } from 'antd';

const NotFound = ({ code }) => {
  //   const title =
  //     code === 404
  //       ? 'This page could not be found'
  //       : HTTPStatus[code] || 'An unexpected error has occurred';
  return (
    <Row
      type="flex"
      align="middle"
      justify="center"
      className="text-center"
      style={{ minHeight: '80vh' }}>
      <div className="not__found">
        <h1 className={`${404 === 404 ? 'text-warning' : 'text-error'} mb-0`}>
          {/* {code} */}
          Coming Soon
        </h1>
        <h6 className="title">
          {/* {title}  */}
          This page is under development or could not be found
        </h6>
      </div>
    </Row>
  );
};

export default NotFound;
