import gql from 'graphql-tag';

export const GET_BRANCH = gql`
  {
    branches {
      _id
      name
      address
      city
      state
      country
      leader {
        _id
        lastName
        firstName
      }
      region {
        _id
        name
      }
    }
  }
`;

export const DELETE_BRANCH = gql`
  mutation deleteSingleBranch($id: String!) {
    deleteBranch(id: $id) {
      _id
    }
  }
`;
