import React, { useState, useEffect } from 'react';
import { Form, Input, Col, Row, Button, Select, Pagination, Spin } from 'antd';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { uuid } from 'uuidv4';
import { GET_SERVICE_REPORTS } from './graphql';
import { GET_SERVICES } from '../Services/query';
import { GET_SERVICE_CATEGORY } from '../ServiceTypes/query';
import { useQuery } from '@apollo/client';
import { groupBy, chain } from 'lodash';
//internals
import { PageTitle, CanAccess, NotFound, Loader } from '_components';
import { DataTable, ServiceCards, OverviewCards } from './components';
import './ServiceReports.css';

const { Option } = Select;

const validationSchema = Yup.object({
  name: Yup.string()
    .matches(/^[a-zA-Z0-9\s]+$/, {
      message: 'Please enter valid characters',
      excludeEmptyString: true,
    })
    .min(2, 'Too Short! Please enter more characters')
    .max(50, 'Too Long! Please enter no more than 50 characters'),
});

const updateListWithoutOverview = (allService, serviceReport) =>
  allService &&
  allService
    // .filter(
    //   (set => a => !set.has(a._id))(
    //     new Set(serviceReport.map(b => b.service._id)),
    //   ),
    // )
    .map(report => {
      return {
        title: report.name,
        serviceType: report.serviceType.name,
        serviceTypeId: report.serviceType._id,
        serviceDate: report.serviceDate,
        serviceId: report._id,
        _id: report._id,
      };
    });

const updateListWithOverview = data =>
  data &&
  data.map(report => ({
    title: report.name,
    serviceType: report.serviceType.name,
    _id: uuid(),
  }));

const ServiceReports = () => {
  const [page, setPage] = useState(0);

  const [serviceWithOverviewReport, updateOverviewList] = useState([]);
  const [serviceWithoutOverviewList, updateWithoutOverviewList] = useState([]);

  const {
    loading: serviceReportsLoading,
    error,
    data: serviceReportsData,
  } = useQuery(GET_SERVICE_REPORTS);

  const order = { field: 'serviceDate', direction: 'Desc' };

  const {
    loading: allServicesLoading,
    error: allServicesError,
    data: allServicesData,
  } = useQuery(GET_SERVICES, {
    variables: { order },
  });

  const {
    loading: serviceTypesLoading,
    error: serviceTypesError,
    data: serviceTypesData,
  } = useQuery(GET_SERVICE_CATEGORY);

  const handleReset = () => {
    resetForm();
  };

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema,
    onSubmit(values) {
      if (!values.name) {
        delete values.name;
      }
      const data = {
        ...values,
      };
    },
  });

  useEffect(() => {
    if (
      !serviceReportsLoading &&
      !allServicesLoading &&
      !serviceTypesLoading &&
      serviceReportsData &&
      serviceReportsData.serviceReports &&
      allServicesData &&
      allServicesData.services
    ) {
      updateOverviewList(
        updateListWithOverview(serviceReportsData.serviceReports),
      );
      updateWithoutOverviewList(
        updateListWithoutOverview(
          allServicesData.services,
          serviceReportsData.serviceReports,
        ),
      );

      // if ( !serviceReportsLoading && !allServicesLoading && !serviceTypesLoading && serviceWithoutOverviewList.length < 1) {
      //   message.success(
      //     <span>You have no outstanding service overview reports</span>,
      //     4,
      //   )
      // }
    }
  }, [
    serviceReportsData,
    allServicesData,
    serviceTypesData,
    allServicesLoading,
    serviceReportsLoading,
    serviceTypesLoading,
  ]);

  useEffect(() => {
    return () => {
      // cancel subscriptions
    };
  }, []);

  return (
    <div className="page-container">
      <PageTitle title="Service Reports" />

      {/* <Row
        gutter={8}
        type="flex"
        justify="space-between"
        className=" mt-1">
        <Col span={15}>

          <Row gutter={8} type="flex" justify="start">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}>
              <Form.Item
                validateStatus={errors.name ? 'error' : null}
                help={
                  errors.name && touched.name
                    ? 'Service name is required'
                    : null
                }>
                <label htmlFor="">Search Name</label>
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Choose category"
                  optionFilterProp="children"
                  onChange={text => setFieldValue('service', text)}
                  // onBlur={handleBlur}
                  defaultValue="all"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }>
                  <Option value="all">All</Option>
                  {serviceTypesData &&
                    serviceTypesData.serviceTypes.map(type => (
                      <Option value={type.name}>{type.name}</Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <Col className="filter__panel_btn_wrapper">
              <Button
                type="primary"
                icon="search"
                className="medium__btn"
                onClick={handleSubmit}>
                Search
              </Button>
            </Col>
            <Col className="filter__panel_btn_wrapper ">
              <Button
                icon="undo"
                className="medium__btn"
                onClick={handleReset}>
                Clear
              </Button>
            </Col>
          </Row>
        </Col>
      </Row> */}

      <>
        <h2 className="welcome_subtitle">Select service you want to report</h2>

        <Row type="flex" gutter={[32, 32]} className="mx-1">
          {serviceReportsLoading ? (
            <div className="center-div">
              <Spin />
            </div>
          ) : (
            serviceWithoutOverviewList.map(serv => {
              return (
                <OverviewCards
                  _id={serv.serviceId}
                  title={serv.title}
                  serviceType={serv.serviceType}
                  serviceTypeId={serv.serviceTypeId}
                  serviceDate={serv.serviceDate}
                />
              );
            })
          )}
        </Row>
        <div className="pagination-wrapper">
          <Pagination
            defaultCurrent={1}
            total={serviceWithoutOverviewList.length}
          />
        </div>
      </>

      {/* <h3 className="service-header-divider">
        Service with Overview Reports
      </h3>

      <Row type="flex" gutter={[16, 16]}>
        {serviceTypesLoading ? (
          <div className="center-div">
            <Spin />
          </div>
        ) : (
          serviceWithOverviewReport.map(serv => (
            <ServiceCards
              _id={serv._id}
              title={serv.title}
              serviceType={serv.serviceType}
            />
          ))
        )}
      </Row>
      <div className="pagination-wrapper">
        <Pagination
          defaultCurrent={1}
          total={serviceWithOverviewReport.length}
        />
      </div> */}
    </div>
  );
};

export default ServiceReports;
