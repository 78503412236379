import React from 'react';
import {
  Col,
  Row,
  Button,
  Icon,
  Input,
  Form,
  Select,
  Divider,
  Alert,
} from 'antd';
import { navigate, Link } from '@reach/router';
import { Formik, Field, FieldArray, ErrorMessage } from 'formik';
import isEmpty from 'lodash.isempty';
import { useQuery, useMutation } from '@apollo/client';

// internals
import { PageTitle, appNotification } from '_components';
import { newSchema } from './formValidationSchema';
import { GET_BRANCH } from '../../pages/Branches/query';
import { GET_SERVICE_REPORTS } from '../../pages/ServiceReports/graphql';
import { ADD_SEED_REPORT } from './graphql';
import { GET_MEMBERS } from '../../pages/Members/query';
import { GET_MY_PROFILE } from 'pages/Profile/graphql';
import { LS } from '_helpers';

const { Option } = Select;
const { TextArea } = Input;

const SeedForm = props => {
  const currentServiceReportData = JSON.parse(LS.get('serviceReport'));
  const order = { field: 'name', direction: 'ASC' };
  const {
    loading: branchLoading,
    error: branchError,
    data: branchData,
  } = useQuery(GET_BRANCH, {
    variables: { order },
  });

  const {
    loading: serviceLoading,
    error: servceError,
    data: serviceData,
  } = useQuery(GET_SERVICE_REPORTS);

  const {
    loading: membersLoading,
    error: membersError,
    data: membersData,
  } = useQuery(GET_MEMBERS, { variables: { order } });

  const [
    addReport,
    { loading: reportLoading, error: reportError, data: reportData },
  ] = useMutation(ADD_SEED_REPORT);

  const {
    loading: myProfileLoading,
    error: myProfileError,
    data: myProfileData,
  } = useQuery(GET_MY_PROFILE);

  return (
    <div className="page-container">
      <Row
        type="flex"
        align="middle"
        justify="center"
        className=""
        gutter={[24, 24]}>
        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}>
          <PageTitle
            title="Seeds Report"
            extra={
              <Row type="flex" justify="end">
                <Link to="../">Back</Link>
              </Row>
            }
          />
          <Divider />

          <Alert
            type="info"
            className="mb-3 "
            description="Financial records cannot
            be edited or updated after submission."
            showIcon={false}
            message={'Attention!'}
          />

          <Row type="flex" gutter={[24, 24]}>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
              <Form>
                <Row gutter={[24, 24]}>
                  <Formik
                    initialValues={{
                      seedDetails: [
                        {
                          seedName: '',
                          seedAmount: 0,
                          seedRemarks: '',
                          member: '',
                        },
                      ],
                      serviceType: currentServiceReportData?.serviceTypeId,
                      recordedBy: myProfileData?.myProfile?._id,
                      branch: myProfileData?.myProfile?.member?.branch?._id,
                      actualServiceDate: '',
                      service: '',
                      serviceReport: '',
                    }}
                    validationSchema={newSchema}
                    onSubmit={values => {
                      const formData = {
                        ...values,
                      };
                      formData['serviceType'] =
                        currentServiceReportData?.serviceTypeId;
                      formData['recordedBy'] = myProfileData?.myProfile?._id;

                      addReport({ variables: { data: formData } }).then(
                        ({ data }) => {
                          appNotification({
                            desc:
                              'Your Seed Reports were successfuly added. Kudos',
                            type: 'success',
                          });
                          if (props.next) {
                            props.next(); // coming from Step builder
                          } else {
                            navigate('/seeds');
                          }
                        },
                      );
                    }}
                    children={({
                      values,
                      handleSubmit,
                      dirty,
                      errors,
                      setFieldValue,
                      touched,
                    }) => (
                      <Col xs={{ span: 24 }} lg={{ span: 24 }} className="">
                        <Form.Item
                          required
                          label="Service"
                          htmlFor="service"
                          extra="Is it L.B.A or Prayer Meeting?"
                          colon={false}
                          validateStatus={
                            errors.serviceReport && touched.serviceReport
                              ? 'error'
                              : null
                          }
                          help={
                            errors.serviceReport && touched.serviceReport
                              ? errors.serviceReport
                              : null
                          }>
                          <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Choose service"
                            optionFilterProp="children"
                            onChange={text =>
                              setFieldValue('serviceReport', text)
                            }
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }>
                            {props.createServiceReport ? (
                              <Option
                                key={props.createServiceReport._id}
                                value={props.createServiceReport._id}>
                                {props.createServiceReport.name}
                              </Option>
                            ) : (
                              serviceData &&
                              serviceData.serviceReports.map(service => (
                                <Option value={service._id}>
                                  {service.name}
                                </Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>

                        <FieldArray
                          name="seedDetails"
                          render={arrayHelpers => (
                            <div>
                              {values.seedDetails.map((article, index) => (
                                <div key={index}>
                                  <label htmlFor="seedName">Seed Name</label>
                                  <Field
                                    as={Input}
                                    type="text"
                                    id="seedName"
                                    name={`seedDetails[${index}].seedName`}
                                  />
                                  <ErrorMessage
                                    component="div"
                                    style={{ color: 'red' }}
                                    name={`seedDetails[${index}].seedName`}
                                  />
                                  <Row gutter={8}>
                                    <Col span={12} xs={{ span: 12 }}>
                                      <label htmlFor="seedAmount">
                                        Seed Amount
                                      </label>
                                      <Field
                                        as={Input}
                                        type="number"
                                        id="seedAmount"
                                        name={`seedDetails[${index}].seedAmount`}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        style={{ color: 'red' }}
                                        name={`seedDetails[${index}].seedAmount`}
                                      />
                                    </Col>
                                    <Col span={12} xs={{ span: 12 }}>
                                      <label htmlFor="member">Member</label>
                                      <Field
                                        component={Select}
                                        id="member"
                                        name={`seedDetails[${index}].member`}
                                        onChange={text =>
                                          setFieldValue(
                                            `seedDetails[${index}].member`,
                                            text,
                                          )
                                        }>
                                        {!isEmpty(membersData) ? (
                                          membersData.members.map(member => (
                                            <option
                                              key={member._id}
                                              value={member._id}>
                                              {`${member.firstName} ${member.lastName}`}
                                            </option>
                                          ))
                                        ) : (
                                          <option value={null}>
                                            No members defined
                                          </option>
                                        )}
                                      </Field>
                                      <ErrorMessage
                                        component="div"
                                        style={{ color: 'red' }}
                                        name={`seedDetails[${index}].member`}
                                      />
                                    </Col>
                                  </Row>

                                  <label htmlFor="seedRemarks">
                                    Seed Remarks
                                  </label>
                                  <Field
                                    as={TextArea}
                                    type="text"
                                    id="seedRemarks"
                                    name={`seedDetails[${index}].seedRemarks`}
                                  />
                                  <ErrorMessage
                                    component="div"
                                    style={{ color: 'red' }}
                                    name={`seedDetails[${index}].seedRemarks`}
                                  />

                                  <Form.Item>
                                    <Row
                                      type="flex"
                                      justify="center"
                                      gutter={8}>
                                      <Col span={12}>
                                        <Button
                                          type="dashed"
                                          onClick={() =>
                                            arrayHelpers.push({
                                              seedName: '',
                                              seedAmount: 0,
                                              seedRemarks: '',
                                              member: '',
                                            })
                                          }>
                                          <Icon type="plus" /> Add field
                                        </Button>
                                      </Col>
                                      <Col span={12}>
                                        {index > 0 && (
                                          <Button
                                            type="dashed"
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }>
                                            <Icon type="minus" /> Remove field
                                          </Button>
                                        )}
                                      </Col>
                                    </Row>
                                  </Form.Item>
                                </div>
                              ))}
                            </div>
                          )}
                        />

                        <Button
                          block
                          size="large"
                          type="primary"
                          disabled={!dirty || !isEmpty(errors)}
                          onClick={handleSubmit}>
                          Submit Report
                        </Button>
                      </Col>
                    )}
                  />
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default SeedForm;
