import React from 'react';
import { Col, Card, Button, Icon } from 'antd';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';
import moment from 'moment';

import { LS } from '_helpers';

const OverviewCards = ({
  title,
  serviceType,
  _id,
  serviceDate,
  serviceTypeId,
}) => {
  // save to localStorage to read on form page
  const saveParams = () => {
    LS.save(
      'serviceReport',
      JSON.stringify({
        _id,
        title,
        serviceType,
        serviceTypeId,
        serviceDate,
      }),
    );
  };

  return (
    <Col
      xs={{ span: 24 }}
      sm={{ span: 12 }}
      lg={{ span: 12 }}
      xl={{ span: 8 }}
      xxl={{ span: 6 }}
      key={`${_id}-${title}`}>
      <Card className="overview-card" bordered={false}>
        <div className="overview-card-upperpart">
          <div className="overview-card__icon_wrapper ">
            <Icon className="overview-card__icon" type="file-text" />
          </div>
          {/* <Link to={`details/${_id}`}> */}
          <h3 className="text-deep-blue service-card-title">{title}</h3>
          {/* </Link> */}
          <h5 className="title-card-desc">{serviceType}</h5>
          <h5 className="title-card-desc">
            <strong> {moment(serviceDate).format('LL')} </strong>
          </h5>
        </div>

        <div className="overview-card-lowerpart">
          <Link
            to={`/service-report/new/${_id}`}
            onClick={saveParams}
            state={{ fromServiceReport: _id, title }}>
            <Button
              className="submit-btn"
              icon="edit"
              shape="round"
              size={'large'}>
              Fill Report{' '}
            </Button>
          </Link>
        </div>
      </Card>
    </Col>
  );
};

OverviewCards.propTypes = {
  title: PropTypes.string.isRequired,
  serviceType: PropTypes.string.isRequired,
};

OverviewCards.defaultProps = {
  title: 'Unknown Service',
  serviceType: 'Unknown Service Category',
};

export default OverviewCards;
