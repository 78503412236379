import React, { useState, useEffect } from 'react';
import { bool } from 'prop-types';
import { Menu, Icon, Layout } from 'antd';
import { Link } from '@reach/router';
import { uuid } from 'uuidv4';
import { useQuery } from '@apollo/client';
import cx from 'classnames';

import NotificationDrawer from './NotificationDrawer';
import NotificationLists from './NotificationLists';
import { envConfig, Util } from '_helpers';
import { GET_MY_PROFILE } from '../../Profile/graphql';
import { MenuList, AdminMenuList, MenuWithSubMenuList } from './constants';

import './component.css';
import { get } from '_helpers/localStorage';
const { Sider } = Layout;

const { SubMenu, Divider } = Menu;

const SideMenu = ({ open }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [userProfile, setUserProfile] = useState(null);

  const { data: profileData } = useQuery(GET_MY_PROFILE, {
    skip: !get('token'),
  });

  useEffect(() => {
    if (profileData) {
      setUserProfile(profileData.myProfile);
    }
  }, [profileData, userProfile]);

  function closeDrawer(params) {
    return setIsOpen(false);
  }
  function isAdminRole(userProfile) {
    return userProfile?.roles
      ?.map(role => role._id)
      .includes(envConfig.adminRoleId);
  }
  const isOnlyDefaultRole = !!userProfile?.roles?.every(
    ({ _id }) => _id === envConfig.defaultRoleId,
  );
  if (!userProfile || isOnlyDefaultRole) return <></>;
  return (
    <div className={cx('MenuWrapper', { 'menu-open': open })} open={open}>
      <Sider>
        <Menu
          theme="dark"
          mode="inline"
          defaultOpenKeys={['sub4']}
          className="SideMenu">
          {MenuList.map(menu => (
            <Menu.Item
              key={uuid()}
              className="sidemenu-item"
              title={menu.title}>
              <Link to={menu.to}>
                <Icon type={menu.icon} />
                <span className="nav-text">{menu.title}</span>
              </Link>
            </Menu.Item>
          ))}

          {MenuWithSubMenuList.map((menu, index) => (
            <SubMenu
              key={menu.key}
              className="sidemenu-item"
              title={
                <span title={menu.title}>
                  <Icon type={menu.icon} />
                  <span className="sidemenu-item">{menu.title}</span>
                </span>
              }>
              <Menu.ItemGroup
                className="sidemenu-submenu-item"
                key={`${menu.key}-${index}`}>
                {menu.subMenu.map(item => (
                  <Menu.Item key={item.key} title={item.title}>
                    <Link to={item.to}>{item.title}</Link>
                  </Menu.Item>
                ))}
              </Menu.ItemGroup>
            </SubMenu>
          ))}

          <Divider />

          {userProfile && Util.isAdminRole(userProfile) ? (
            <SubMenu
              key="sub4"
              className="sidemenu-item"
              title={
                <span>
                  <Icon type="setting" />
                  <span className="sidemenu-item">Admin Setting</span>
                </span>
              }>
              {AdminMenuList.map((menu, index) => (
                <Menu.ItemGroup
                  className="sidemenu-submenu-item"
                  key={`${menu.key}-${index}`}
                  title={menu.title}>
                  {menu.subMenu.map(item => (
                    <Menu.Item key={item.key} title={item.title}>
                      <Link to={item.to}>{item.title}</Link>
                    </Menu.Item>
                  ))}
                </Menu.ItemGroup>
              ))}
            </SubMenu>
          ) : (
            ''
          )}
        </Menu>

        <NotificationDrawer onClose={closeDrawer} visible={isOpen}>
          <NotificationLists />
        </NotificationDrawer>
      </Sider>
    </div>
  );
};

SideMenu.propTypes = {
  open: bool.isRequired,
};

export default SideMenu;
