import * as Yup from 'yup';

export const newSchema = Yup.object().shape({
  recordedBy: Yup.string(),
  serviceType: Yup.string(),
  serviceReport: Yup.string().required('Service Report is required'),
  service: Yup.string(),
  actualServiceDate: Yup.string(),
  branch: Yup.string(),
  seedDetails: Yup.array().of(
    Yup.object().shape({
      seedName: Yup.string()
        .min(2, 'Seed Name too short')
        .required('Seed Name is required'),
      member: Yup.string().required('Member is required'),
      seedAmount: Yup.number()
        .typeError('Amount must be in digits')
        .positive('Amount must be greater than zero')
        .integer('Amount must be integer')
        .required('Amount is required'),
      seedRemarks: Yup.string().min(2, 'Remark too short'),
    }),
  ),
});
