import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Select, Divider, Alert, Button } from 'antd';
import { Link } from '@reach/router';
import { useQuery } from '@apollo/client';

// internals
import { PageTitle } from '_components';
import { DashboardCard } from './components';
import { envConfig } from '_helpers';
import { GET_MY_PROFILE } from '../Profile/graphql';

import './Dashboard.css';
import Welcome from 'pages/Welcome';

const Dashboard = () => {
  const [userProfile, setUserProfile] = useState(null);
  const { data: profileData } = useQuery(GET_MY_PROFILE);

  useEffect(() => {
    if (profileData) {
      setUserProfile(profileData.myProfile);
    }
  }, [profileData, userProfile]);

  return (
    <div>
      <PageTitle
        title={'Single Reports'}
        extra={
          <>
            <Row type="flex" justify="end">
              <Col span={4}>
                <Link to="/">Go to Home</Link>
              </Col>
            </Row>
          </>
        }
      />
      {!userProfile ? (
        <Alert
          message={
            <>
              <p>
                {/* Welcome,If your first time, kindly update your profile. Click{' '} */}
                <strong>
                  <Link to="/profile">Here</Link>
                </strong>{' '}
              </p>
            </>
          }
          type="warning"
          showIcon
        />
      ) : (
        ''
      )}
      <Divider orientation="left" className="text-gray">
        Basic Reports
      </Divider>

      <Row
        type="flex"
        gutter={[16, 16]}
        justify="start"
        style={{ textAlign: 'center' }}>
        {/* <DashboardCard
        cardLink="/attendance/new"
        iconColor="#00a8ff"
        iconType="team"
        title="Attendance Report"
      /> */}
        <DashboardCard
          cardLink="/outreach-report/new"
          iconColor="#e1b12c"
          iconType="shop"
          title="Outreach Report"
        />
        <DashboardCard
          cardLink="/believers-class/new"
          iconColor="#192a56"
          iconType="solution"
          title="Believer's Class"
        />
        <DashboardCard
          cardLink="/converts"
          iconColor="#60a3bc"
          iconType="smile"
          title="Convert Report"
        />
        <DashboardCard
          cardLink="/first-timers"
          iconColor="#33d9b2"
          iconType="user-add"
          title="First Timers"
        />
        <DashboardCard
          cardLink="/ft-visitation"
          iconColor="#474787"
          iconType="idcard"
          title="First Timer Visitation"
        />
        <DashboardCard
          cardLink="/ft-absorption"
          iconColor="#8c7ae6"
          iconType="user"
          title="First Timer's Absorption"
        />
        <DashboardCard
          cardLink="/counseling/new"
          iconColor="#b71540"
          iconType="heart"
          title="Counseling"
        />
        <DashboardCard
          cardLink="/members"
          iconColor="#38cda6"
          iconType="usergroup-add"
          title="Membership"
        />
        <DashboardCard
          cardLink="/visitation"
          iconColor="#38ada9"
          iconType="home"
          title="Member's  Visitation"
        />
      </Row>

      <Divider orientation="left" className="text-gray">
        Special Reports
      </Divider>

      <Row
        type="flex"
        gutter={[16, 16]}
        justify="start"
        style={{ textAlign: 'center' }}>
        <DashboardCard
          cardLink="/service-report"
          iconColor="#33d9b2"
          iconType="profile"
          title="Service Report"
        />

        <DashboardCard
          cardLink="/offering"
          iconColor="#44bd32"
          iconType="dollar"
          title="Offering Report"
        />
        <DashboardCard
          cardLink="/egf"
          iconColor="#44fd32"
          iconType="dollar"
          title="EGF Report"
        />
        <DashboardCard
          cardLink="/tithe"
          iconColor="#44gd32"
          iconType="dollar"
          title="Tithe Report"
        />
        <DashboardCard
          cardLink="/seeds"
          iconColor="#706fd3"
          iconType="money-collect"
          title="Seed Report"
        />
        <DashboardCard
          cardLink="/service-report/new"
          iconColor="#cc8e35"
          iconType="wallet"
          title="Expense Report"
        />

        <DashboardCard
          cardLink="/ministration/new"
          iconColor="#eb2f06"
          iconType="fire"
          title="Ministration Report"
        />
      </Row>

      <Divider orientation="left" className="text-gray">
        Other Activities/Admin
      </Divider>
      <Row
        type="flex"
        gutter={[16, 16]}
        justify="start"
        style={{ textAlign: 'center' }}>
        <DashboardCard
          cardLink="/generate"
          iconColor="#6a89cc"
          iconType="file-pdf"
          title="Generate Reports"
        />
        {userProfile && userProfile.roles[0]._id === envConfig.adminRoleId ? (
          <>
            <DashboardCard
              cardLink="/services-types"
              iconColor="#ED4C67"
              iconType="appstore"
              title="Service Catergory"
            />
            <DashboardCard
              cardLink="/roles"
              iconColor="#ED4C97"
              iconType="setting"
              title="Manage Roles"
            />
            <DashboardCard
              cardLink="/users"
              iconColor="#E1B12C"
              iconType="usergroup-add"
              title="Manage Users Details"
            />
          </>
        ) : (
          ''
        )}
      </Row>
    </div>
  );
};

export default Dashboard;
