import * as Yup from 'yup';

const validationSchema = Yup.object({
  name: Yup.string()
    .matches(/^[a-zA-Z0-9\s]+$/, {
      message: 'Please enter valid characters',
      excludeEmptyString: true,
    })
    .min(2)
    .required('Branch name is required'),
  address: Yup.string().min(2),
  city: Yup.string().min(2),
  state: Yup.string().min(2),
  country: Yup.string().min(2),
  leader: Yup.string()
    .matches(/^[a-zA-Z0-9\s]+$/, {
      message: 'Please enter valid characters',
      excludeEmptyString: true,
    })
    .min(2),
  region: Yup.string()
    .matches(/^[a-zA-Z0-9\s]+$/, {
      message: 'Please enter valid characters',
      excludeEmptyString: true,
    })
    .min(2)
    .required('Field is required'),
});

export default validationSchema;
