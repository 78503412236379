import gql from 'graphql-tag';

export const UPDATE_REGION = gql`
  mutation updateRegion($id: String!, $data: RegionInput!) {
    updateRegion(id: $id, input: $data) {
      _id
      name
      leader {
        _id
        firstName
        lastName
      }
    }
  }
`;
