import React from 'react';
import {
  Col,
  Row,
  Button,
  Input,
  Form,
  Select,
  Divider,
  Icon,
  Alert,
} from 'antd';
import { navigate } from '@reach/router';
import { useFormik } from 'formik';
import isEmpty from 'lodash.isempty';
import { useMutation, useQuery } from '@apollo/client';

// internals
import { PageTitle, appNotification } from '_components';
import validationSchema from './formValidationSchema';
import { GET_SERVICE_REPORTS, ADD_FINANCE_REPORT } from './graphql';
import { GET_MY_PROFILE } from 'pages/Profile/graphql';
import { LS } from '_helpers';

const { Option } = Select;

const FinancesForm = props => {
  const currentServiceReportData = JSON.parse(LS.get('serviceReport'));
  const order = { field: 'name', direction: 'ASC' };
  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
    refetch,
  } = useQuery(GET_SERVICE_REPORTS, {
    variables: { order },
  });

  const [
    addFinanceReport,
    { loading: mutateLoading, error: mutateError, data: mutateData },
  ] = useMutation(ADD_FINANCE_REPORT);

  const {
    loading: myProfileLoading,
    error: myProfileError,
    data: myProfileData,
  } = useQuery(GET_MY_PROFILE);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    dirty,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      tithe: 0,
      offering: 0,
      egf: 0,
      serviceType: currentServiceReportData?.serviceTypeId,
      recordedBy: myProfileData?.myProfile?._id,
      branch: myProfileData?.myProfile?.member?.branch?._id,
    },
    validationSchema,
    onSubmit(values) {
      const formData = {
        ...values,
      };
      let isIntValue = [];
      Object.keys(formData).map(key => {
        isIntValue = ['tithe', 'offering', 'egf'];
        if (isIntValue.includes(key)) {
          formData[key] = parseInt(formData[key]);
        }
      });
      addFinanceReport({ variables: { data: formData } }).then(({ data }) => {
        if (data.createFinanceReport._id) {
          appNotification({
            desc: `Your Offering Report was successfully added. Kudos`,
            type: 'success',
          });
          if (props.next) {
            props.next(); // coming from Step builder
          } else {
            navigate('/offering');
          }
        }
      });

      resetForm();
    },
  });

  return (
    <div className="page-container">
      <Row
        type="flex"
        align="middle"
        justify="center"
        className=""
        gutter={[24, 24]}>
        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}>
          <PageTitle
            title="Offering Report"
            extra={
              <Row type="flex" justify="end">
                <a
                  onClick={() => {
                    navigate(-1);
                  }}>
                  <Icon className="mx-1" type="left-circle" /> Back{' '}
                </a>
              </Row>
            }
          />
          <Divider />
          <Alert
            type="info"
            className="mb-3 "
            description="Financial records cannot
            be edited or updated after submission."
            showIcon={false}
            message={'Attention!'}
          />

          <Form>
            <Row gutter={[24, 24]}>
              <Col className="">
                <Form.Item
                  required
                  label="Service"
                  htmlFor="serviceReport"
                  colon={false}
                  validateStatus={
                    errors.serviceReport && touched.serviceReport
                      ? 'error'
                      : null
                  }
                  help={
                    errors.serviceReport && touched.serviceReport
                      ? errors.serviceReport
                      : null
                  }>
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Select Service Report"
                    optionFilterProp="children"
                    onChange={text => setFieldValue('serviceReport', text)}
                    // onBlur={handleBlur}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }>
                    {props.createServiceReport ? (
                      <Option
                        key={props.createServiceReport._id}
                        value={props.createServiceReport._id}>
                        {props.createServiceReport.name}
                      </Option>
                    ) : !isEmpty(queryData) ? (
                      queryData.serviceReports.map(report => (
                        <Option key={report._id} value={report._id}>
                          {report.name}
                        </Option>
                      ))
                    ) : (
                      <Option value={null}>No service report defined</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Offering"
                  htmlFor="offering"
                  colon={false}
                  validateStatus={
                    errors.offering && touched.offering ? 'error' : null
                  }
                  help={
                    errors.offering && touched.offering ? errors.offering : null
                  }>
                  <Input
                    name="offering"
                    type="number"
                    placeholder="0"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.offering}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row type="flex" justify="center">
            <Button
              block
              size="large"
              type="primary"
              disabled={!isEmpty(errors) || !dirty}
              onClick={handleSubmit}>
              Submit Report
            </Button>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default FinancesForm;
