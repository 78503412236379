import React, { useState, useEffect } from 'react';
import { navigate, Link } from '@reach/router';
import { Button, Form, Card, Row, Col, Input, Select, Alert } from 'antd';
import { useFormik } from 'formik';
import isEmpty from 'lodash.isempty';
import { useMutation, useQuery } from '@apollo/client';

import validationSchema from './formValidationSchema';
import { appNotification } from '_components';
import { get } from '_helpers/localStorage';
import { GET_BRANCH, CREATE_PROFILE, GET_MY_PROFILE_MIN } from './graphql';
import { envConfig } from '_helpers';
import './Profile.css';

const { Option } = Select;

/**
 * ⚠️ READ THIS BEFORE YOU GO FURTHER
 * This page is to update the user profile created thru Auth0
 * Auth0 is the auth service used but its record are not in CRA database
 * We need the user records and the branch in the CRA DB, so the user is navigated here on FIRST
 * LOGIN. After the first time login, and the branch is submitted.
 * This page is redundant. Hence why we disable submit button if "userProfile" isn't empty.
 * If there is a need to update user profile, it is done on "EditUserForm" on
 * User Management page. And it is done by Admin or a user with right permissions alone!
 */

const Profile = () => {
  const [userProfile, setUserProfile] = useState(null);
  const { data: profileData } = useQuery(GET_MY_PROFILE_MIN);

  useEffect(() => {
    if (profileData && profileData !== null) {
      setUserProfile(profileData.myProfile);
    }
  }, [profileData, userProfile]);

  const [
    createProfile,
    { loading: mutateLoading, error: mutateError },
  ] = useMutation(CREATE_PROFILE);

  const order = { field: 'name', direction: 'ASC' };
  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(GET_BRANCH, {
    variables: { order },
  });
  const currentUser = get('authUser');

  let auth0ProfileData = null;
  if (currentUser) {
    auth0ProfileData = JSON.parse(currentUser);
  }

  function initialFirstName() {
    if (auth0ProfileData?.given_name) {
      return auth0ProfileData.given_name;
    } else {
      return profileData?.myProfile?.firstName;
    }
  }

  function initialLastName() {
    if (auth0ProfileData?.family_name) {
      return auth0ProfileData.family_name;
    } else {
      return profileData?.myProfile?.lastName;
    }
  }

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      email: auth0ProfileData ? auth0ProfileData.email : null,
      lastName: initialLastName(),
      firstName: initialFirstName(),
      branch: auth0ProfileData ? auth0ProfileData.member?.branch?._id : null,
    },
    validationSchema,
    onSubmit(values) {
      values.auth0UserId = auth0ProfileData.sub;
      values.roles = envConfig.defaultRoleId;
      const formData = {
        ...values,
      };
      createProfile({ variables: { data: formData } })
        .then(({ data }) => {
          data &&
            data.signUp._id &&
            appNotification({
              desc: `${data.signUp.firstName} profile updated successfuly. Kudos`,
              type: 'success',
            });
          resetForm();
          navigate('/');
        })
        .catch(error => {
          appNotification({
            desc: mutateError?.message || error.message,
            title: 'Error',
            type: 'error',
          });
        });
    },
  });

  return (
    <>
      <Row
        type="flex"
        align="middle"
        justify="center"
        gutter={[24, 24]}
        className="py-1">
        <Col xs={{ span: 24 }} md={{ span: 8 }}>
          {profileData ? (
            <Alert
              className="alert"
              message={
                <>
                  <p>
                    Welcome, profile already existed for{' '}
                    <strong>
                      {profileData &&
                        profileData.myProfile.firstName.toUpperCase() +
                          ' ' +
                          profileData.myProfile.lastName.toUpperCase()}{' '}
                    </strong>
                    .
                    <br />
                    No action required. Go to{' '}
                    <Link class="alert-link" to="/">
                      Home
                    </Link>
                  </p>
                </>
              }
              type="warning"
              showIcon
            />
          ) : (
            <Alert
              className="alert"
              message={
                <>
                  <p>
                    Welcome, Kindly update your profile Information{' '}
                    <strong>
                      {(auth0ProfileData &&
                        auth0ProfileData?.nickname.toUpperCase()) ||
                        auth0ProfileData?.family_name.toUpperCase() +
                          ' ' +
                          auth0ProfileData.given_name.toUpperCase()}{' '}
                    </strong>
                    . <br /> Ensure your details are correct.
                  </p>
                </>
              }
              type="info"
              showIcon
            />
          )}

          <Card className="profile-card" bordered={false}>
            <h2 className="welcome__title">User Profile</h2>
            <Form>
              <Form.Item
                label="First Name"
                htmlFor="firstName"
                colon={false}
                required
                validateStatus={
                  errors.firstName && touched.firstName ? 'error' : null
                }
                help={
                  errors.firstName && touched.firstName
                    ? errors.firstName
                    : null
                }>
                <Input
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  readOnly={profileData?.firstName ? true : false}
                />
              </Form.Item>

              <Form.Item
                label="Last Name"
                htmlFor="lastName"
                required
                colon={false}
                validateStatus={
                  errors.lastName && touched.lastName ? 'error' : null
                }
                help={
                  errors.lastName && touched.lastName ? errors.lastName : null
                }>
                <Input
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  readOnly={profileData?.lastName ? true : false}
                />
              </Form.Item>
              <Form.Item
                label="Email (read only)"
                htmlFor="email"
                colon={false}
                validateStatus={errors.email && touched.email ? 'error' : null}
                help={errors.email && touched.email ? errors.email : null}>
                <Input
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  readOnly
                />
              </Form.Item>
              {!queryError && (
                <Form.Item
                  label="Branch"
                  htmlFor="branch"
                  colon={false}
                  required
                  validateStatus={
                    errors.branch && touched.branch ? 'error' : null
                  }
                  help={errors.branch && touched.branch ? errors.branch : null}>
                  <Select
                    showSearch
                    loading={queryLoading}
                    placeholder="Choose branch"
                    optionFilterProp="children"
                    onChange={text => setFieldValue('branch', text)}
                    name="branch"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }>
                    {!isEmpty(queryData) ? (
                      queryData.branches.map(type => (
                        <Option key={type._id} value={type._id}>
                          {type.name}
                        </Option>
                      ))
                    ) : (
                      <Option value={null}>No branch</Option>
                    )}
                  </Select>
                </Form.Item>
              )}

              <Button
                key="submit"
                block
                type="primary"
                size="large"
                loading={mutateLoading}
                disabled={!isEmpty(userProfile) || !isEmpty(errors)}
                onClick={handleSubmit}>
                Save Changes
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Profile;
