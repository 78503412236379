import React, { useEffect } from 'react';
import {
  Col,
  Row,
  Button,
  Icon,
  DatePicker,
  Input,
  Form,
  Divider,
  Descriptions,
  Spin,
} from 'antd';
import { navigate, Link } from '@reach/router';
import { useFormik } from 'formik';
import moment from 'moment';
import isEmpty from 'lodash.isempty';
import { useMutation, useQuery } from '@apollo/client';

// internals
import { PageTitle, appNotification } from '_components';
import validationSchema from './formValidationSchema';
import { ADD_SERVICE_REPORT } from './graphql';
import { GET_MY_PROFILE } from 'pages/Profile/graphql';
import { LS } from '_helpers';
import './ServiceReportForm.css';

const { TextArea } = Input;

const ServiceReportForm = props => {
  const currentServiceReportData = JSON.parse(LS.get('serviceReport'));
  const {
    loading: myProfileLoading,
    error: myProfileError,
    data: myProfileData,
  } = useQuery(GET_MY_PROFILE);

  const [
    addReport,
    { loading: mutateLoading, error: mutateError, data: mutateData },
  ] = useMutation(ADD_SERVICE_REPORT);

  const disabledDate = current => {
    return current && current > moment().endOf('day');
  };

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    dirty,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: `${myProfileData?.myProfile?.member?.branch?.name} Report for ${
        currentServiceReportData.title
      } on ${moment(currentServiceReportData.serviceDate).format('LL')}`,
      service: currentServiceReportData._id,
      recordedBy: myProfileData?.myProfile?._id,
      branch: myProfileData?.myProfile?.member?.branch?._id,
      serviceType: currentServiceReportData.serviceTypeId,
      actualServiceDate: moment(currentServiceReportData.serviceDate),
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit(values) {
      const formData = {
        ...values,
        actualServiceDate: new Date(
          moment(currentServiceReportData.serviceDate),
        ),
      };
      Object.keys(formData).map(key => {
        if (key === 'noOfFirstTimers') {
          formData[key] = parseInt(formData[key]);
        }
      });

      addReport({ variables: { data: formData } }).then(({ data }) => {
        if (data.createServiceReport._id) {
          appNotification({
            desc: 'Your Overview Report was successfuly added. Kudos',
            type: 'success',
          });
          navigate('/comprehensive-report', {
            state: {
              createServiceReport: data.createServiceReport,
            },
          });
        }
      });
    },
  });

  useEffect(() => {
    if (!currentServiceReportData) {
      navigate('/service-report');
    }
  }, [currentServiceReportData]);

  return (
    <div className="page-container">
      <div className="">
        <Row
          type="flex"
          align="middle"
          justify="center"
          className=""
          gutter={[24, 24]}>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}>
            <PageTitle
              title="Overview Reports Form"
              extra={
                <Row type="flex" justify="end">
                  <Link to="/service-report">
                    <Icon className="mx-1" type="left-circle" />
                    Back
                  </Link>
                </Row>
              }
            />
            <Divider />
            <Row gutter={[24, 24]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <h3 className="text-deep-blue form-title">
                  {currentServiceReportData.title}
                </h3>
                <h5 className="form-title-desc">
                  {currentServiceReportData.serviceType}
                </h5>
                <h5 className="form-title-date">
                  <strong> Date: </strong>
                  {moment(currentServiceReportData.serviceDate).format('LL')}
                </h5>

                <Divider />
                {myProfileLoading ? (
                  <p>loading...</p>
                ) : (
                  <Descriptions layout="vertical">
                    <Descriptions.Item label="Report Submitted By" span={3}>
                      {`${myProfileData?.myProfile?.firstName.toUpperCase()} ${myProfileData?.myProfile?.lastName.toUpperCase()}`}
                    </Descriptions.Item>

                    <Descriptions.Item label="Church Branch" span={3}>
                      {myProfileData?.myProfile?.member?.branch?.name}
                    </Descriptions.Item>
                  </Descriptions>
                )}
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }} className="">
                <Form>
                  <Spin
                    size="large"
                    tip="Submitting your report, Kindly hold on"
                    spinning={mutateLoading}>
                    <Form.Item
                      required
                      label="Report Name"
                      htmlFor="name"
                      colon={false}
                      validateStatus={
                        errors.name && touched.name ? 'error' : null
                      }
                      help={'Pre-filled'}>
                      <Input
                        readOnly
                        size="large"
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                      />
                    </Form.Item>
                    <Form.Item
                      required
                      label="Actual Service Date"
                      htmlFor="actualServiceDate"
                      validateStatus={
                        errors.actualServiceDate && touched.actualServiceDate
                          ? 'error'
                          : null
                      }
                      help={
                        errors.actualServiceDate && touched.actualServiceDate
                          ? errors.actualServiceDate
                          : null
                      }>
                      <DatePicker
                        size="large"
                        style={{ width: '100%' }}
                        name="actualServiceDate"
                        format={'MMMM DD YYYY'}
                        value={values.actualServiceDate}
                        disabledDate={disabledDate}
                        onChange={(dateObject, dateString) => {
                          return setFieldValue('actualServiceDate', dateObject);
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      required
                      label="No. of First Timers"
                      htmlFor="noOfFirstTimers"
                      colon={false}
                      validateStatus={
                        errors.noOfFirstTimers && touched.noOfFirstTimers
                          ? 'error'
                          : null
                      }
                      help={
                        errors.noOfFirstTimers && touched.noOfFirstTimers
                          ? errors.noOfFirstTimers
                          : null
                      }>
                      <Input
                        size="large"
                        name="noOfFirstTimers"
                        placeholder="In digits: 1234"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.noOfFirstTimers}
                      />
                    </Form.Item>

                    <Form.Item
                      required
                      label="Pastoral Remarks"
                      htmlFor="pastoralRemarks"
                      colon={false}
                      validateStatus={
                        errors.pastoralRemarks && touched.pastoralRemarks
                          ? 'error'
                          : null
                      }
                      help={
                        errors.pastoralRemarks && touched.pastoralRemarks
                          ? errors.pastoralRemarks
                          : null
                      }>
                      <TextArea
                        rows={4}
                        name="pastoralRemarks"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.pastoralRemarks}
                      />
                    </Form.Item>

                    <Form.Item>
                      <Button
                        block
                        size="large"
                        type="primary"
                        disabled={!isEmpty(errors) || !dirty}
                        onClick={handleSubmit}>
                        Submit Report
                      </Button>
                    </Form.Item>
                  </Spin>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ServiceReportForm;
