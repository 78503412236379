import gql from 'graphql-tag';

// mutate converts report
export const ADD_CONVERT_REPORT = gql`
  mutation addConvertReport($data: ConvertInput!) {
    createConvert(input: $data) {
      _id
      dateSaved
    }
  }
`;

export const GET_MEMBERS = gql`
  query getMembers {
    members {
      _id
      firstName
      middleName
      lastName
    }
  }
`;

export const GET_BRANCH = gql`
  {
    branches {
      _id
      name
    }
  }
`;
