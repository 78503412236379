import gql from 'graphql-tag';

export const GET_ATTENDANCE_REPORTS = gql`
  query getAttendanceReports {
    attendanceReports {
      _id
      pastor
      men
      women
      brothers
      sisters
      teenagers
      children
      samuelClub
      serviceType {
        _id
      }
      serviceReport {
        _id
      }
      branch {
        _id
        name
      }
      recordedBy {
        _id
      }
      service {
        name
        serviceDate
      }
    }
  }
`;
