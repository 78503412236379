/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import moment from 'moment';
import { uuid } from 'uuidv4';
import { useQuery } from '@apollo/client';
import filter from 'lodash/filter';

import { GET_CONVERTS_REPORTS } from './graphql';
import isEmpty from 'lodash.isempty';

const columns = [
  {
    title: 'Convert Name',
    dataIndex: 'name',
  },

  {
    title: 'New Life Teacher',
    dataIndex: 'newLifeTeacher',
  },
  {
    title: 'Branch',
    dataIndex: 'branch',
  },
  {
    title: 'Date Saved',
    dataIndex: 'dateSaved',
  },
  // {
  //   title: 'Action',
  //   key: '',
  //   dataIndex: '',
  //   render: (text, record) => {
  //     return (
  //       <Button
  //         key={uuid()}
  //         href={`/converts-records/${ uuid()}`}
  //         className="table-view__button"
  //         >
  //         View Details
  //       </Button>
  //     );
  //   },
  // },
];

const update = data =>
  data &&
  data.map(report => ({
    key: uuid(),
    name: report.name,
    newLifeTeacher: isEmpty(report.newLifeTeacher)
      ? ' '
      : Object.values(report.newLifeTeacher)
          .slice(0, -1)
          .join(' '),
    dateSaved:
      report.dateSaved && moment(report.dateSaved).format('MMM DD, YYYY hh:mm'),
    branch: isEmpty(report.branch) ? '-' : report.branch.name,
    description: report.pastoralRemark,
  }));

const DataTable = ({ searchQuery }) => {
  const [page, setPage] = useState(1);

  const [convertsReports, updateList] = useState([]);

  const { loading, error, data } = useQuery(GET_CONVERTS_REPORTS);

  const pagination = {
    total: convertsReports.length,
    pageSize: 10,
    current: page,
    onChange: page => {
      setPage(page);
    },
  };

  useEffect(() => {
    let results = [];
    if (searchQuery.length > 3) {
      results = filter(
        convertsReports,
        report =>
          report.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          report.name.toLowerCase().indexOf(searchQuery.toLowerCase()) != -1,
      );
      updateList(results);
    }
    return () => {
      // cancel subscriptions
      if (data && data.converts) {
        updateList(update(data.converts));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    if (data && data.converts) {
      updateList(update(data.converts));
    }
  }, [data, loading, page]);

  return (
    <div style={{ marginTop: 8 }}>
      <Table
        className="data__table"
        columns={columns}
        dataSource={convertsReports}
        pagination={pagination}
        loading={loading}
      />
    </div>
  );
};

export default DataTable;
