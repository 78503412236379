import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Card, Input, Form, Select } from 'antd';
import { navigate, Link } from '@reach/router';
import { useFormik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import isEmpty from 'lodash.isempty';

// internals
import { PageTitle, appNotification } from '_components';

import { SS } from '_helpers';
import { formItemLayout } from '_constants';
import validationSchema from './formValidationSchema';
import { UPDATE_ROLE_MUTATION, GET_PERMISSIONS } from '../RoleForm/query';

const { Option } = Select;

const EditRoleForm = props => {
  const [details, setDetails] = useState(SS.get('role-details'));
  const order = { field: 'name', direction: 'ASC' };
  const {
    loading: permissionLoading,
    error: permissionError,
    data: permissionData,
  } = useQuery(GET_PERMISSIONS, { variables: { order } });
  const [updateRole, { loading, error }] = useMutation(UPDATE_ROLE_MUTATION);
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: details.name,
      permissions: [
        ...new Set(details.permissions.map(permission => permission._id)),
      ],
    },
    validationSchema,
    onSubmit(values) {
      //   if (!values.name) {
      //     delete values.name;
      //   }
      const roleData = {
        ...values,
      };
      updateRole({ variables: { id: details.id, data: roleData } }).then(
        ({ data }) => {
          if (data.updateRole._id) {
            appNotification({
              desc: `${data.updateRole.name} was successfuly updated. Kudos`,
              type: 'success',
            });
            navigate('/roles');
          }
        },
      );
      // console.log('SUBMITTED', details.id, values);
      resetForm();
    },
  });

  useEffect(() => {
    return () => {
      // cancel subscriptions
    };
  }, []);
  return (
    <>
      <PageTitle
        title=" Edit Role"
        extra={
          <Row type="flex" justify="end">
            <Link to="/roles">Back</Link>
          </Row>
        }
      />
      <div className="trans-details">
        <Row type="flex" className="" gutter={[24, 24]}>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}>
            <Row gutter={[24, 24]}>
              <Col span={24} className="">
                <Card
                  bordered={false}
                  style={{ borderRadius: 4 }}
                  title="Change role details">
                  <Form layout="horizontal">
                    <Form.Item
                      label="Name"
                      htmlFor="name"
                      colon={false}
                      {...formItemLayout}
                      validateStatus={
                        errors.name && touched.name ? 'error' : null
                      }
                      help={errors.name && touched.name ? errors.name : null}>
                      <Input
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Permissions"
                      htmlFor="permisions"
                      colon={false}
                      {...formItemLayout}
                      validateStatus={
                        errors.permissions && touched.permissions
                          ? 'error'
                          : null
                      }
                      help={
                        errors.permissions && touched.permissions
                          ? errors.permissions
                          : null
                      }>
                      {/* THE SELECT OPTIONS SHOULD FETCHED ASYNC */}
                      <Select
                        enableReinitialize={true}
                        showSearch
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Select permissions"
                        optionFilterProp="children"
                        onChange={text => setFieldValue('permissions', text)}
                        defaultValue={[
                          ...new Set(
                            details.permissions.map(
                              permission => permission._id,
                            ),
                          ),
                        ]}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                        {!isEmpty(permissionData) ? (
                          permissionData.permissions.map(permission => (
                            <Option key={permission._id} value={permission._id}>
                              {permission.name}
                            </Option>
                          ))
                        ) : (
                          <Option value={null}>No permisison found</Option>
                        )}
                      </Select>
                    </Form.Item>

                    <Row type="flex" justify="end">
                      <Button
                        key="submit"
                        type="primary"
                        style={{ width: 170 }}
                        disabled={!isEmpty(errors)}
                        onClick={handleSubmit}>
                        Update Role
                      </Button>
                    </Row>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EditRoleForm;
