import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const options = {
  tooltips: {
    backgroundColor: '#fff',
    titleFontColor: '#4A494A',
    bodyFontColor: '#373A3C',
    footerFontColor: '#373A3C',
    borderColor: '#373',
  },
  legend: {
    display: false,
    position: 'bottom',
    align: 'center',
    boxWidth: 20,
  },
};

const data = {
  labels: ['Completed', 'Failed', 'Pending'],
  datasets: [
    {
      data: [400, 350, 280],
      backgroundColor: ['#19C165', '#FF5660', '#FFC400'],
      hoverBackgroundColor: ['#19C165', '#FF5660', '#FFC400'],
    },
  ],
};

const ISWTransactionStatusChart = () => {
  return <Doughnut data={data} options={options} height={200} width={300} />;
};

export default ISWTransactionStatusChart;
