import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Tag, Icon, Select, Radio } from 'antd';
import { Link } from '@reach/router';

const DashboardCard = ({ cardLink, title, iconType, iconColor }) => {
  return (
    <Col xs={{ span: 12 }} md={{ span: 6 }} xxl={{ span: 4 }}>
      <Link to={cardLink}>
        <Card bordered={false} hoverable style={{ borderRadius: '4px' }}>
          <Icon
            type={iconType}
            className=""
            style={{
              fontSize: '2.5rem',
              color: `${iconColor}`,
              padding: '0.75rem',
            }}
          />
          <h4 style={{ color: '#718093' }}>{title}</h4>
        </Card>
      </Link>
    </Col>
  );
};

export default DashboardCard;
