import * as Yup from 'yup';

/*
language: String = "en"
name: String!
noOfFirstTimers: Float
pastoralRemarks: String
actualServiceDate: DateTime!
serviceType: String
service: String!
branch: String!
recordedBy: String

*/

const validationSchema = Yup.object({
  name: Yup.string()
    .min(2)
    .required('Field is required'),

  noOfFirstTimers: Yup.number()
    .typeError('Must be in digits')
    .min(0)
    .integer(),

  pastoralRemarks: Yup.string().required(),

  actualServiceDate: Yup.date().required(),

  service: Yup.string()
    .min(2)
    .required('"Service" is required'),

  serviceType: Yup.string().min(2),

  branch: Yup.string()
    .min(2)
    .required('"Branch" is required'),

  recordedBy: Yup.string()
    .min(2)
    .required('Field is required'),
});

export default validationSchema;
