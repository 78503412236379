import { setContext } from '@apollo/client/link/context';
import { ApolloClient, InMemoryCache as Cache } from '@apollo/client';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { fragmentMatcher } from './createCache';

import { onError } from 'apollo-link-error';
import { appNotification } from '_components';

import { get } from './localStorage';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

const cache = new Cache({ fragmentMatcher }).restore(caches);

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = get('token');
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  });

  return forward(operation);
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = get('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};
const { log } = console;

const errorHandler = onError(({ response, graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    // console.log(graphQLErrors);
    graphQLErrors.map(({ message, locations, path }) =>
      process.env.REACT_APP_NODE_ENV !== 'production'
        ? appNotification({ desc: message, title: 'Error', type: 'error' })
        : log(message),
    );
  }

  // console.log(Object.getOwnPropertyNames(networkError))
  //check authorization status code instead
  if (networkError)
    appNotification({
      desc: networkError.message,
      title: networkError.name || 'Network Error',
      type: 'error',
    });
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new Cache(),
});
export default client;
