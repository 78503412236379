/**
 * Role Base Accesss Rules for components and pages
 * Reference: https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/
 */

const rules = {
  institutions: {
    static: [
      'ROLE_CAN_SEE_BANK_LINKS',
      'ROLE_CAN_SEE_BANK_TRANSACTIONS_CARDS',
      'ROLE_CAN_SEE_LINE_CHART',
      'ROLE_CAN_SEE_STATS_SUMMARY',
      'ROLE_CAN_SEE_CHARGEBACK_PAGE',
      'ROLE_CAN_SEE_BATCH_UPLOADED_BTN',
      'ROLE_CAN_SEE_DOWNLOAD_FILE',
      'ROLE_CAN_SEE_APPROVE_ICONS',
      'ROLE_CAN_SEE_COMMENT_EDITOR',
      'ROLE_CAN_SEE_AGENT_PAGE',
    ],
    dynamic: {},
  },
  interswitch: {
    static: [
      'ROLE_CAN_SEE_INTERSWITCH_LINKS',
      'ROLE_CAN_SEE_ISW_TRANSACTIONS_CARDS',
      'ROLE_CAN_SEE_LINE_CHART',
      'ROLE_CAN_SEE_STATS_SUMMARY',
      'ROLE_CAN_SEE_BANKS_DROPDOWNS_AT_LINECHART',
    ],
    dynamic: {},
  },
  agent: {
    static: [
      'ROLE_CAN_SEE_AGENT_DASHBOARD',
      'ROLE_CAN_SEE_AGENT_LINKS',
      'ROLE_CAN_SEE_CHARGEBACK_PAGE',
      'ROLE_CAN_SEE_ATTACH_FILE',
      'ROLE_CAN_SEE_COMMENTS_COLLAPSE_ICON',
      'ROLE_CAN_SEE_EVIDENCE_UPLOADED_BTN',
      'ROLE_CAN_SEE_EVIDENCE_WAITING_TO_BE_UPDATED',
      'ROLE_CAN_SEE_AGENT_SIGNIN',
      'ROLE_CAN_SEE_AGENT_VERIFY',
      'ROLE_CAN_SEE_AGENT_RESET_PASSWORD',
      'ROLE_CAN_SEE_AGENT_RESET_SUCCESS',
    ],
    dynamic: {},
  },
};

export default rules;
