import React, { useEffect, useState } from 'react';
import { Table, Button, Tag, Modal } from 'antd';
import { useQuery, useLazyQuery } from '@apollo/client';
import { uuid } from 'uuidv4';
import filter from 'lodash/filter';
import { GET_SERVICES, GET_SINGLE_SERVICE } from '../query';

const update = data =>
  data &&
  data
    .map(service => ({
      key: uuid(),
      id: service._id,
      name: service.name,
      serviceType: service.serviceType,
      remarks: service.remarks,
      orderOfService: service.orderOfService,
      serviceDate: new Date(service.serviceDate).toDateString(),
    }))
    .sort(
      (a, b) =>
        new Date(b.serviceDate).getTime() - new Date(a.serviceDate).getTime(),
    );

const DataTable = ({ searchQuery }) => {
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [record, setRecord] = useState([]);
  const [serviceData, updateList] = useState([]);
  const order = { field: 'serviceDate', direction: 'Asc' };

  const { loading, data } = useQuery(GET_SERVICES, {
    variables: { order },
  });

  const [
    getSingleService,
    { loading: detailsLoading, data: detailsData },
  ] = useLazyQuery(GET_SINGLE_SERVICE, {
    variables: { id: record?.id },
  });

  const pagination = {
    total: serviceData && serviceData.length,
    pageSize: 10,
    current: page,
    onChange: page => {
      setPage(page);
    },
  };

  const handleShowModal = data => {
    setRecord(data);
    setShowModal(true);
    // TODO: Let's look into this later because graphql get's called when you close Modal
    // a double calls happen or something
    getSingleService();
  };

  const handleCancel = () => {
    setShowModal(false);
    setRecord(null);
  };

  const columns = [
    {
      title: 'Service Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.length - b.name.length,
      key: 'name',
    },
    {
      title: 'Date',
      dataIndex: 'serviceDate',
      sorter: (a, b) => new Date(a.serviceDate) - new Date(b.serviceDate),
      render: (_, record) => {
        return <>{new Date(record.serviceDate).toDateString()}</>;
      },
    },
    {
      title: 'Service Category',
      dataIndex: 'serviceType',
      sorter: (a, b) => a.serviceType.name.length - b.serviceType.name.length,
      render: (_, record) => {
        return (
          <>
            {record.serviceType === null ? (
              <Tag color="red">Unknown Category</Tag>
            ) : (
              record.serviceType.name
            )}
          </>
        );
      },
    },
    {
      title: 'Action',
      key: 'record.id',
      dataIndex: 'record.id',
      align: 'center',
      width: 160,
      render: (_, record) => {
        return (
          <Button
            key={record.id}
            onClick={() => handleShowModal(record)}
            className="table-view__button">
            View Details
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    let results = [];
    if (searchQuery.length > 3) {
      results = filter(serviceData, service => {
        const name = Object.values(service)
          .slice(1, 4)
          .join(' ')
          .toLowerCase();

        return (
          name.includes(searchQuery.toLowerCase()) ||
          name.indexOf(searchQuery.toLowerCase()) !== -1
        );
      });
      updateList(results);
    }
    return () => {
      if (data && data.services) {
        updateList(update(data.services));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    if (data && data.services) {
      updateList(update(data.services));
    }
  }, [data, loading]);

  useEffect(() => {}, [page]);

  return (
    <div style={{ marginTop: 8 }}>
      <Table
        className="data__table"
        columns={columns}
        dataSource={serviceData}
        pagination={pagination}
        loading={loading}
      />
      <Modal
        title="Service Details"
        visible={showModal}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
        ]}>
        {detailsLoading ? (
          <p>Loading ...</p>
        ) : (
          <div>
            <p>{detailsData?.id}</p>
            <h5>Name</h5>
            <p>{detailsData?.singleService?.name}</p>
            <h5>Date</h5>
            <p>
              {new Date(detailsData?.singleService?.serviceDate).toDateString()}
            </p>
            <h5>Order of Service</h5>
            <p>{detailsData?.singleService?.orderOfService}</p>
            <h5>Order of Remarks</h5>
            <p>{detailsData?.singleService?.remarks}</p>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default DataTable;
