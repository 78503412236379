import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { Card, Col, Row, Tag, Icon, Select, Radio } from 'antd';

/**
 * THIS COMPONENT IS STRICTLY USED ON THE DASHBOARD VIEW
 */
const DashboardStatsCard = ({ title, value, volume, volumeColor }) => {
  return (
    <Col
      xs={{ span: 24 }}
      md={{ span: 12 }}
      lg={{ span: 12 }}
      xl={{ span: 6 }}
      xxl={{ span: 6 }}>
      <Card className="stats__card" bordered={false}>
        <div className="stats__card-top">
          <h4 className="card__title">{title}</h4>
        </div>
        <div className="stats__card-content">
          <h4 className="stats__h4">Volume</h4>
          <h2 className={`stats__h2 ${volumeColor}`}>{volume}</h2>
          <h4 className="stats__h4 ">Value</h4>
          <NumberFormat
            value={value}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'₦'}
            className="stats__h3"
          />
        </div>
      </Card>
    </Col>
  );
};

DashboardStatsCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  volume: PropTypes.number.isRequired,
  volumeColor: PropTypes.string.isRequired,
};

DashboardStatsCard.defaultProps = {
  value: 0,
  volume: 0,
  volumeColor: 'blue-text', // other colour: /green-text /yellow-text/red-text
};
export default DashboardStatsCard;
