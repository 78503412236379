import React, { useEffect, useState } from 'react';
import { Layout, Spin, Icon, Menu, Avatar, Col, Row } from 'antd';
import { Link } from '@reach/router';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

// internals
import { BurgerMenu, NewSideMenu } from './components';
import './AppLayout.css';
import { save, clear } from '_helpers/localStorage';
import { appNotification as notification } from '_components';

const { Footer, Content, Header } = Layout;
const { SubMenu } = Menu;

const NonDashboardRoutes = [
  '/login',
  '/signup',
  '/reset-password',
  '/verify',
  '/reset-success',
  '/_error',
  '/start',
];

const AppLayout = ({ location, children }) => {
  const { user: authData, isAuthenticated, isLoading } = useAuth0();
  const { logout, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const isNotDashboard = NonDashboardRoutes.includes(location.pathname);
  const [open, setOpen] = useState(false);
  const [authSuccess, setAuthSuccess] = useState(
    !!localStorage.getItem('token'),
  );
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    getAccessTokenSilently({
      audience: `${process.env.REACT_APP_AUTH_AUDIENCE}`,
      scope: 'read:current_user',
    })
      .then(token => {
        save('token', token);
        save('authUser', JSON.stringify(authData));
        setAuthSuccess(true);
      })
      .then(async () => {})
      .catch(e => {
        notification(
          'error loading profile, kindly refresh',
          ' error ',
          'error',
        );
      });
  }, [authData, getAccessTokenSilently]);

  useEffect(() => {
    setLoading(false);
  }, [loading]);

  return !isAuthenticated ? (
    loginWithRedirect()
  ) : (
    <Spin
      tip="Loading..."
      size="large"
      indicator={<Icon type="loading" spin />}
      spinning={loading || isLoading}>
      <Layout className="pagelayout">
        {!isNotDashboard && (
          <>
            <BurgerMenu open={open} setOpen={setOpen} />
            <NewSideMenu open={open} setOpen={setOpen} />
          </>
        )}

        <Layout className="pagelayout__main">
          <Header style={{ background: '#fff' }}>
            <Row type="flex" justify="end">
              <Col>
                <Menu mode="horizontal" style={{ lineHeight: '64px' }}>
                  <SubMenu
                    title={
                      <span className="submenu-title-wrapper">
                        <Link to={'/profile'}>
                          <Avatar
                            style={{ backgroundColor: '#87d068' }}
                            icon={<Icon type="user" />}
                            src={
                              authData
                                ? authData.picture
                                : 'https://placeimg.com/160/160/any'
                            }
                          />
                          &nbsp; {authData ? authData.name : 'Profile name'}
                        </Link>
                      </span>
                    }
                    key="199">
                    <Menu.Item key="setprofile:2">
                      <Link to={'/profile'}>
                        <Icon type="user" />
                        Profile
                      </Link>
                    </Menu.Item>
                    <Menu.Divider />{' '}
                    <Menu.Item key="setting:2">
                      <Icon type="setting" />
                      Settings
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item
                      key="1-1"
                      onClick={() => {
                        clear();
                        logout({ returnTo: window.location.origin });
                      }}>
                      <Icon type="logout" />
                      Log out
                    </Menu.Item>
                  </SubMenu>
                </Menu>
              </Col>
            </Row>
          </Header>
          <Content
            onClick={e => setOpen(false)}
            className="pagelayout__content">
            {authSuccess && children}
          </Content>
          <Footer>Dream Centre Int'l Church ©{currentYear}</Footer>
        </Layout>
      </Layout>
    </Spin>
  );
};

export default withAuthenticationRequired(AppLayout);
