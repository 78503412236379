import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { ApolloProvider } from '@apollo/client';
import { StepsProvider } from 'react-step-builder';

import './index.css';
import App from './App';
import { client } from '_helpers';

const domain = process.env.REACT_APP_AUTHDOMAIN;
const clientId = process.env.REACT_APP_CLIENTID;
const audience = process.env.REACT_APP_AUTH_AUDIENCE;

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        useRefreshTokens={true}
        redirectUri={window.location.origin}
        audience={audience}
        scope="read:current_user">
        <StepsProvider>
          <App />
        </StepsProvider>
      </Auth0Provider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
