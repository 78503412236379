import gql from 'graphql-tag';

export const GET_BRANCH = gql`
  {
    branches {
      _id
      name
    }
  }
`;
export const GET_MEMBERS = gql`
  query getMembers {
    members {
      _id
      firstName
      middleName
      lastName
    }
  }
`;
//fix correct mutation
export const CREATE_FIRST_TIMERS = gql`
  mutation createFirstTimer($data: FirstTimerInput!) {
    createFirstTimer(input: $data) {
      _id
      lastName
      firstName
    }
  }
`;
