import dotenv from 'dotenv';

dotenv.config();

export const {
  REACT_APP_GRAPHQL_ENDPOINT,
  REACT_APP_CLIENT_ID,
  REACT_APP_REALM,
  REACT_APP_CALLBACK_URL,
  REACT_APP_AUTH_SERVER_URL,
  REACT_APP_RESOURCE,
  REACT_APP_DEFAULT_ROLE_ID,
  REACT_APP_ADMIN_ROLE_ID,
} = process.env;

export const envConfig = {
  graphQLEndpoint: REACT_APP_GRAPHQL_ENDPOINT,
  adminRoleId: REACT_APP_ADMIN_ROLE_ID,
  defaultRoleId: REACT_APP_DEFAULT_ROLE_ID,
  clientId: REACT_APP_CLIENT_ID,
  realm: REACT_APP_REALM,
  url: REACT_APP_AUTH_SERVER_URL,
  resource: REACT_APP_RESOURCE,
  callbackUrl: REACT_APP_CALLBACK_URL,
};
