import React, { useEffect, useState } from 'react';
import { Table, Button, Tag, Divider, Modal } from 'antd';
import { uuid } from 'uuidv4';
import { Link } from '@reach/router';
import { useQuery } from '@apollo/client';
import filter from 'lodash/filter';
import isEmpty from 'lodash.isempty';

import { SS } from '_helpers';
import { GET_USERS } from '../query';

const columns = [
  {
    title: 'Last Name',
    dataIndex: 'lastName',
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Branch',
    dataIndex: 'branch',
  },
  {
    title: 'Roles',
    dataIndex: 'roleList',
    render: (text, record) => {
      const list = record.roleList.map(role => <Tag>{role} </Tag>);
      return <>{list}</>;
    },
  },

  {
    title: 'Action',
    key: '',
    dataIndex: '',
    render: (text, record) => {
      return (
        <>
          <Link to={`${record.id}/edit`}>
            <Button
              key={record._id}
              size="small"
              // href={`/ft-absorption/${uuid()}`}
              onClick={() => SS.save('user-details', record)}
              className="table-view__button">
              Edit
            </Button>
          </Link>
        </>
      );
    },
  },
];
// @Todo adapt leaders and branch data
const { confirm } = Modal;

function showDeleteConfirm(record) {
  confirm({
    title: 'Are you sure you want delete?',
    content: (
      <>
        <p className="mt-2 mb-2">
          Do you want to delete users with{' '}
          <strong>{record.lastName + ' ' + record.firstName}</strong> ?
        </p>
        <small className="text-error my-1">
          Please note action is irrevesible!
        </small>
      </>
    ),
    okText: 'Delete Users',
    okType: 'danger',
    cancelText: 'Cancel',
    onOk() {
      // console.log('OK');
    },
    onCancel() {},
  });
}
const update = data =>
  data &&
  data.map(user => ({
    key: uuid(),
    id: user._id,
    lastName: user.lastName,
    firstName: user.firstName,
    email: user.email,
    branch:
      isEmpty(user.member) || isEmpty(user.member.branch)
        ? '-'
        : user.member.branch.name,
    roles: user.roles,
    roleList: user.roleList,
    branchDetails:
      isEmpty(user.member) || isEmpty(user.member.branch)
        ? '-'
        : user.member.branch,
  }));

const DataTable = ({ searchQuery }) => {
  const [userData, updateList] = useState([]);

  const [page, setPage] = useState(1);
  const order = { field: 'lastName', direction: 'Asc' };
  const { loading, error, data } = useQuery(GET_USERS, {
    variables: { order },
  });

  useEffect(() => {
    let results = [];
    if (searchQuery.length > 3) {
      results = filter(userData, user => {
        const name = Object.values(user)
          .slice(1, 4)
          .join(' ')
          .toLowerCase();

        return (
          name.includes(searchQuery.toLowerCase()) ||
          name.indexOf(searchQuery.toLowerCase()) !== -1
        );
      });
      updateList(results);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    if (data && data.users) {
      updateList(
        update(
          data.users.map(user => ({
            ...user,
            roleList: user.roles.map(role => role.name),
          })),
        ),
      );
    }
  }, [data, loading]);

  // CONTROL PAGINATION DATA
  const pagination = {
    total: userData && userData.length,
    pageSize: 10,
    current: page,
    onChange: page => {
      setPage(page);
    },
  };

  useEffect(() => {
    return () => {
      // cancel subscriptions
    };
  }, [page]);

  return (
    <div style={{ marginTop: 8 }}>
      <Table
        className="data__table"
        columns={columns}
        dataSource={userData}
        pagination={pagination}
        loading={loading}
      />
    </div>
  );
};

export default DataTable;
