import gql from 'graphql-tag';

export const GET_USERS = gql`
  {
    users {
      _id
      auth0UserId
      lastName
      firstName
      email
      member {
        _id
        branch {
          _id
          name
        }
      }
      roles {
        _id
        name
      }
    }
  }
`;
