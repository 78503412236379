import React, { Suspense, lazy } from 'react';
import { Router } from '@reach/router';

import { ResetPassword } from 'pages';
import Root from 'pages/Root';

/**
 * ========== PAGES =================
 * Lazy import page for faster loading time
 */
const Welcome = lazy(() => import('../pages/Welcome'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const Profile = lazy(() => import('../pages/Profile'));
const Members = lazy(() => import('../pages/Members'));
const BulkMembers = lazy(() => import('../pages/BulkMembers'));
const FirstTimers = lazy(() => import('../pages/FirstTimers'));
const Branches = lazy(() => import('../pages/Branches'));
const Regions = lazy(() => import('../pages/Regions'));
const Services = lazy(() => import('../pages/Services'));
const NotFound = lazy(() => import('../pages/NotFound'));
const Attendance = lazy(() => import('../pages/Attendance'));
const SeedsReports = lazy(() => import('../pages/SeedsReports'));
const TitheReports = lazy(() => import('../pages/TitheReports'));
const EGFReports = lazy(() => import('../pages/EGFReports'));
const FinanceReports = lazy(() => import('../pages/FinanceReports'));
const ServiceTypes = lazy(() => import('../pages/ServiceTypes'));
const ServiceReports = lazy(() => import('../pages/ServiceReports'));
const FirstTimerDetails = lazy(() => import('../pages/FirstTmerDetails'));
const MemberDetails = lazy(() => import('../pages/MemberDetails'));
const RoleManagement = lazy(() => import('../pages/RoleManagement'));
const UserManagement = lazy(() => import('../pages/UserManagement'));
const ConvertsReports = lazy(() => import('../pages/ConvertsReports'));
const ReportAnalytics = lazy(() => import('../pages/ReportAnalytics'));
const AttendanceDetails = lazy(() => import('../pages/AttendanceDetails'));
const SeedsReportDetails = lazy(() => import('../pages/SeedsReportDetails'));
const TitheReportDetails = lazy(() => import('../pages/TitheReportDetails'));
const EGFReportDetails = lazy(() => import('../pages/EGFReportDetails'));
const ServiceReportsList = lazy(() => import('../pages/ServiceReportsList'));
const FinanceReportDetails = lazy(() =>
  import('../pages/FinanceReportDetails'),
);
const FirstTimerAbsorption = lazy(() =>
  import('../pages/FirstTimerAbsorption'),
);
const FirstTimerVisitation = lazy(() =>
  import('../pages/FirstTimerVisitation'),
);
const MemberVisitation = lazy(() => import('../pages/MemberVisitation'));
const ServiceReportDetails = lazy(() =>
  import('../pages/ServiceReportDetails'),
);
const FirstTimerAbsorptionDetails = lazy(() =>
  import('../pages/FirstTimerAbsorptionDetails'),
);
const MultistepFormsPage = lazy(() => import('../pages/MultistepFormsPage'));

/**
 * ========== FORM PAGES =================
 * Lazy import page for faster loading time
 */
const RoleForm = lazy(() => import('../formPages/RoleForm'));
const SeedsForm = lazy(() => import('../formPages/SeedsForm'));
const TitheForm = lazy(() => import('../formPages/TitheForm'));
const EGFForm = lazy(() => import('../formPages/EGFForm'));
const BranchForm = lazy(() => import('../formPages/BranchForm'));
const EditUserForm = lazy(() => import('../formPages/EditUserForm'));
const EditRoleForm = lazy(() => import('../formPages/EditRoleForm'));
const EditRegionForm = lazy(() => import('../formPages/EditRegionForm'));
const EditBranchForm = lazy(() => import('../formPages/EditBranchForm'));
const RegionForm = lazy(() => import('../formPages/RegionForm'));
const MembersForm = lazy(() => import('../formPages/MembersForm'));
const EditMembersForm = lazy(() => import('../formPages/EditMembersForm'));
const FinanceForm = lazy(() => import('../formPages/FinanceForm'));
const ServiceForm = lazy(() => import('../formPages/ServiceForm'));
const AttendanceForm = lazy(() => import('../formPages/AttendanceForm'));
const ServiceReportForm = lazy(() => import('../formPages/ServiceReportForm'));
const ServiceTypeForm = lazy(() => import('../formPages/ServiceTypeForm'));
const FirstTimersForm = lazy(() => import('../formPages/FirstTimersForm'));
const FirstTimerAbsorptionForm = lazy(() =>
  import('../formPages/FirstTimerAbsorptionForm'),
);
const FirstTimerVisitationForm = lazy(() =>
  import('../formPages/FirstTimerVisitationForm'),
);
const MemberVisitationForm = lazy(() =>
  import('../formPages/MemberVisitationForm'),
);

const Placeholder = ({ children }) => {
  return children;
};

const routes = (
  <Suspense fallback={<div>Loading...</div>}>
    <Router>
      <NotFound default />
      <Root path="/" />
      <Welcome path="/welcome" />
      <Dashboard path="/dashboard" />
      <ConvertsReports path="/converts" />
      <ReportAnalytics path="/reports-analytics" />
      <Profile path="/profile" />
      <Placeholder path="/ft-absorption">
        <FirstTimerAbsorption path="/" />
        <FirstTimerAbsorptionForm path="new" />
        <FirstTimerAbsorptionDetails path=":id" />
      </Placeholder>
      <Placeholder path="/ft-visitation">
        <FirstTimerVisitation path="/" />
        <FirstTimerVisitationForm path="new" />
        <FirstTimerAbsorptionDetails path=":id" />
      </Placeholder>
      <Placeholder path="/visitation">
        <MemberVisitation path="/" />
        <MemberVisitationForm path="new" />
        <FirstTimerAbsorptionDetails path=":id" />
      </Placeholder>
      <Placeholder path="/service-report">
        <ServiceReports path="/" />
        <ServiceReportDetails path="details/:id" />
        <ServiceReportForm path="new/:id" />
        <ServiceReportsList path="lists" />
      </Placeholder>
      <Placeholder path="/attendance">
        <Attendance path="/" />
        <AttendanceForm path="new" />
        <AttendanceDetails path="details/:id" />
      </Placeholder>
      <Placeholder path="/branches">
        <Branches path="/" />
        <BranchForm path="new" />
        <EditBranchForm path=":id/edit" />
      </Placeholder>
      <Placeholder path="/regions">
        <Regions path="/" />
        <RegionForm path="new" />
        <EditRegionForm path=":id/edit" />
      </Placeholder>
      <Placeholder path="/roles">
        <RoleManagement path="/" />
        <RoleForm path="new" />
        <EditRoleForm path=":id/edit" />
      </Placeholder>
      <Placeholder path="/users">
        <UserManagement path="/" />
        <EditUserForm path=":id/edit" />
      </Placeholder>
      <Placeholder path="/seeds">
        <SeedsReports path="/" />
        <SeedsForm path="new" />
        <SeedsReportDetails path="details/:id" />
      </Placeholder>
      <Placeholder path="/tithe">
        <TitheReports path="/" />
        <TitheForm path="new" />
        <TitheReportDetails path="details/:id" />
      </Placeholder>
      <Placeholder path="/egf">
        <EGFReports path="/" />
        <EGFForm path="new" />
        <EGFReportDetails path="details/:id" />
      </Placeholder>
      <Placeholder path="/offering">
        <FinanceReports path="/" />
        <FinanceForm path="new" />
        <FinanceReportDetails path="details/:id" />
      </Placeholder>
      <Placeholder path="/services-types">
        <ServiceTypes path="/" />
        <ServiceTypeForm path="new" />
      </Placeholder>
      <Placeholder path="/services">
        <ServiceForm path="new" />
        <Services path="/" />
      </Placeholder>
      <Placeholder path="/members">
        <MembersForm path="/new" />
        <BulkMembers path="/bulk-upload" />
        <Members path="/" />
        <EditMembersForm path=":id/edit" />
        <MemberDetails path="details/:id" />
      </Placeholder>
      <Placeholder path="/first-timers">
        <FirstTimersForm path="/new" />
        <FirstTimers path="/" />
        <FirstTimerDetails path="details/:id" />
      </Placeholder>
      <Placeholder path="/comprehensive-report">
        <MultistepFormsPage path="/" />
      </Placeholder>
    </Router>
  </Suspense>
);

export default routes;
