import React, { useEffect, useState } from 'react';
import { Table, Button, Divider, Modal, Tag } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import filter from 'lodash/filter';
import isEmpty from 'lodash.isempty';
import { uuid } from 'uuidv4';
import { Link } from '@reach/router';
import { SS } from '_helpers';
import { DELETE_REGION, GET_REGION } from '../query';
import { appNotification } from '_components';
import { GQL_ORDER } from '_helpers/util';

const update = data =>
  data &&
  data.map(region => ({
    key: uuid(),
    id: region._id,
    name: region.name,
    branches: region.branches,
    leaderId: region.leader?._id,
    branchList: region.branchList,
    leader: isEmpty(region.leader)
      ? '-'
      : region.leader.firstName + ' ' + region.leader.lastName,
  }));

const DataTable = ({ searchQuery }) => {
  const [regionData, updateList] = useState([]);

  const [page, setPage] = useState(1);
  const [region, setRegion] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const { loading, data } = useQuery(GET_REGION, {
    variables: { order: GQL_ORDER },
  });

  const [deleteRegion, { loading: deleteLoading }] = useMutation(
    DELETE_REGION,
    {
      variables: { id: region?.id },
      onCompleted() {
        setShowModal(false);
        setRegion(null);
        window.location.reload();
      },
      onError() {
        appNotification({
          desc: 'Unable to delete branch. Try again later.',
          type: 'error',
        });
      },
    },
  );

  const handleShowModal = data => {
    setRegion(data);
    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
    setRegion(null);
  };

  const handleDelete = () => {
    deleteRegion(region.id);
  };

  useEffect(() => {
    let results = [];
    if (searchQuery.length > 3) {
      results = filter(regionData, region => {
        const name = Object.values(region)
          .slice(1, 4)
          .join(' ')
          .toLowerCase();

        return (
          name.includes(searchQuery.toLowerCase()) ||
          name.indexOf(searchQuery.toLowerCase()) !== -1
        );
      });
      updateList(results);
    }
    return () => {
      if (data && data.regions) {
        updateList(update(data.regions));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    if (data?.regions) {
      updateList(
        update(
          data.regions.map(region => ({
            ...region,
            branchList: region.branches.map(branch => branch.name),
          })),
        ),
      );
    }
  }, [data, loading]);

  const pagination = {
    total: regionData && regionData.length,
    pageSize: 10,
    current: page,
    onChange: page => {
      setPage(page);
    },
  };

  const columns = [
    {
      title: 'Region Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name?.length - b.name?.length,
    },
    {
      title: 'Region Leader',
      dataIndex: 'leader',
      sorter: (a, b) => a.leader?.length - b.leader?.length,
    },
    {
      title: 'Branches',
      dataIndex: 'branchList',
      width: '45%',
      render: record => {
        const list = record.map(branch => <Tag>{branch}</Tag>);
        return <> {list}</>;
      },
    },
    {
      title: 'Action',
      key: '',
      dataIndex: '',
      align: 'center',
      render: (_, record) => {
        return (
          <>
            <Button
              key={record.id}
              onClick={() => handleShowModal(record)}
              className=""
              size="small"
              type="link">
              Delete
            </Button>
            <Divider type="vertical" />
            <Link to={`${record.id}/edit`}>
              <Button
                key={record._id}
                size="small"
                onClick={() => SS.save('region-details', record)}
                className="table-view__button">
                Edit
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  useEffect(() => {}, [page]);

  return (
    <div style={{ marginTop: 8 }}>
      <Table
        className="data__table"
        columns={columns}
        dataSource={regionData}
        pagination={pagination}
        loading={loading}
      />
      {region && (
        <Modal
          title="Delete Region"
          visible={showModal}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              key="delete"
              type="danger"
              onClick={handleDelete}
              loading={deleteLoading}>
              Delete
            </Button>,
          ]}>
          <p>
            Do you want to delete <strong>{region?.name} </strong> led by{' '}
            <strong>{region.leader} </strong>
            with {region.branchList.length} branch(es)?
            <br />
            <strong className="text-error">
              Please note action is irrevesible!
            </strong>
          </p>
        </Modal>
      )}
    </div>
  );
};

export default DataTable;
