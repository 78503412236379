import gql from 'graphql-tag';

export const GET_BRANCH = gql`
  {
    branches {
      _id
      name
    }
  }
`;
export const CREATE_PROFILE = gql`
  mutation createProfile($data: UserInput!) {
    signUp(input: $data) {
      _id
      lastName
      firstName
    }
  }
`;

export const GET_MY_PROFILE = gql`
  {
    myProfile {
      _id
      email
      firstName
      auth0UserId
      lastName
      member {
        branch {
          _id
          name
          region {
            _id
          }
        }
        phoneNumber
      }
      roles {
        _id
        name
        permissions {
          _id
          name
        }
      }
      url
    }
  }
`;

export const GET_MY_PROFILE_MIN = gql`
  {
    myProfile {
      _id
      email
      firstName
      auth0UserId
      lastName
      member {
        branch {
          _id
          name
          region {
            _id
          }
        }
        phoneNumber
      }
      roles {
        _id
        name
        permissions {
          _id
          name
        }
      }
      url
    }
  }
`;
