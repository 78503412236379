// @flow

import {
  IntrospectionFragmentMatcher,
  InMemoryCache,
  defaultDataIdFromObject,
} from 'apollo-cache-inmemory';
import introspectionQueryResultData from './fragmentTypes.json';

export const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

function dataIdFromObject(obj) {
  switch (obj.__typename) {
    default:
      return defaultDataIdFromObject(obj);
  }
}

export default function createCache() {
  // https://www.apollographql.com/docs/react/basics/caching.html#configuration
  return new InMemoryCache({
    dataIdFromObject,
    fragmentMatcher,
  });
}
