import gql from 'graphql-tag';

export const UPDATE_BRANCH = gql`
  mutation updateBranch($input: BranchInput!, $id: String!) {
    updateBranch(input: $input, id: $id) {
      _id
      name
      url
      address
      city
      state
      country
      leader {
        _id
        firstName
        lastName
      }
      region {
        _id
        name
      }
    }
  }
`;
