import React from 'react';
import { Col, Row, Button, Input, Form, Select, Divider, Icon } from 'antd';
import { navigate } from '@reach/router';
import { useFormik } from 'formik';
import isEmpty from 'lodash.isempty';
import { useMutation, useQuery } from '@apollo/client';
// internals
import { PageTitle, appNotification } from '_components';
import validationSchema from './formValidationSchema';
import { ADD_ATTENDANCE, UPDATE_ATTENDANCE } from './graphql';
import { GET_SERVICE_REPORTS } from '../../pages/ServiceReports/graphql';

const { Option } = Select;

const AttendanceForm = props => {
  let record = props?.location?.state;

  const order = { field: 'name', direction: 'ASC' };
  const { data: queryData } = useQuery(GET_SERVICE_REPORTS, {
    variables: { order },
  });

  const [addAttendance, { loading: mutateLoading }] = useMutation(
    ADD_ATTENDANCE,
  );

  const [updateAttendance, { loading: updateLoading }] = useMutation(
    UPDATE_ATTENDANCE,
  );

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    dirty,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      serviceReport: record?.id,
      pastor: record?.pastor,
      men: record?.men,
      women: record?.women,
      brothers: record?.brothers,
      teenagers: record?.teenagers,
      sisters: record?.sisters,
      children: record?.children,
    },
    validationSchema,
    onSubmit(values) {
      const formData = {
        ...values,
      };
      Object.keys(formData).map(key => {
        if (key !== 'serviceReport') {
          formData[key] = parseInt(formData[key]);
        }
      });
      if (record?._id) {
        // update existing attendance
        updateAttendance({
          variables: { data: formData, id: record._id },
        }).then(({ data }) => {
          if (data?.updateAttendanceReport?._id) {
            appNotification({
              desc: `Your Attendance Report was successfully modified. Kudos`,
              type: 'success',
            });
            navigate(-1);
          }
        });
      } else {
        addAttendance({ variables: { data: formData } }).then(({ data }) => {
          if (data?.createAttendanceReport?._id) {
            appNotification({
              desc: `Your Attendance Report was successfully ${
                record ? 'modified' : 'added'
              }. Kudos`,
              type: 'success',
            });
            if (props.next) {
              props.next(); // coming from Step builder
            } else if (record) {
              navigate(-1);
            } else {
              navigate('/attendance');
            }
          }
        });
      }
      resetForm();
    },
  });

  return (
    <div className="page-container">
      <Row
        type="flex"
        align="middle"
        justify="center"
        className=""
        gutter={[24, 24]}>
        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}>
          <PageTitle
            title="Attendance Form"
            extra={
              <Row type="flex" justify="end">
                <a
                  onClick={() => {
                    navigate(-1);
                  }}>
                  <Icon className="mx-1" type="left-circle" /> Back{' '}
                </a>
              </Row>
            }
          />

          <Divider />
          <Row gutter={[24, 24]}>
            <Col xs={{ span: 24 }} lg={{ span: 24 }} className="">
              <Form>
                <Row gutter={[16, 16]}>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} className="">
                    <Form.Item
                      required
                      label="Service"
                      htmlFor="serviceReport"
                      colon={false}
                      validateStatus={
                        errors.serviceReport && touched.serviceReport
                          ? 'error'
                          : null
                      }
                      help={
                        errors.serviceReport && touched.serviceReport
                          ? errors.serviceReport
                          : null
                      }>
                      <Select
                        defaultValue={record?.name ? record.name : ''}
                        disabled={!!record?.name}
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select type of service"
                        optionFilterProp="children"
                        onChange={text => setFieldValue('serviceReport', text)}
                        // onBlur={handleBlur}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                        {props.createServiceReport ? (
                          <Option
                            key={props.createServiceReport._id}
                            value={props.createServiceReport._id}>
                            {props.createServiceReport.name}
                          </Option>
                        ) : !isEmpty(queryData) ? ( // change to lazy query
                          queryData.serviceReports.map(report => (
                            <Option key={report._id} value={report._id}>
                              {report.name}
                            </Option>
                          ))
                        ) : (
                          <Option value={null}>
                            No service report defined
                          </Option>
                        )}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      required
                      label="Pastors"
                      htmlFor="pastor"
                      colon={false}
                      validateStatus={
                        errors.pastor && touched.pastor ? 'error' : null
                      }
                      help={
                        errors.pastor && touched.pastor ? errors.pastor : null
                      }>
                      <Input
                        name="pastor"
                        type="number"
                        placeholder="0"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.pastor}
                      />
                    </Form.Item>

                    <Form.Item
                      required
                      label="Deacons"
                      htmlFor="deacon"
                      colon={false}
                      validateStatus={
                        errors.deacon && touched.deacon ? 'error' : null
                      }
                      help={
                        errors.deacon && touched.deacon ? errors.deacon : null
                      }>
                      <Input
                        name="deacon"
                        type="number"
                        placeholder="0"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.deacon}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Men"
                      htmlFor="men"
                      colon={false}
                      validateStatus={
                        errors.men && touched.men ? 'error' : null
                      }
                      help={errors.men && touched.men ? errors.men : null}>
                      <Input
                        name="men"
                        type="number"
                        placeholder="0"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.men}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Women"
                      htmlFor="women"
                      colon={false}
                      validateStatus={
                        errors.women && touched.women ? 'error' : null
                      }
                      help={
                        errors.women && touched.women ? errors.women : null
                      }>
                      <Input
                        name="women"
                        type="number"
                        placeholder="0"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.women}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} lg={{ span: 12 }} className="">
                    <Form.Item
                      label="Brothers"
                      htmlFor="brothers"
                      colon={false}
                      validateStatus={
                        errors.brothers && touched.brothers ? 'error' : null
                      }
                      help={
                        errors.brothers && touched.brothers
                          ? errors.brothers
                          : null
                      }>
                      <Input
                        name="brothers"
                        type="number"
                        placeholder="0"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.brothers}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Sisters"
                      htmlFor="sisters"
                      colon={false}
                      validateStatus={
                        errors.sisters && touched.sisters ? 'error' : null
                      }
                      help={
                        errors.sisters && touched.sisters
                          ? errors.sisters
                          : null
                      }>
                      <Input
                        name="sisters"
                        type="number"
                        placeholder="0"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.sisters}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Teeanagers"
                      htmlFor="teenagers"
                      colon={false}
                      validateStatus={
                        errors.teenagers && touched.teenagers ? 'error' : null
                      }
                      help={
                        errors.teenagers && touched.teenagers
                          ? errors.teenagers
                          : null
                      }>
                      <Input
                        name="teenagers"
                        type="number"
                        placeholder="0"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.teenagers}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Samuel's Club"
                      htmlFor="samuelClub"
                      colon={false}
                      validateStatus={
                        errors.samuelClub && touched.samuelClub ? 'error' : null
                      }
                      help={
                        errors.samuelClub && touched.samuelClub
                          ? errors.samuelClub
                          : null
                      }>
                      <Input
                        name="samuelClub"
                        type="number"
                        placeholder="0"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.samuelClub}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Children"
                      htmlFor="children"
                      colon={false}
                      validateStatus={
                        errors.children && touched.children ? 'error' : null
                      }
                      help={
                        errors.children && touched.children
                          ? errors.children
                          : null
                      }>
                      <Input
                        name="children"
                        type="number"
                        placeholder="0"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.children}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Button
                  block
                  size="large"
                  type="primary"
                  loading={mutateLoading || updateLoading}
                  disabled={!isEmpty(errors) || !dirty}
                  onClick={handleSubmit}>
                  Submit Report
                </Button>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AttendanceForm;
