/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Table, Button } from 'antd';
import moment from 'moment';
import { uuid } from 'uuidv4';
import { Link } from '@reach/router';
import { GET_FT } from 'formPages/FirstTimerAbsorptionForm/graphql';
import { useQuery } from '@apollo/client';
import filter from 'lodash/filter';
import { SS } from '_helpers';

const columns = [
  {
    title: 'Convert Name',
    dataIndex: 'name',
    // fixed: 'left',
    // width: 180,
    // key: 'name',
    align: 'center',
  },
  {
    title: 'First Date InChurch',
    dataIndex: 'firstDateInChurch',
  },
  // {
  //   title: 'Follow up Contact',
  //   dataIndex: 'followUpContact',
  // },
  {
    title: 'Family Unit Leader',
    dataIndex: 'familyUnitLeader',
  },
  // {
  //   title: 'Salvation Status',
  //   dataIndex: 'salvationStatus',
  // },

  // {
  //   title: 'New Life Teacher',
  //   children: [
  //     {
  //       title: 'Stage',
  //       dataIndex: 'newLifeStage',
  //       key: 'age',
  //       // width: 150,
  //     },
  //     {
  //       title: 'Teacher',
  //       dataIndex: 'newLifeTeacher',
  //       key: 'age',
  //       // width: 150,
  //     },
  //   ],
  // },
  {
    title: 'Believer Class',
    children: [
      {
        title: 'Teacher',
        dataIndex: 'believerClassTeacher',
        // key: 'age',
      },
      {
        title: 'Stage',
        dataIndex: 'believerClassStage',
        // key: 'age',
      },
    ],
  },
  // {
  //   title: 'Pastor\'s Remark',
  //   dataIndex: 'remark',
  //   ellipsis: true,
  // },
  {
    title: 'Action',
    key: '',
    dataIndex: '',
    // fixed: 'right',
    align: 'center',
    width: 160,
    render: (text, record) => {
      return (
        <Link to={`${record.id}`}>
          <Button
            key={uuid()}
            // href={`/ft-absorption/${uuid()}`}
            onClick={() => SS.save('convert-details', record)}
            className="table-view__button">
            View Details
          </Button>
        </Link>
      );
    },
  },
];

const update = data =>
  data &&
  data.map(firstTimer => ({
    key: uuid(),
    id: firstTimer._id,
    name:
      firstTimer &&
      Object.values(firstTimer)
        .slice(1, 4)
        .join(' '),
    newLifeStage: firstTimer.newLifeStage,
    newLifeTeacher:
      firstTimer.newLifeTeacher &&
      Object.values(firstTimer.newLifeTeacher)
        .slice(1, -1)
        .join(' '),
    believerClassStage: firstTimer.belClassStage,
    believerClassTeacher:
      firstTimer.belClassTeacher &&
      Object.values(firstTimer.newLifeTeacher)
        .slice(1, -1)
        .join(' '),
    salvationStatus: firstTimer.saved ? 'Saved' : 'Unsaved',
    firstDateInChurch:
      firstTimer.firstDayInChurch &&
      moment(firstTimer.firstDayInChurch).format('MM-DD-YYYY'),
    familyUnitLeader:
      firstTimer.unitLeader &&
      Object.values(firstTimer.unitLeader)
        .slice(1, -1)
        .join(' '),
    followUpContact:
      firstTimer &&
      Object.values(firstTimer)
        .slice(4, 8)
        .join(' '),
    remark: firstTimer.pastoralRemarks,
  }));

const DataTable = ({ searchQuery }) => {
  const [page, setPage] = useState(1);

  const [firstTimers, updateList] = useState([]);

  const { loading, error, data } = useQuery(GET_FT);

  // CONTROL PAGINATION DATA
  const pagination = {
    total: firstTimers.length,
    pageSize: 54,
    current: page,
    onChange: page => {
      setPage(page);
      // dispatch(fetchAgents(page));
    },
  };

  useEffect(() => {
    let results = [];
    if (searchQuery.length > 3) {
      results = filter(firstTimers, firstTimer => {
        const name = Object.values(firstTimers)
          .slice(1, 4)
          .join(' ')
          .toLowerCase();

        return (
          name.includes(searchQuery.toLowerCase()) ||
          name.indexOf(searchQuery.toLowerCase()) !== -1
        );
      });
      updateList(results);
    }
    return () => {
      // cancel subscriptions
      if (data && data.firstTimers) {
        updateList(update(data.firstTimers));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    if (data && data.firstTimers) {
      updateList(update(data.firstTimers));
    }
  }, [data, loading]);

  useEffect(() => {
    return () => {
      // cancel subscriptions
    };
  }, [page]);

  return (
    <div style={{ marginTop: 8 }}>
      <Table
        className="data__table"
        columns={columns}
        dataSource={firstTimers}
        pagination={pagination}
        loading={loading}
        // bordered
        // size="small"
        // scroll={{ x: 'calc(1200px + 50%)' }}
      />
    </div>
  );
};

export default DataTable;
