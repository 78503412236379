import gql from 'graphql-tag';

export const ADD_ATTENDANCE = gql`
  mutation addAttendance($data: AttendanceReportInput!) {
    createAttendanceReport(input: $data) {
      _id
      pastor
      men
      women
      brothers
      sisters
      teenagers
      samuelClub
      children
      deacon
    }
  }
`;

export const UPDATE_ATTENDANCE = gql`
  mutation updateAttendance($data: AttendanceReportInput!, $id: String!) {
    updateAttendanceReport(input: $data, id: $id) {
      _id
      pastor
      men
      women
      brothers
      sisters
      teenagers
      samuelClub
      children
      deacon
    }
  }
`;
