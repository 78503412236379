import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Card, Input, Form, Select } from 'antd';
import { navigate, Link } from '@reach/router';
import { useFormik } from 'formik';
import isEmpty from 'lodash.isempty';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { useMutation, useQuery } from '@apollo/client';

// internals
import { PageTitle, appNotification } from '_components';
import { formItemLayout } from '_constants';
import validationSchema from './formValidationSchema';
import RegionDataList from './_components/regionList';
import { CREATE_BRANCH_MUTATION } from './query';
import { GET_MEMBERS } from '../../pages/Members/query';

const { Option } = Select;

const BranchForm = props => {
  const [addBranch] = useMutation(CREATE_BRANCH_MUTATION);
  const order = { field: 'name', direction: 'ASC' };
  const {
    loading: membersLoading,
    error: membersError,
    data: membersData,
  } = useQuery(GET_MEMBERS, { variables: { order } });

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {},
    validationSchema,
    async onSubmit(values) {
      const branchData = {
        ...values,
      };

      addBranch({ variables: { data: branchData } }).then(({ data }) => {
        if (data.createBranch._id) {
          appNotification({
            desc: `${data.createBranch.name} was successfuly added. Kudos`,
            type: 'success',
          });
          navigate('/branches');
        }
      });
      // console.log('SUBMITTED', values);
      resetForm();
    },
  });

  useEffect(() => {
    return () => {
      // cancel subscriptions
    };
  }, []);
  return (
    <>
      <PageTitle
        title=" Create Branch"
        extra={
          <Row type="flex" justify="end">
            <Link to="../">Back</Link>
          </Row>
        }
      />
      <div className="trans-details">
        <Row type="flex" className="" gutter={[24, 24]}>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}>
            <Row gutter={[24, 24]}>
              <Col span={24} className="">
                <Card
                  bordered={false}
                  style={{ borderRadius: 4 }}
                  title="Add a new Branch">
                  <Form layout="horizontal">
                    <Form.Item
                      label="Name"
                      htmlFor="name"
                      colon={false}
                      {...formItemLayout}
                      validateStatus={
                        errors.name && touched.name ? 'error' : null
                      }
                      help={errors.name && touched.name ? errors.name : null}>
                      <Input
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Branch Leader"
                      htmlFor="leader"
                      colon={false}
                      {...formItemLayout}
                      validateStatus={
                        errors.leader && touched.leader ? 'error' : null
                      }
                      help={
                        errors.leader && touched.leader ? errors.leader : null
                      }>
                      <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select Leader"
                        optionFilterProp="children"
                        onChange={text => setFieldValue('leader', text)}
                        // onBlur={handleBlur}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                        {!isEmpty(membersData) ? (
                          membersData.members.map(member => (
                            <Option key={member._id} value={member._id}>
                              {member?.firstName + ' ' + member.lastName}
                            </Option>
                          ))
                        ) : (
                          <Option value={null}>No leaders defined</Option>
                        )}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Region"
                      htmlFor="region"
                      colon={false}
                      {...formItemLayout}
                      validateStatus={
                        errors.region && touched.region ? 'error' : null
                      }
                      help={
                        errors.region && touched.region ? errors.region : null
                      }>
                      <RegionDataList
                        onChange={data => setFieldValue('region', data)}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Address"
                      htmlFor="address"
                      colon={false}
                      {...formItemLayout}
                      validateStatus={
                        errors.address && touched.address ? 'error' : null
                      }
                      help={
                        errors.address && touched.address
                          ? errors.address
                          : null
                      }>
                      <Input
                        name="address"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.address}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Country"
                      htmlFor="country"
                      colon={false}
                      {...formItemLayout}
                      validateStatus={
                        errors.country && touched.country ? 'error' : null
                      }
                      help={
                        errors.country && touched.country
                          ? errors.country
                          : null
                      }>
                      <CountryDropdown
                        className="ant-input"
                        value={values.country}
                        onChange={e => setFieldValue('country', e)}
                        priorityOptions={['NG', 'US', 'GB', 'CA']}
                      />
                    </Form.Item>
                    <Form.Item
                      label="State"
                      htmlFor="state"
                      colon={false}
                      {...formItemLayout}
                      validateStatus={
                        errors.state && touched.state ? 'error' : null
                      }
                      help={
                        errors.state && touched.state ? errors.state : null
                      }>
                      <RegionDropdown
                        className="ant-input"
                        country={values.country}
                        value={values.state}
                        onChange={e => setFieldValue('state', e)}
                      />
                    </Form.Item>
                    <Form.Item
                      label="City"
                      htmlFor="city"
                      colon={false}
                      {...formItemLayout}
                      validateStatus={
                        errors.city && touched.city ? 'error' : null
                      }
                      help={errors.city && touched.city ? errors.city : null}>
                      <Input
                        name="city"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.city}
                      />
                    </Form.Item>

                    <Row type="flex" justify="end">
                      <Button
                        key="submit"
                        type="primary"
                        style={{ width: 170 }}
                        disabled={!isEmpty(errors)}
                        onClick={handleSubmit}>
                        Create Branch
                      </Button>
                    </Row>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default BranchForm;
