import React, { useEffect, useState } from 'react';
import { Table, Button, Divider, Modal } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import filter from 'lodash/filter';
import isEmpty from 'lodash.isempty';
import { uuid } from 'uuidv4';
import { Link } from '@reach/router';
import { SS } from '_helpers';
import { GET_BRANCH, DELETE_BRANCH } from '../query';
import { appNotification } from '_components';

const order = { field: 'name', direction: 'Asc' };
const update = data =>
  data &&
  data.map(branch => ({
    key: uuid(),
    id: branch._id,
    name: branch.name,
    address: branch.address,
    city: branch.city,
    state: branch.state,
    country: branch.country,
    region: isEmpty(branch.region) ? '-' : branch.region.name,
    leader: isEmpty(branch.leader)
      ? '-'
      : branch.leader.firstName + ' ' + branch.leader.lastName,
  }));

const DataTable = ({ searchQuery }) => {
  const [branchData, updateList] = useState([]);
  const [page, setPage] = useState(1);
  const [branch, setBranch] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const { loading, data } = useQuery(GET_BRANCH, {
    variables: { order },
  });

  const [deleteSingleBranch, { loading: deleteLoading }] = useMutation(
    DELETE_BRANCH,
    {
      variables: { id: branch?.id },
      onCompleted() {
        setShowModal(false);
        setBranch(null);
        window.location.reload();
      },
      onError() {
        appNotification({
          desc: 'Unable to delete branch. Try again later.',
          type: 'error',
        });
      },
    },
  );

  const handleShowModal = data => {
    setBranch(data);
    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
    setBranch(null);
  };

  const handleDelete = () => {
    deleteSingleBranch(branch.id);
  };

  const pagination = {
    total: branchData && branchData.length,
    pageSize: 54,
    current: page,
    onChange: page => {
      setPage(page);
    },
  };

  const columns = [
    {
      title: 'Branch Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name?.length - b.name?.length,
      key: 'name',
    },
    {
      title: 'Region',
      dataIndex: 'region',
      sorter: (a, b) => a.region?.length - b.region?.length,
    },
    {
      title: 'City',
      dataIndex: 'city',
      sorter: (a, b) => a.city?.length - b.city?.length,
      width: '15%',
    },
    {
      title: 'State',
      dataIndex: 'state',
      sorter: (a, b) => a.state?.length - b.state?.length,
    },
    {
      title: 'Leader',
      dataIndex: 'leader',
      sorter: (a, b) => a.leader?.length - b.leader?.length,
    },
    {
      title: 'Action',
      key: 'record.id',
      dataIndex: 'record.id',
      align: 'center',
      width: 200,
      render: (_, record) => {
        return (
          <>
            <Button
              key={record.id}
              onClick={() => handleShowModal(record)}
              size="small"
              type="link">
              Delete
            </Button>
            <Divider type="vertical" />
            <Link to={`${record.id}/edit`}>
              <Button
                key={record.id}
                size="small"
                onClick={() => SS.save('branch-details', record)}
                className="table-view__button">
                Edit
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    let results = [];
    if (searchQuery.length > 3) {
      results = filter(branchData, branch => {
        const name = Object.values(branch)
          .slice(1, 4)
          .join(' ')
          .toLowerCase();

        return (
          name.includes(searchQuery.toLowerCase()) ||
          name.indexOf(searchQuery.toLowerCase()) !== -1
        );
      });
      updateList(results);
    }
    return () => {
      if (data && data.branches) {
        updateList(update(data.branches));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    if (data && data.branches) {
      updateList(update(data.branches));
    }
  }, [data, loading]);

  return (
    <div style={{ marginTop: 8 }}>
      <Table
        className="data__table"
        columns={columns}
        dataSource={branchData}
        pagination={pagination}
        loading={loading}
      />
      {branch && (
        <Modal
          title="Delete Branch"
          visible={showModal}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              key="delete"
              type="danger"
              onClick={handleDelete}
              loading={deleteLoading}>
              Delete
            </Button>,
          ]}>
          <p>
            Do you want to delete <strong>{branch?.name}</strong> in{' '}
            <strong>{branch?.region}</strong>?
            <br />
            <strong className="text-error">
              Please note action is irrevesible!
            </strong>
          </p>
        </Modal>
      )}
    </div>
  );
};

export default DataTable;
