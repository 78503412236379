import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useQuery } from '@apollo/client';
import { GET_REGION } from '../../../pages/Regions/query';
const Option = Select.Option;

const DataList = ({ onChange }) => {
  let isLoading = false;
  let regionData = [];
  let regionDropDown;

  try {
    const result = useQuery(GET_REGION);
    if (result.loading) {
      isLoading = true;
    }
    if (result.data) {
      regionData = result.data.regions;
      regionDropDown = regionData
        .sort()
        .map(regions => (
          <Option value={regions._id}>
            {regions.name.toString().toUpperCase()}
          </Option>
        ));
    }
  } catch (error) {
    // handle error here
  }

  function handleBlur() {
    // console.log('blur');
  }

  function handleFocus() {
    // console.log('focus');
  }
  return (
    <Select
      showSearch
      placeholder="Select a region"
      optionFilterProp="children"
      onChange={onChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }>
      {regionDropDown}
    </Select>
  );
};

export default DataList;
