import React, { useState, useEffect } from 'react';
import { Menu, Icon } from 'antd';
import { Link } from '@reach/router';
import { uuid } from 'uuidv4';
import { useQuery } from '@apollo/client';

import NotificationDrawer from './NotificationDrawer';
import NotificationLists from './NotificationLists';
import { envConfig } from '_helpers';
import { GET_MY_PROFILE } from '../../Profile/graphql';

const MenuList = [
  {
    to: '/',
    icon: 'dashboard',
    title: 'Dashboard',
  },
  // {
  //   to: '/attendance',
  //   icon: 'team',
  //   title: 'Attendance Report',
  // },
  // {
  //   to: '/reports-analytics',
  //   icon: 'bar-chart',
  //   title: 'Reports analytics',
  // },
  // {
  //   to: '/service-report',
  //   icon: 'profile',
  //   title: 'Service Reports',
  // },
  {
    to: '/converts',
    icon: 'smile',
    title: 'Converts',
  },
  {
    to: '/ft-absorption',
    icon: 'user',
    title: 'First Timer Absorption',
  },
];

const { SubMenu } = Menu;

const SideMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [userProfile, setUserProfile] = useState(null);

  const { data: profileData } = useQuery(GET_MY_PROFILE);

  useEffect(() => {
    if (profileData) {
      setUserProfile(profileData.myProfile);
    }
  }, [profileData, userProfile]);

  function closeDrawer(params) {
    return setIsOpen(false);
  }
  return (
    <>
      <Menu theme="dark" mode="inline" defaultOpenKeys={['sub4']}>
        {MenuList.map(menu => (
          <Menu.Item key={uuid()}>
            <Link to={menu.to}>
              <Icon type={menu.icon} />
              <span className="nav-text">{menu.title}</span>
            </Link>
          </Menu.Item>
        ))}

        <SubMenu
          key="sub4first"
          title={
            <span>
              <Icon type="user-add" />
              <span>First Timers</span>
            </span>
          }>
          <Menu.ItemGroup key="g1brfirst">
            <Menu.Item key="1firsttimer">
              <Link to="/first-timers/new">Add New First Timer</Link>
            </Menu.Item>
            <Menu.Item key="2firsttimer">
              <Link to="/first-timers">View First Timers</Link>
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>

        <SubMenu
          key="sub4members"
          title={
            <span>
              <Icon type="team" />
              <span>Members</span>
            </span>
          }>
          <Menu.ItemGroup key="g1brmemberse">
            <Menu.Item key="1members">
              <Link to="/members/new">Add New Member</Link>
            </Menu.Item>
            <Menu.Item key="Bulk Members">
              <Link to="/members/bulk-upload">Bulk Members</Link>
            </Menu.Item>
            <Menu.Item key="2members">
              <Link to="/members">View Members</Link>
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>

        <SubMenu
          key="sub4profile"
          title={
            <span>
              <Icon type="profile" />
              <span>Service Reports</span>
            </span>
          }>
          <Menu.ItemGroup key="g1branchprofile">
            <Menu.Item key="1Service Reports8">
              <Link to="/service-report">Add New Reports</Link>
            </Menu.Item>
            <Menu.Item key="3service">
              <Link to="/services">View Service Detail</Link>
            </Menu.Item>
            <Menu.Item key="2Service Reports">
              <Link to="/service-report/lists">View Service Reports</Link>
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>

        {userProfile && userProfile.roles[0]._id === envConfig.adminRoleId ? (
          <SubMenu
            key="sub4"
            title={
              <span>
                <Icon type="setting" />
                <span>Admin Setting</span>
              </span>
            }>
            <Menu.ItemGroup key="g1branch" title="Branch">
              <Menu.Item key="1branch">
                <Link to="/branches/new">Add Branch</Link>
              </Menu.Item>
              <Menu.Item key="2branch">
                <Link to="/branches">View Branches</Link>
              </Menu.Item>
            </Menu.ItemGroup>

            <Menu.ItemGroup key="g1region" title="Region">
              <Menu.Item key="1region">
                <Link to="/regions/new">Add Region</Link>
              </Menu.Item>
              <Menu.Item key="2region">
                <Link to="/regions">View Regions</Link>
              </Menu.Item>
            </Menu.ItemGroup>

            <Menu.ItemGroup key="g1service" title="Services">
              <Menu.Item key="1service">
                <Link to="/services/new">Add Service</Link>
              </Menu.Item>
              <Menu.Item key="3service">
                <Link to="/services">View Services</Link>
              </Menu.Item>
              <Menu.Item key="2service">
                <Link to="/services-types/new">Add Service Category</Link>
              </Menu.Item>
              <Menu.Item key="4service">
                <Link to="/services-types">View Service Category</Link>
              </Menu.Item>
            </Menu.ItemGroup>

            <Menu.ItemGroup key="g1role" title="Role">
              <Menu.Item key="1role">
                <Link to="/roles/new">Add role</Link>
              </Menu.Item>
              <Menu.Item key="2role">
                <Link to="/roles">View roles</Link>
              </Menu.Item>
            </Menu.ItemGroup>

            <Menu.ItemGroup key="g1user" title="User">
              <Menu.Item key="1user">
                <Link to="/users">Manage Users</Link>
              </Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>
        ) : (
          ''
        )}
      </Menu>

      <NotificationDrawer onClose={closeDrawer} visible={isOpen}>
        <NotificationLists></NotificationLists>
      </NotificationDrawer>
    </>
  );
};

export default SideMenu;
