import gql from 'graphql-tag';

// MODIFY SCHEMA
export const CREATE_SERVICE = gql`
  mutation creatService($data: ServiceInput!) {
    createService(input: $data) {
      _id
    }
  }
`;

export const GET_SERVICE_TYPES = gql`
  query getServiceTypes($order: ServiceTypeSortInput) {
    serviceTypes(order: $order) {
      _id
      name
      url
    }
  }
`;
