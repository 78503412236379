import gql from 'graphql-tag';

export const GET_ROLES = gql`
  {
    roles {
      _id
      name
      permissions {
        _id
        name
      }
    }
  }
`;
