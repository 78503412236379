// const formItemLayout = {
//   labelCol: { span: 6 },
//   wrapperCol: { span: 18 },
// };
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

export default formItemLayout;
