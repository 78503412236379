import gql from 'graphql-tag';

// mutate role
export const CREATE_ROLE_MUTATION = gql`
  mutation createRole($data: RoleInput!) {
    createRole(input: $data) {
      _id
      name
    }
  }
`;

// GET ALL PERMISSIONS
export const GET_PERMISSIONS = gql`
  {
    permissions {
      _id
      name
    }
  }
`;

// update role
export const UPDATE_ROLE_MUTATION = gql`
  mutation updateRole($id: String!, $data: RoleInput!) {
    updateRole(id: $id, input: $data) {
      _id
      name
    }
  }
`;
