import React, { useEffect, useState } from 'react';
import { Table, Button, Tag, Divider, Modal } from 'antd';
import { uuid } from 'uuidv4';
import { Link } from '@reach/router';
import { useQuery } from '@apollo/client';
import filter from 'lodash/filter';

import { SS } from '_helpers';
import { GET_ROLES } from '../query';

const columns = [
  {
    title: 'Role ID',
    dataIndex: 'id',
  },
  {
    title: 'Role Name',
    dataIndex: 'name',
  },
  {
    title: 'Permissions',
    dataIndex: 'permissionList',
    width: '55%',
    render: (text, record) => {
      const list = record.permissionList.map(perm => <Tag>{perm} </Tag>);
      return <>{list}</>;
    },
  },

  {
    title: 'Action',
    key: '',
    dataIndex: '',
    render: (text, record) => {
      return (
        <>
          <Button
            key={record.id}
            onClick={() => showDeleteConfirm(record)}
            className=""
            size="small"
            type="link">
            Delete
          </Button>
          {/* </Link> */}
          <Divider type="vertical" />
          <Link to={`${record.id}/edit`}>
            <Button
              key={record._id}
              size="small"
              // href={`/ft-absorption/${uuid()}`}
              onClick={() => SS.save('role-details', record)}
              className="table-view__button">
              Edit
            </Button>
          </Link>
        </>
      );
    },
  },
];
// @Todo adapt leaders and branch data
const { confirm } = Modal;

function showDeleteConfirm(record) {
  confirm({
    title: 'Are you sure you want delete?',
    content: (
      <>
        <p className="mt-2 mb-2">
          Do you want to delete <strong>{record.name}</strong> role ?
        </p>
        <small className="text-error my-1">
          Please note action is irrevesible!
        </small>
      </>
    ),
    okText: 'Delete Role',
    okType: 'danger',
    cancelText: 'Cancel',
    onOk() {
      // console.log('OK');
    },
    onCancel() {
      // console.log('Cancel');
    },
  });
}
const update = data =>
  data &&
  data.map(role => ({
    key: uuid(),
    id: role._id,
    name: role.name,
    permissions: role.permissions,
    permissionList: role.permissionList,
  }));

const DataTable = ({ searchQuery }) => {
  const [roleData, updateList] = useState([]);

  const [page, setPage] = useState(1);
  const order = { field: 'name', direction: 'Asc' };
  const { loading, error, data } = useQuery(GET_ROLES, {
    variables: { order },
  });

  useEffect(() => {
    let results = [];
    if (searchQuery.length > 3) {
      results = filter(roleData, role => {
        const name = Object.values(role)
          .slice(1, 4)
          .join(' ')
          .toLowerCase();

        return (
          name.includes(searchQuery.toLowerCase()) ||
          name.indexOf(searchQuery.toLowerCase()) !== -1
        );
      });
      updateList(results);
    }
    return () => {
      // cancel subscriptions
      if (data && data.roles) {
        updateList(update(data.roles));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    if (data?.roles) {
      updateList(
        update(
          data.roles.map(role => ({
            ...role,
            permissionList: role.permissions.map(({ name }) => name),
          })),
        ),
      );
    }
  }, [data, loading]);

  // CONTROL PAGINATION DATA
  const pagination = {
    total: roleData && roleData.length,
    pageSize: 10,
    current: page,
    onChange: page => {
      setPage(page);
    },
  };

  useEffect(() => {
    return () => {
      // cancel subscriptions
    };
  }, [page]);

  return (
    <div style={{ marginTop: 8 }}>
      <Table
        className="data__table"
        columns={columns}
        dataSource={roleData}
        pagination={pagination}
        loading={loading}
      />
    </div>
  );
};

export default DataTable;
