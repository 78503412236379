import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Tag, Icon, Select, Radio } from 'antd';
import isEmpty from 'lodash.isempty';
// internals
import {
  DateRange,
  PageTitle,
  CanAccess,
  DashboardStatsCard,
  appNotification,
} from '_components';
import { LineChart, TransactionsRateChart, FilterDropdown } from './components';
import BankTransactionsCards from './BankTransactionsCards';
import AgentDashboard from './AgentDashboard';
import InterswitchCards from './InterswitchCards';

const { Option } = Select;

const ReportAnalytics = () => {
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDaysAgo = e => {
    // console.log(`radio checked:${e.target.value}`);
  };
  const handleChange = e => {
    // console.log(e);
  };

  useEffect(() => {}, [tomorrow, startDate, endDate]);

  return (
    <div>
      <PageTitle
        title={'Report Analytics'}
        extra={
          <>
            <Row type="flex" justify="end">
              <Col span={4}>
                <Select
                  defaultValue="Select church"
                  style={{ width: '100%' }}
                  onChange={handleChange}>
                  <Option value="">Select Church</Option>
                  <Option value="dc-isheri">DC Isheri</Option>
                  <Option value="dc-canada">DC Canada</Option>
                  <Option value="dc-ikorodu">DC ikorodu</Option>
                  <Option value="dc-hq">DC HQ</Option>
                  <Option value="dc-stratford">DC Stratford</Option>
                </Select>
              </Col>
              <Col span={8} className="mx-1">
                <DateRange
                  displayLabel={false}
                  getStartDate={text => setStartDate(text)}
                  getEndDate={text => setEndDate(text)}
                />
              </Col>
              <Col>
                <div className="line-chart__radio">
                  <Radio.Group onChange={handleDaysAgo} defaultValue="today">
                    <Radio.Button value="today">Today</Radio.Button>
                    <Radio.Button value="week">Week</Radio.Button>
                    <Radio.Button value="month">Month</Radio.Button>
                    <Radio.Button value="year">Year</Radio.Button>
                  </Radio.Group>
                </div>
              </Col>
            </Row>
          </>
        }
      />

      <Row type="flex" justify="start" className="" gutter={[16, 16]}>
        <DashboardStatsCard
          title="Total"
          volume={500}
          value={1600500.09}
          volumeColor="blue-text"
        />
        <DashboardStatsCard
          title="Offering"
          volume={500}
          value={1600500.09}
          volumeColor="green-text"
        />
        <DashboardStatsCard
          title="EGF"
          volume={50}
          value={1500.09}
          volumeColor="yellow-text"
        />
        <DashboardStatsCard
          title="Tithes"
          volume={500}
          value={160.19}
          volumeColor="red-text"
        />
      </Row>
      <LineChart />

      <BankTransactionsCards />

      <InterswitchCards />
    </div>
  );
};

export default ReportAnalytics;
