import React, { useEffect, useState } from 'react';
import { Table, Button } from 'antd';
import { useQuery } from '@apollo/client';
import { uuid } from 'uuidv4';
import { Link } from '@reach/router';
import { SS, Currency } from '_helpers';
import { GET_FINANCE_REPORTS } from '../graphql';

const columns = [
  {
    title: 'Amount',
    dataIndex: 'offering',
    render: (text, record) => {
      return <>{Currency.format(record.offering)}</>;
    },
  },

  {
    title: 'Branch',
    dataIndex: 'branch',
    render: (text, record) => {
      return <>{record.branch}</>;
    },
  },
  {
    title: 'Service Type',
    dataIndex: 'serviceType',
  },
  {
    title: 'Recorded by',
    dataIndex: 'recordedBy',
    render: (text, record) => {
      return <>{record.recordedBy}</>;
    },
  },

  {
    title: 'Action',
    key: '',
    dataIndex: '',
    align: 'center',
    // width: 160,
    render: (text, record) => {
      return (
        <>
          <Link to={`details/${record.id}`}>
            <Button
              key={uuid()}
              size="small"
              // href={`/ft-absorption/${uuid()}`}
              onClick={() => SS.save('finance-details', record)}
              className="table-view__button">
              View
            </Button>
          </Link>
        </>
      );
    },
  },
];

const update = data =>
  data &&
  data.map(finance => ({
    key: uuid(),
    id: finance._id,
    tithe: finance.tithe,
    egf: finance.egf,
    offering: finance.offering,
    recordedBy:
      finance.recordedBy &&
      Object.values(finance.recordedBy)
        .slice(1, -1)
        .join(' '),
    service: finance.service.name,
    serviceType: finance.serviceType.name,
    branch: finance.branch.name,
  }));

const DataTable = ({ searchQuery }) => {
  const isLoading = false;
  const [financeData, updateList] = useState([]);
  const [page, setPage] = useState(1);
  const order = { direction: 'Asc' };

  const { loading, error, data } = useQuery(GET_FINANCE_REPORTS, {
    variables: { order },
  });
  // console.log(data);

  // CONTROL PAGINATION DATA
  const pagination = {
    total: financeData.length,
    pageSize: 10,
    current: page,
    onChange: page => {
      setPage(page);
      // dispatch(fetchAgents(page));
    },
  };

  // useEffect(() => {
  //   let results = [];
  //   if (searchQuery.length > 3) {
  //     results = filter(financeData, financeReports => {
  //       const name = Object.values(financeReports)
  //         .slice(1, 4)
  //         .join(' ')
  //         .toLowerCase();

  //       return (
  //         name.includes(searchQuery.toLowerCase()) ||
  //         name.indexOf(searchQuery.toLowerCase()) !== -1
  //       );
  //     });
  //     updateList(results);
  //   }
  //   return () => {
  //     // cancel subscriptions
  //     if (data && data.financeReports) {
  //       updateList(update(data.financeReports));
  //     }
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [searchQuery]);

  useEffect(() => {
    if (data && data.financeReports) {
      updateList(update(data.financeReports));
    }
  }, [data, loading]);

  useEffect(() => {
    return () => {
      // cancel subscriptions
    };
  }, [page]);

  return (
    <div style={{ marginTop: 8 }}>
      <Table
        className="data__table"
        columns={columns}
        dataSource={financeData}
        pagination={pagination}
        loading={isLoading}
      />
    </div>
  );
};

export default DataTable;
