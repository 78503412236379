import gql from 'graphql-tag';

export const GET_CONVERTS_REPORTS = gql`
  query getConvertsReports {
    converts {
      _id
      name
      newLifeTeacher {
        firstName
        middleName
        lastName
      }
      belClassStage
      pastoralRemark
      belClassTeacher {
        firstName
        middleName
        lastName
      }
      dateSaved
      branch {
        _id
        name
      }
    }
  }
`;
