import React, { useState, useEffect } from 'react';
import { Col, Row, Card, Tag } from 'antd';
import { navigate, Link } from '@reach/router';

// internals
import { PageTitle } from '_components';
import { SS } from '_helpers';
import './FirstTimerAbsorptionDetails.css';

const FirstTimerAbsorptionDetails = props => {
  const [details, setDetails] = useState(SS.get('convert-details'));

  useEffect(() => {
    if (props.id !== details.id) {
      navigate(`/ft-absorption`);
    }
  }, [details.id, props.id]);

  return (
    <>
      <PageTitle
        title="First Timer Absorption Details"
        extra={
          <Row type="flex" justify="end">
            <Link to="../">Back</Link>
          </Row>
        }
      />
      <div className="trans-details">
        <Row type="flex" className="" gutter={[24, 24]}>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}>
            <Row gutter={[24, 24]}>
              {/* First Timers Details column */}
              <Col span={24} className="">
                <Card bordered={false} style={{ borderRadius: 4 }}>
                  <Row
                    className="markdown__row"
                    type="flex"
                    justify="space-between">
                    <Col span={18}>
                      <h3 className="mx-1">{`${details.name}'s Details`}</h3>
                    </Col>
                  </Row>
                  <div className="details_divider"></div>
                  <div className="markdown ">
                    <table className="">
                      <tbody>
                        <tr>
                          <td>First Date in Church</td>
                          <td>{details.firstDateInChurch}</td>
                        </tr>

                        <tr>
                          <td>Follow-up Contact</td>
                          <td>{details.followUpContact}</td>
                        </tr>
                        <tr>
                          <td>Family Unit Leader</td>
                          <td>{details.familyUnitLeader}</td>
                        </tr>
                        <tr>
                          <td>Salvation Status</td>
                          <td>
                            <Tag color="green">{details.salvationStatus}</Tag>
                          </td>
                        </tr>
                        <tr>
                          <td>Your New Life Teacher</td>
                          <td>{details.newLifeTeacher}</td>
                        </tr>
                        <tr>
                          <td>New Life Stage</td>
                          <td>{details.newLifeStage}</td>
                        </tr>
                        <tr>
                          <td>Believer's Class Teacher</td>
                          <td>{details.believerClassTeacher}</td>
                        </tr>
                        <tr>
                          <td>Believer's Class Stage</td>
                          <td>{details.believerClassStage}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}>
            <Row gutter={[24, 24]}>
              {/* Payment Method column */}
              <Col span={24} className="">
                <Card bordered={false} style={{ borderRadius: 4 }}>
                  <Row
                    className="markdown__row"
                    type="flex"
                    justify="space-between">
                    <Col span={18}>
                      <span style={{ marginRight: '1em', marginLeft: '1em' }}>
                        Pastors' Remark
                      </span>
                    </Col>
                  </Row>
                  <div className="details_divider"></div>
                  <div className="markdown ">
                    <p className="px-1 py-1">{details.remark}</p>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FirstTimerAbsorptionDetails;
