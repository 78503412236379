import React, { useEffect, useState } from 'react';
import { Table, Button, Divider, Modal, Tag } from 'antd';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { uuid } from 'uuidv4';
import { Link } from '@reach/router';
import { SS } from '_helpers';
import { appNotification as notification } from '_components';
import { GET_SERVICE_CATEGORY } from '../query';

const columns = [
  {
    title: 'Category Name',
    dataIndex: 'name',
  },

  {
    title: 'Action',
    key: '',
    dataIndex: '',
    align: 'center',
    // width: 160,
    render: (text, record) => {
      return (
        <>
          {/* <Link to={`details/${record.id}`}> */}
          <Button
            key={record._id}
            // onClick={() => SS.save('branch-details', record)}
            onClick={() => showDeleteConfirm(record)}
            className=""
            size="small"
            type="link">
            Delete
          </Button>
          {/* </Link> */}
          <Divider type="vertical" />
          <Link to={`${record._id}/edit`}>
            <Button
              key={record._id}
              size="small"
              // href={`/ft-absorption/${uuid()}`}
              onClick={() => SS.save('service-category-details', record)}
              className="table-view__button">
              Edit
            </Button>
          </Link>
        </>
      );
    },
  },
];

const data = [];
for (let i = 1; i < 9; i++) {
  data.push({
    key: uuid(),
    id: i,
    _id: i,
    name: 'Living By the Answer',
    services: ['LBA, 02-09-2002', 'Apostolic Serivce, 02-09-2002'],
  });
}

const { confirm } = Modal;

function showDeleteConfirm(record) {
  confirm({
    title: 'Are you sure you want delete?',
    content: (
      <>
        <p className="mt-2 mb-2">
          Do you want to delete <strong>{record.name}</strong> ?
        </p>
        <small className="text-error my-1">
          Please note action is irrevesible!
        </small>
      </>
    ),
    okText: 'Delete Branch',
    okType: 'danger',
    cancelText: 'Cancel',
    onOk() {
      // console.log('OK');
    },
    onCancel() {
      // console.log('Cancel');
    },
  });
}

const DataTable = () => {
  let isLoading = false;
  let serviceCategoryData;
  const [page, setPage] = useState(1);
  const order = { field: 'name', direction: 'DESC' };
  try {
    const result = useQuery(GET_SERVICE_CATEGORY, {
      variables: { order },
    });
    if (result.loading) {
      isLoading = true;
    }
    if (result.data) {
      serviceCategoryData = result.data.serviceTypes;
    }
  } catch (error) {
    notification(`${error.message}`, ' error ', 'error');
  }
  // CONTROL PAGINATION DATA
  const pagination = {
    total: data,
    pageSize: 54,
    current: page,
    onChange: page => {
      setPage(page);
      // dispatch(fetchAgents(page));
    },
  };

  useEffect(() => {
    return () => {
      // cancel subscriptions
    };
  }, [page]);

  return (
    <div style={{ marginTop: 8 }}>
      <Table
        className="data__table"
        columns={columns}
        dataSource={serviceCategoryData}
        // pagination={pagination}
        loading={isLoading}
      />
    </div>
  );
};

export default DataTable;
