import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Card, Input, Form, Select } from 'antd';
import { navigate, Link } from '@reach/router';
import { useFormik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import isEmpty from 'lodash.isempty';

// internals
import { PageTitle, appNotification } from '_components';

import { SS } from '_helpers';
import { formItemLayout } from '_constants';
import validationSchema from './formValidationSchema';
import { UPDATE_USER_MUTATION } from './query';
import { GET_BRANCH } from '../../pages/Branches/query';
import { GET_ROLES } from '../../pages/RoleManagement/query';

const { Option } = Select;

const EditUserForm = props => {
  const [details, setDetails] = useState(SS.get('user-details'));

  const order = { field: 'name', direction: 'ASC' };
  const {
    loading: branchLoading,
    error: branchError,
    data: branchData,
  } = useQuery(GET_BRANCH, { variables: { order } });

  const {
    loading: roleLoading,
    error: roleError,
    data: roleData,
  } = useQuery(GET_ROLES, { variables: { order } });

  const [updateUser, { loading, error }] = useMutation(UPDATE_USER_MUTATION);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      lastName: details.lastName,
      firstName: details.firstName,
      email: details.email,
      // member: details.branchDetails._id,
      roles: [...new Set(details.roles.map(role => role._id))],
    },
    validationSchema,
    onSubmit(values) {
      //   if (!values.name) {
      //     delete values.name;
      //   }
      const userData = {
        ...values,
      };
      updateUser({ variables: { id: details.id, data: userData } }).then(
        ({ data }) => {
          if (data.updateUser._id) {
            appNotification({
              desc: `${data.updateUser.lastName +
                ' ' +
                data.updateUser.firstName} was successfuly updated. Kudos`,
              type: 'success',
            });
            navigate('/users');
          }
        },
      );
      // console.log('SUBMITTED', details.id, values);
      resetForm();
    },
  });

  useEffect(() => {
    return () => {
      // cancel subscriptions
    };
  }, []);
  return (
    <>
      <PageTitle
        title=" Edit User"
        extra={
          <Row type="flex" justify="end">
            <Link to="/users">Back</Link>
          </Row>
        }
      />
      <div className="trans-details">
        <Row type="flex" className="" gutter={[24, 24]}>
          <Col
            xs={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}>
            <Row gutter={[24, 24]}>
              <Col span={24} className="">
                <Card
                  bordered={false}
                  style={{ borderRadius: 4 }}
                  title="Change user details">
                  <Form layout="horizontal">
                    <Form.Item
                      label="Last Name"
                      htmlFor="lastName"
                      colon={false}
                      {...formItemLayout}
                      validateStatus={
                        errors.lastName && touched.lastName ? 'error' : null
                      }
                      help={
                        errors.lastName && touched.lastName
                          ? errors.lastName
                          : null
                      }>
                      <Input
                        name="lastName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.lastName}
                        readOnly
                      />
                    </Form.Item>

                    <Form.Item
                      label="First Name"
                      htmlFor="firstName"
                      colon={false}
                      {...formItemLayout}
                      validateStatus={
                        errors.firstName && touched.firstName ? 'error' : null
                      }
                      help={
                        errors.firstName && touched.firstName
                          ? errors.firstName
                          : null
                      }>
                      <Input
                        name="firstName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.firstName}
                        readOnly
                      />
                    </Form.Item>

                    <Form.Item
                      label="Email"
                      htmlFor="email"
                      colon={false}
                      {...formItemLayout}
                      validateStatus={
                        errors.email && touched.email ? 'error' : null
                      }
                      help={
                        errors.email && touched.email ? errors.email : null
                      }>
                      <Input
                        name="auth0UserId"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        readOnly
                      />
                    </Form.Item>

                    <Form.Item
                      label="Roles"
                      htmlFor="roles"
                      colon={false}
                      {...formItemLayout}
                      validateStatus={
                        errors.roles && touched.roles ? 'error' : null
                      }
                      help={
                        errors.roles && touched.roles ? errors.roles : null
                      }>
                      {/* THE SELECT OPTIONS SHOULD FETCHED ASYNC */}
                      <Select
                        enableReinitialize={true}
                        showSearch
                        style={{ width: '100%' }}
                        mode="multiple"
                        placeholder="Select roles"
                        optionFilterProp="children"
                        onChange={text => setFieldValue('roles', text)}
                        defaultValue={[
                          ...new Set(details.roles.map(role => role._id)),
                        ]}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                        {!isEmpty(roleData) ? (
                          roleData.roles.map(role => (
                            <Option key={role._id} value={role._id}>
                              {role.name}
                            </Option>
                          ))
                        ) : (
                          <Option value={null}>No role found</Option>
                        )}
                      </Select>
                    </Form.Item>
                    {/* send email to users email */}
                    {/* <Form.Item
                      label="Branch"
                      htmlFor="member"
                      colon={false}
                      {...formItemLayout}
                      validateStatus={
                        errors.member && touched.member ? 'error' : null
                      }
                      help={
                        errors.member && touched.member ? errors.member : null
                      }>
                      <Select
                        enableReinitialize={true}
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Select branch"
                        optionFilterProp="children"
                        onChange={text => setFieldValue('member', text)}
                        defaultValue={details.branchDetails._id}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                        {!isEmpty(branchData) ? (
                          branchData.branches.map(branch => (
                            <Option key={branch._id} value={branch._id}>
                              {branch.name}
                            </Option>
                          ))
                        ) : (
                          <Option value={null}>No branch found</Option>
                        )}
                      </Select>
                    </Form.Item> */}

                    <Row type="flex" justify="end">
                      <Button
                        key="submit"
                        type="primary"
                        style={{ width: 170 }}
                        disabled={!isEmpty(errors)}
                        onClick={handleSubmit}>
                        Update User
                      </Button>
                    </Row>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EditUserForm;
