/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Table, Button } from 'antd';
import { uuid } from 'uuidv4';
import { Link } from '@reach/router';
import { useQuery } from '@apollo/client';
import filter from 'lodash/filter';

import { SS } from '_helpers';
import { GET_ATTENDANCE_REPORTS } from '../query';

const columns = [
  {
    title: 'Programme',
    dataIndex: 'name',
    width: '12%',
    sorter: (a, b) => a.name?.length - b.name?.length,
    key: 'name',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    sorter: (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
  },

  {
    title: ' Branch',
    dataIndex: 'branch',
    sorter: (a, b) => a.branch?.name.length - b.branch?.name.length,
    render: record => {
      return <>{record.name}</>;
    },
  },

  {
    title: 'Pastors',
    dataIndex: 'pastors',
  },
  {
    title: 'Men',
    dataIndex: 'men',
  },
  {
    title: 'Women',
    dataIndex: 'women',
  },
  {
    title: 'Brothers',
    dataIndex: 'brothers',
  },
  {
    title: 'Sisters',
    dataIndex: 'sisters',
  },
  {
    title: 'Teenagers',
    dataIndex: 'teenagers',
  },
  {
    title: 'Children',
    dataIndex: '_children',
  },
  {
    title: 'Samuel Club',
    dataIndex: 'samuelClub',
  },

  {
    title: 'Action',
    key: '',
    dataIndex: '',
    align: 'center',
    render: (text, record) => {
      return (
        <Link to={`details/${record.id}`}>
          <Button
            key={uuid()}
            onClick={() => SS.save('attendance-details', record)}
            className="table-view__button">
            View Details
          </Button>
        </Link>
      );
    },
  },
];

const update = data =>
  data &&
  data.map(report => ({
    key: uuid(),
    id: report._id,
    name: report.service.name,
    branch: report.branch,
    date: new Date(report.service.serviceDate).toISOString().slice(0, 10),
    pastors: report.pastor,
    men: report.men,
    women: report.women,
    teenagers: report.teenagers,
    sisters: report.sisters,
    brothers: report.brothers,
    // Antd has a React.Node prop called "chilren"
    _children: report.children,
  }));

const DataTable = ({ searchQuery }) => {
  const [page, setPage] = useState(1);
  const order = { field: 'service.serviceDate', direction: 'DESC' };

  const [attendanceReports, updateList] = useState([]);

  const { loading, error, data } = useQuery(GET_ATTENDANCE_REPORTS, {
    variables: { order },
  });

  // // CONTROL PAGINATION DATA
  const pagination = {
    total: attendanceReports.length,
    pageSize: 54,
    current: page,
    onChange: page => {
      setPage(page);
    },
  };

  useEffect(() => {
    let results = [];
    if (searchQuery.length > 3) {
      results = filter(
        attendanceReports,
        report =>
          report.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          report.name.toLowerCase().indexOf(searchQuery.toLowerCase()) != -1,
      );
      updateList(results);
    }
    return () => {
      // cancel subscriptions
      if (data && data.attendanceReports) {
        updateList(update(data.attendanceReports));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    if (data && data.attendanceReports) {
      updateList(update(data.attendanceReports));
    }
  }, [data, loading, page]);

  return (
    <div style={{ marginTop: 8 }}>
      <Table
        className="data__table"
        columns={columns}
        dataSource={attendanceReports.sort(
          (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
        )}
        pagination={pagination}
        loading={loading}
      />
    </div>
  );
};

export default DataTable;
