import gql from 'graphql-tag';

export const GET_REGION = gql`
  {
    regions {
      _id
      name
      branches {
        _id
        name
        state
        city
        country
      }
      leader {
        _id
        lastName
        firstName
      }
    }
  }
`;

export const DELETE_REGION = gql`
  mutation deleteRegion($id: String!) {
    deleteRegion(id: $id) {
      _id
    }
  }
`;
