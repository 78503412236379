import React, { useEffect, useState } from 'react';
import { Table, Button, Tag, Divider } from 'antd';
import { useQuery } from '@apollo/client';
import { uuid } from 'uuidv4';
import { Link } from '@reach/router';
import moment from 'moment';
import filter from 'lodash/filter';
import isEmpty from 'lodash.isempty';
import { SS } from '_helpers';
import { appNotification as notification } from '_components';
import { GET_MEMBERS } from '../query';

const columns = [
  {
    title: 'First Name',
    dataIndex: 'firstName',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
  },
  {
    title: 'Email Address',
    dataIndex: 'email',
  },
  {
    title: 'Phone Number',
    dataIndex: 'phoneNumber',
  },
  {
    title: 'Membership Level',
    dataIndex: 'membershipLevel',
  },
  {
    title: 'Membership Verified',
    dataIndex: 'verified',
    render: (text, record) => {
      return (
        <>
          {record.verified ? (
            <Tag color="green">Verified</Tag>
          ) : (
            <Tag color="red">Not Verified</Tag>
          )}
        </>
      );
    },
  },
  {
    title: ' Branch',
    dataIndex: 'branch',
    render: (text, record) => {
      return <>{record.branch}</>;
    },
  },
  {
    title: 'Action',
    key: '',
    dataIndex: '',
    render: (text, record) => {
      return (
        <>
          <Link to={`details/${record.id}`}>
            <Button
              key={record.id}
              onClick={() => SS.save('members-details', record)}
              className="table-view__button">
              View Details
            </Button>
          </Link>
          <Divider type="horizontal" />
          <Link to={`${record.id}/edit`}>
            <Button
              key={record.id}
              size="small"
              onClick={() => SS.save('member-details', record)}
              className="table-view__button">
              Edit
            </Button>
          </Link>
        </>
      );
    },
  },
];

const update = data =>
  data &&
  data.map(member => ({
    key: uuid(),
    id: member._id,
    firstName: member.firstName,
    middleName: member.middleName,
    lastName: member.lastName,
    gender: member.gender,
    dateOfBirth: isEmpty(member.dateOfBirth)
      ? '-'
      : moment(member.dateOfBirth).format('DD-MM-YYYY'),
    email: member.email,
    phoneNumber: member.phoneNumber,
    membershipLevel: member.membershipLevel,
    maritalStatus: member.maritalStatus,
    photo: isEmpty(member.photo)
      ? 'https://placeimg.com/160/160/any'
      : member.photo.fileUrl,
    occupation: member.occupation,
    address: member.address,
    city: member.city,
    state: member.state,
    country: member.country,
    verified: member.verified,
    branch: isEmpty(member.branch) ? '-' : member.branch.name,
  }));

const DataTable = ({ searchQuery }) => {
  const [membersData, updateList] = useState([]);

  const [page, setPage] = useState(1);
  const order = { field: 'lastName', direction: 'Asc' };

  const { loading, error, data } = useQuery(GET_MEMBERS, {
    variables: { order },
  });

  // console.info(membersData);

  // CONTROL PAGINATION DATA
  const pagination = {
    total: membersData.length,
    pageSize: 10,
    current: page,
    onChange: page => {
      setPage(page);
    },
  };
  useEffect(() => {
    let results = [];
    if (searchQuery.length > 3) {
      results = filter(membersData, member => {
        const name = Object.values(member)
          .slice(1, 4)
          .join(' ')
          .toLowerCase();

        return (
          name.includes(searchQuery.toLowerCase()) ||
          name.indexOf(searchQuery.toLowerCase()) !== -1
        );
      });
      updateList(results);
    }
    return () => {
      // cancel subscriptions
      if (data && data.members) {
        updateList(update(data.members));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    if (data && data.members) {
      updateList(update(data.members));
    }
  }, [data, loading]);

  useEffect(() => {
    return () => {
      // cancel subscriptions
    };
  }, [page]);

  return (
    <div style={{ marginTop: 8 }}>
      <Table
        className="data__table"
        columns={columns}
        dataSource={membersData}
        pagination={pagination}
        loading={loading}
      />
    </div>
  );
};

export default DataTable;
